<template>
    <div class="row">
      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
      <div class="col-12">
        <card :title="table.title" v-if="dataItem.isPage=='table'">
          <div class="row">
            <div class="col-2 offset-10">
              <base-button type="secondary" class="pull-right"  @click="LoadData()">โหลดข้อมูล</base-button>
            </div>
          </div>
          <div class="table-responsive">
            <table-confirm :data="table.data" :columns="table.columns" @modals="OPenModel" thead-classes="text-primary"/>
          </div>
        </card>
        <!-- ///// -->
        <card :title="`รายละเอียด ${titlePage(dataItem.isPage)}`" v-if="dataItem.isPage!='table'">
          <div class="row">
            <div class="col-2 offset-10">
              <base-button type="secondary" class="pull-right"  @click="OPenModel({action:{title:'table'}})">ย้อนกลับ</base-button>
            </div>
            <div class="col-12" >
              <!-- GetMoney -->
              <getmoney v-if="dataItem.isPage=='GetMoney'" :data="this.dataItem" @loading="Loading" @backPage="OPenModel" />
              <!-- Withdraw -->
              <withdraw  v-if="dataItem.isPage=='Withdraw'" :data="this.dataItem" @loading="Loading" @backPage="OPenModel" />
              <!-- Costomer -->
              <costomer v-if="dataItem.isPage=='Costomer'" :data="this.dataItem" @loading="Loading" @backPage="OPenModel" />
           </div>
          </div>
        </card>

      </div>
    </div>
</template>
<script>
import { TableConfirm } from "@/components";
import { Costomer , Getmoney , Withdraw } from './ConfirmPage/index.js';
// Import component
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
const tableColumns = ["action", "commit", "admin", "owner","created_at","event"];
const tableData = [];
//
export default {
  components: {
    TableConfirm,
    Costomer ,
    Getmoney ,
    Loading,
    Withdraw
  },
  data() {
    return {
      table: {
        title: "รายการรออนุมัติ",
        columns: [...tableColumns],
        data: [...tableData]
      },
      dataItem:{isPage:'table'},
      isLoading: true,
      fullPage: true,
    };
  },
  methods: {
    Loading(evt){
      console.log('Loading',evt);
      this.isLoading = evt;
    },
    LoadData(){
      this.isLoading = true;
      axios(config.api('get', '/confirm/list')).then((response)=>{
        this.table.data = response.data.ResultData;
        this.isLoading = false;
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    titlePage(title){
      let TitleOBJ = {'GetMoney':'รายละเอียด การโอนเครดิต','Withdraw':'รายละเอียด การถอนเงิน','Costomer':'รายละเอียด แก้ไขข้อมูลสมาชิก'};
      return TitleOBJ[title];
    },
    OPenModel(ev){
      if(ev.action.title == 'GetMoney'){
        // console.log('GetMoney-OPenModel->',{_id:ev._id,data:ev.post});
        this.dataItem = {_id:ev._id,isPage:'GetMoney',method:ev.action.to ,post:ev.post,commit:ev.commit};
      }else if(ev.action.title == 'Withdraw'){
        // console.log('Withdraw-OPenModel->',{_id:ev._id,data:ev.post});
        this.dataItem = {_id:ev._id,isPage:'Withdraw',method:ev.action.to ,post:ev.post,commit:ev.commit};
      }else if(ev.action.title == 'Costomer'){
        // console.log('Costomer-OPenModel->',{_id:ev._id,data:ev.post});
        this.dataItem = {_id:ev._id,isPage:'Costomer',method:ev.action.to ,post:ev.post,commit:ev.commit};
      }else{
        this.dataItem = {_id:'',isPage:'table',data:{}};
        this.LoadData();
      }
    }
  },
  mounted(){
    this.LoadData();
  }
};
</script>
<style>

</style>
