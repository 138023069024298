<template>
    <div class="row">
      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
      <div class="col-12">
        <div class="mb-3">
          <card>
            <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากชื่อ/เบอร์/เลขบัญชี ... <Enter>" v-on:keyup.enter="searchWord()">
            </base-input>
          </card>
        </div>
        <div class="mb-3">
          <card :title="table.title">
            <div class="mb-2">
              <label for=""> Tag : </label>
              <ul class="logTag">
                <li v-for="(item, index) in tags" :key="index" @click="tagFind(item)">
                  <i class="tim-icons icon-tag"></i> {{item}}
                </li>
              </ul>
            </div>
            <div class="table-responsive">
              <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
              <base-table-logs :data="table.data"
                          :columns="table.columns"
                          thead-classes="text-primary">
              </base-table-logs>
              <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
</template>
<script>
import { BaseTableLogs } from "@/components";
import axios from 'axios';
import config from '@/config.js';
import utils from '@/utils';

import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";

const tableColumns = ["type", "message", "before", "after", "admin", "method", "fromLink", "ip","commit", "created_at"];
const tableData = [];
export default {
  components: {
    BaseTableLogs,
    Loading,
    SlidingPagination
  },
  data() {
    return {
      table: {
        title: "Logs",
        columns: [...tableColumns],
        data: [...tableData]
      },
      tags:[
        "info",
        "warn",
        "fatal",
        "error",
        "debug",
        "Login",
        "Control Games",
        "promotion",
        "Modify costomer",
        "Increase customer money",
        "Modify Admin",
        "Transfer Fail",
        "Transfer Success",
        "Withdrawal list",
        "Control Games",
        "Control Bank"
      ],
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      let searchName = this.searchName || null;
      axios(config.api('get', `/report/logsAdmin/${selectedPage}/${searchName}`)).then((response)=>{
        this.totalPages = response.data.ResultData.numpage.page;
        this.table.data = response.data.ResultData.skipdata;
        this.currentPage = selectedPage;
        this.isLoading = false;
      },(error)=>{
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    searchWord(){
      if(utils.permission('LOGS_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/report/logsAdmin/1'));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/report/logsAdmin/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    tagFind(text){
      this.searchName = text;
      this.searchWord();
    }
  },
  mounted() {
    if(utils.permission('LOGS_VIEW')){
      let searchName = this.searchName || null;
      axios(config.api('get', `/report/logsAdmin/1/${searchName}`)).then((response)=>{
        this.totalPages = response.data.ResultData.numpage.page;
        this.table.data = response.data.ResultData.skipdata;
        this.isLoading = false;
      },(error)=>{
        this.$alert(error.response.data.Message,'Error','error');
      });
    }
  }
};
</script>
<style>
.logTag{
  padding: 0;
  margin: 0;
}
.logTag > li{
  display: inline-block;
  border-radius: 50px;
  color: #FFF;
  background-color: #fd77a4;
  padding: 5px 10px;
  font-size: 10px;
  margin-left: 7px;
  margin-bottom: 7px;
  text-align: center;
  cursor: pointer;
}
</style>
