<template>
     <div>  
         {{seclectTage}}
        <section class="mb-3" ref="configbank" id="configbank">
          <h3>จัดการธนาคาร</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/config/configbank/table.png" style="width: 100%;" alt="dashboard">
                         <p>รายการ</p>
                    </div>
                    <div class="col-3">
                         <ul class="iconmenu"> 
                              <li>
                                   <button class="btn btn-primary icon sm" @click="event = 'add'">
                                        เพิ่มรายการ
                                   </button>  
                              </li> 
                              <li>
                                   <button class="btn btn-info icon sm" @click="event = 'info'">
                                        <i class="tim-icons icon-paper"></i>
                                   </button>  
                                   ดูข้อมูล
                              </li> 
                              <li>
                                   <button class="btn btn-warning icon sm">
                                        <i class="tim-icons icon-calendar-60"></i>
                                   </button>  
                                   ตรวจสอบยอด
                              </li> 
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li>
                                             <label class="switch"> 
                                                  <input v-model="boxswitch" type="checkbox" checked>
                                                  <span class="slider round"></span>
                                             </label> 
                                        </li>
                                        <li><p>{{boxswitch | switchoffon}}</p></li>
                                   </ul>
                              </li>
                         </ul>
                    </div>
                    <div class="col-9">
                         <div v-show="event == 'add'">
                              <img src="/img/help/config/configbank/banks.png" style="width: 100%;" alt="dashboard">
                              <p>เลือกธนาคารที่ต้องการเพิ่มเข้าระบบ</p>
                              <img src="/img/help/config/configbank/add.png" style="width: 100%;" alt="dashboard">
                              <p>กรอกชื่อบันชี และ เลขบันชีที่ต้องการเพิ่ม</p>
                         </div> 
                         <div v-show="event == 'info'">
                              <img src="/img/help/config/configbank/info.png" style="width: 100%;" alt="dashboard">
                              <p>ข้อมูลสำหรับบันชี Ref1 - Ref2 ใช่สำหรับระบุ Key ในการแจ้งเตือน</p> 
                         </div> 
                    </div>
               </div>
          </div>
        </section>
        <section class="mt-3" ref="promotion" id="promotion">
          <h3>จัดการโปรโมชั่น</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/config/promotion/table.png" style="width: 100%;" alt="dashboard">
                         <p>รายการ</p>
                    </div>
                    <div class="col-3">
                         <ul class="iconmenu">
                              <li>
                                   <button class="btn btn-primary icon sm" @click="eventPro = 'add'">
                                        เพิ่มรายการ
                                   </button>  
                              </li> 
                              <li>
                                   <button class="btn btn-info icon sm" @click="eventPro = 'add'">
                                        <i class="tim-icons icon-pencil"></i>
                                   </button>  
                                   ดูข้อมูล
                              </li>
                              <li>
                                   <button class="btn btn-danger icon sm">
                                        <i class="tim-icons icon-lock-circle"></i>
                                   </button>  
                                   บ๊อค
                              </li>
                              <li>
                                   <button class="btn btn-danger icon sm">
                                        <i class="tim-icons icon-trash-simple"></i>
                                   </button>  
                                   ลบ
                              </li>
                              <li>
                                   <button class="btn btn-success icon sm">
                                        <i class="tim-icons icon-pin"></i>
                                   </button>  
                                   ปักหมุด
                              </li> 
                         </ul>
                    </div>
                    <div class="col-9">
                         <div v-show="eventPro == 'add'">
                              <img src="/img/help/config/promotion/newitem.png" style="width: 100%;" alt="dashboard"> 
                              <div class="mt-3">
                                   <base-table :columns="table.columns" :data="table.data" thead-classes="text-primary"></base-table>
                              </div>
                         </div> 
                    </div>
               </div>
          </div>
        </section>
        <section class="mt-3 mb-3" ref="AFLink" id="AFLink">
          <h3>รายการลิ้งค์ AF</h3>
          <div class="body-htlp-tag"></div>
        </section>  
     </div>
</template>
<script>
import { BaseTable } from "@/components";
export default { 
     components: {
          BaseTable
     },
     props: {
          tag:{
               type:String,
               default:'configbank'
          }
     }, 
     data() {
          return { 
               event:'add',
               eventPro:'add',
               boxswitch:false,
              table:{
                    columns:['title','type','description'],
                    data:[
                         {
                              title:'หัวข้อ',
                              type:'Input',
                              description:'กรอกหัวข้อของโปรโมชั่้น'
                         },
                         {
                              title:'รายละเอียด',
                              type:'Input',
                              description:'บรรยายรายละเอียดของโปรโมชั่น'
                         },
                         {
                              title:'อัพโหลด',
                              type:'Image',
                              description:'รูปบรรยายโปรโมชั่น'
                         },
                         {
                              title:'รูปแบบคูปอง (Free credit)',
                              type:'checked',
                              description:'ถ้าปกติแล้วจะปิดทำงานอยู่เพราะใช้สำหรับเครดิตฟรีเท่านั้น'
                         },
                         {
                              title:'รูปแบบ',
                              type:'select',
                              description:'ไม่ต้องเลือกส่วนนี้เลย'
                         },
                         {
                              title:'จำนวนโบนัส',
                              type:'Number',
                              description:'จำนวนโบนัสเป็นจำนวนที่ผู้ใช้จะได้รับ ต้องดูจาก รูปแบบการเพิ่ม ด้วย ดังตัวอย่าง คือ 100 แล้ว รูปแบบการเพิ่ม เช่น เปอร์เซน ฝาก 20 เพิ่มอีก 100% = 40 แต่ถ้าเป็นรูปแบบคูณ ฝาก 20 คูณ 2 = 40'
                         },
                         {
                              title:'รูปแบบการเพิ่ม',
                              type:'เปอร์เซน - บวก',
                              description:'รูปแบบการเพิ่มของเครดิต เช่น เปอร์เซน ฝาก 20 เพิ่มอีก 100% = 40 แต่ถ้าเป็นรูปแบบคูณ ฝาก 20 คูณ 2 = 40'
                         },
                         {
                              title:'ต้องทำยอด Turn เคดิต',
                              type:'Number',
                              description:'กรอกเพื่อระบบยอดที่ต้องทำให้เครดิตถึงยอดแล้วถึงถอนได้'
                         },
                         {
                              title:'รูปแบบยอด',
                              type:'ปกติ,คูณ',
                              description:'รูปแบบคือการระบุว่าเครดิตที่ใช่ไปนั้นจะต้องทำยอดแบบคูณหรือปกติตามที่ระบุงใน (ต้องทำยอด Turn เคดิต) เช่นตัวอย่าง ต้องทำยอดให้ครบ 500 แบบปกติไม่ต้องทำยอดแบบคูณ'
                         },
                         {
                              title:'จำกัดการฝาก',
                              type:'Number',
                              description:'จำกัดรายการฝากสำหรับโปรนี้เช่น ฝาก 50 ระบ 100'
                         },
                         {
                              title:'จำกัดยอดรับเครดิต',
                              type:'Number',
                              description:'จำกัดรายการฝากสำหรับโปรนี้เช่น ฝาก 50 ระบ 100'
                         },
                         {
                              title:'จำกัดยอดถอน',
                              type:'Number',
                              description:'ไม่ว่าจะทำยอดมากแค่ใหนถ้าตกเงินไขถอนยังไงก็ไม่เกิด'
                         },
                         {
                              title:'จำนวนครั้ง',
                              type:'Number',
                              description:'จำนวนตรั้งที่ต้องการร้บเช่น 1 ต่อ1วัน'
                         },
                         {
                              title:'รูปแบบจำนวนครั้ง',
                              type:'รับได้ตลอด,วันละครั้ง,รับได้ครั้งเดียว',
                              description:'ประเภทของข้อกำจัด'
                         },
                         {
                              title:'รูปแบบยอด Turn',
                              type:'Turn เครดิต,Turn Over',
                              description:'รูปแบบเงือนไขว่าจะวัดจากเงือนใขของโปรโมชั่น'
                         }
                    ]
              }
          }
     },
     filters:{
          switchoffon(evt){
               return (evt)?'เปิดการใข้งาน':'ปิดการทำงาน'
          }
     },
     computed:{
          seclectTage(){ 
               try {
                    let ele = this.$refs[this.tag]; 
                    if(ele.offsetTop > 0){ 
                         ele.scrollIntoView({behavior: 'smooth'})
                         // window.scrollTo(ele.offsetTop);
                    } 
               } catch (error) {}
          }
     },
     mounted() {
          
     },
}
</script>
<style scoped>
.body-htlp-tag{
     margin-bottom: 50px;
     border-bottom: dotted 2px rgb(59, 58, 58);
     padding-bottom: 50px !important;
}
.btn-action {
     background: #c8c8c8;
}
.iconmenu,.iconmenu-sub{
     margin: 0px;
     padding: 0px;
}
.iconmenu > li {
     display: block;
}
.iconmenu-sub > li{
     display: inline-block;
}
</style>