<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากยูเซอร์ของผู้เล่น ... <Enter>" v-on:keyup.enter="searchWord()">
          </base-input>
        </card>
        <card>
           <label style="font-size: 17px;"> <strong style="margin-right: 20px;color: #f00;">TIP: </strong> การจัดเรียง</label>
           <div class="guide">
             <ul>
                <li>รายการที่โชว์ตอนนี้ล่าสุดจะอยู่บนสุด</li>
             </ul>
           </div>
        </card>
        <card>
           <label style="font-size: 17px;"> <strong style="margin-right: 20px;color: #f00;">TIP: </strong> สีและประเภท</label>
           <div class="guide mt-2 mb-2">
             <ul>
               <li style="color: #F00;">-x.x</li>
               <li> <span style="color: #F00;">สีแดง</span> คือการ <u>แทง</u> </li>
               <li style="color: #38ff00;">-x.x</li>
               <li> <span style="color: #38ff00;">สีเขียว</span> คือ <u>ผลลัพท์</u> ที่ได้</li>
               <li style="color: #FF0;">-x.x</li>
               <li> <span style="color: #FF0;">สีเหลือง</span> คือ <u>ผลลัพท์</u> ที่ไม่ได้</li>
               <li style="color: #00e7ff">ฟรีสปิน</li>
               <li> <span style="color: #00e7ff;">สีฟ้า</span> คือ <u>แทง</u> ฟรีสปิน</li>
               <li>-</li>
               <li> <strong>[แทง]</strong>  จะเป็น <span style="color: #F00;">สีแดง</span>  </li>
               <li> <strong>[ผลลัพท์]</strong>  <span style="color: #38ff00;">สีเขียว</span> และ <span style="color: #FF0;">สีเหลือง</span>  </li>
             </ul>
           </div>
           <div class="guide mt-2 mb-2">
             <ul>
               <li style="color: #ff4ae9">-x.x</li>
               <li> <span style="color: #ff4ae9">สีม่วง</span> คือ ยอดก่อนเล่น</li>
               <li style="color: #5a6eed">x.x</li>
               <li> <span style="color: #5a6eed">สีน้ำเงิน</span> คือ ยอดล่าสุด</li>
             </ul>
           </div>
        </card>
      </div>
      <div class="table-responsive">
        <base-table-games-logs :data="table.data" :columns="table.columns" @withd-modals="prodsEvet"  thead-classes="text-primary"> </base-table-games-logs>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>

    </card>
  </div>
  <!-- //////// -->

  <modal :show.sync="modals.open"  body-classes="p-0"  modal-classes="modal-dialog-centered tableScors">
     <card type="secondary" header-classes="bg-white pb-5" body-classes=" px-lg-5 py-lg-5" class="border-0 mb-0" v-if="modals.eve == 'info'">
       <div class="row">
         <!-- <div class="col-12"> <h4><strong>LOG  : </strong> {{modals.data.main.gameId}}</h4> </div> -->
         <div class="mains col-12">
           <div class="row">
             <div class="col-2" style="position: relative;">
               <div class="boxIconGame">
                 <div class="iconGameM" v-html="reqImg(modals.data.main.platformCode,'pf')"> </div>
                 <div class="iconGame" v-html="reqImg(modals.data.main.gameImg,modals.data.main.platformCode)"> </div>
               </div>
             </div>
             <div class="col-10">
               <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">gameId</th>
                    <td>{{modals.data.main.gameId}}</td>
                    <th scope="row">type</th>
                    <td>{{modals.data.main.type}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Platform</th>
                    <td>{{modals.data.main.platformCode}}</td>
                    <th scope="row">IP</th>
                    <td>{{modals.data.main.ip}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Game</th>
                    <td>{{modals.data.main.gameName}}</td>
                    <th scope="row">Date</th>
                    <td>{{formatDate(modals.data.main.updated_at)}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Start Credit</th>
                    <td>{{modals.data.main.credit}}</td>
                    <th scope="row">End Credit</th>
                    <td>{{endCredit(modals.data.logs)}}</td>
                  </tr>
                  <tr>
                    <th><img src="/img/icons_sys/debit.png" style="width: 32px;"></th>
                    <td>{{ modals.data.amount.debit }}</td>
                    <th><img src="/img/icons_sys/credit.png" style="width: 32px;"></th>
                    <td>{{ modals.data.amount.credit}}</td>
                  </tr>
                </tbody>
              </table>
             </div>
           </div>
         </div>
         <div class="logs col-12">
           <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th>Method</th>
                <th scope="col">Amount</th>
                <th scope="col">Before</th>
                <th scope="col">After</th>
                <th scope="col">Round Id</th>
                <th scope="col">Bet time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(column, index) in modals.data.logs" :key="index">
                <th scope="row">{{index+1}}</th>
                <td v-if="column.method == 'debit'"> <img src="/img/icons_sys/debit.png" style="width: 32px;"> </td>
                <td v-if="column.method == 'credit'"> <img v-if="column.amount > 0" src="/img/icons_sys/credit.png" style="width: 32px;"><img v-if="column.amount == 0" src="/img/icons_sys/tare.png" style="width: 32px;"> </td>
                <td v-if="column.method == 'debit'"><span style="color: #F00;">-{{column.amount}}</span></td>
                <td v-if="column.method == 'credit'"><span style="color: #0b9f04;" v-if="column.amount > 0">+{{column.amount}}</span><span style="color: #ffd100;" v-if="column.amount == 0">{{column.amount}}</span></td>
                <td>{{column.before}}</td>
                <td>{{column.after}}</td>
                <td>{{column.roundId}}</td>
                <td>{{BetTimes(column.betTime)}}</td>
              </tr>
            </tbody>
          </table>
         </div>
       </div>
       <template slot="footer">
         <base-button type="secondary" class="ml-auto" @click="modals.open = false">Close  </base-button>
       </template>
     </card>
  </modal>
  <!-- //////// -->
</div>
</template>
<script>
import { BaseTableGamesLogs , Modal} from "@/components"; 
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "platformCode", "gameImg" , "type",  "amount", "before" ,"after",  "gameId" ,  "roundId" , "betTime"];
const tableData = [];

export default {
  components: {
    BaseTableGamesLogs,
    SlidingPagination,
    Modal,
    Loading
  },
  data() {
    return {
      table: {
        title: "รายการผู้เล่นแต่ละห้อง",
        columns: [...tableColumns],
        data: [...tableData]
      },
      modals: {
        open: false,
        eve: '',
        data :{},
      },
      nextPageBar : '/gameslog/',
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    formatDate(timestamp){
      let timeSs = (timestamp)? new Date(timestamp) : new Date();
      let timeS = (timeSs == 'Invalid Date')?new Date(parseInt(timestamp)):timeSs;
      let d = timeS,
          hour = d.getHours(),
          minutes = d.getMinutes(),
          seconds = d.getSeconds(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;
      return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
    },
    reqImg(imgs,partf){
      if(partf == 'pf'){
        return `<img src="/img/icons_games/${imgs}.png"></img>`;
      }else{
        if(imgs == '/'){
          return `<img src="https://images.sharkbet777.com/typegame/${partf}.png"></img>`;
        }else{
          return `<img src="https://images.sharkbet777.com/iconsGame/${partf}/${imgs}.png"></img>`;
        }
      }
    },
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          // console.log('nextPageBar',this.nextPageBar);
          let tableReq = await axios(config.api('get', `${this.nextPageBar}${selectedPage}`));
          this.table.data = utils.sortB({'_id':tableReq.data.ResultData.skipdata});
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    BetTimes(times){
      let IntTime =  parseInt(times);
      if(IntTime.toString().length < 11){
        return utils.forMatDateTimes(IntTime*1000); // parseInt
      }else{
        return utils.forMatDateTimes(IntTime); // parseInt
      }
    },
    prodsEvet(item){
       if(utils.permission('GAMESLOG_VIEW')){
         /// get log game
         this.isLoading = true;
         (async () => {
           try {
             let tableReq = await axios(config.api('get', `/gameslog/findid/${item.item.group}`));
             if(tableReq.data.ResultData){
               this.isLoading = false;
               this.modals.open = true;
               this.modals.eve = 'info';
               this.modals.data = tableReq.data.ResultData;
                // console.log('tableReq',tableReq.data.ResultData);
             }else{
               this.isLoading = false;
               this.$alert('มีปัญหาจากการดึงข้อมูล','Permission','warning');
             }

           } catch (e) {
             this.isLoading = false;
             this.$alert('มีปัญหาจากการดึงข้อมูล','Permission','warning');
           }
         })();
         //  if(item.eve == 'activeInfo'){
         //    this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
         //  //  this.$fire({
         //  //   type: 'info',
         //  //   title: 'ทำรายการ',
         //  //   text: item.item.commit,
         //  //   footer: `โดย : ${item.item.admin}`
         //  // });
         // }else{
         //   item.item.join = item.item.join.sort((a, b) => (b.top > a.top ? -1 : 1));
         //   this.modals.open = true;
         //   this.modals.eve = item.eve;
         //   this.modals.data = item.item;
         // }
           // item.item.join = item.item.join.sort((a, b) => (b.top > a.top ? -1 : 1));
           // this.modals.open = true;
           // this.modals.eve = item.eve;
           // this.modals.data = item.item;
          // console.log('item.item',item.item);
         // this.$alert('dddddddd','Permission','warning');
       }else{
         this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
       }
    },
    searchWord(){
      if(utils.permission('GAMESLOG_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              this.nextPageBar = `/gameslog/`
              let tableReq = await axios(config.api('get', `${this.nextPageBar}1`));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = utils.sortB({'_id':tableReq.data.ResultData.skipdata});
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              this.nextPageBar = `/gameslog/search/${this.searchName}/`
              let tableReq = await axios(config.api('get', `${this.nextPageBar}1`));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = utils.sortB({'_id':tableReq.data.ResultData.skipdata}); 
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    endCredit(logdata){
      console.log('logdata',logdata);
      let index = logdata.length-1
      return logdata[index].after;
    }

  },
  mounted(){
    ///// GET List True
    if(utils.permission('GAMESLOG_VIEW')){
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/gameslog/1'));
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = utils.sortB({'_id':tableReq.data.ResultData.skipdata});
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
        this.searchName = (params.gid != null )?params.gid:'';
        this.searchWord();
      })();
      //////

    }else{
      this.isLoading = false;
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
.tableScors{
  max-width: 70%!important;
}
.mains {
  padding: 15px;
  background: #1c1e30;
}
.logs {
  margin-top: 10px;
  padding: 15px;
  background: #1c1e30;
}
.boxIconGame{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.iconGame{
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 20px;
  background: rgb(92,90,91);
  background: linear-gradient(149deg, rgba(92,90,91,1) 0%, rgba(46,43,44,1) 50%, rgba(38,34,35,1) 51%, rgba(38,34,35,1) 100%);
  border: solid 2px #074526;
  line-height: 100px;
  text-align: center;
  position: relative;
  margin-top: -10px;
}
.iconGame > img{
  min-width: 119px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.iconGameM{
  width: 66px;
  overflow: hidden;
  background: #276848;
  border: solid 2px #074526;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-left: 18px;
}
.guide > ul {
  margin: 0px;
  padding: 0px;
}
.guide > ul > li{
  display: inline-block;
  margin: 0px 10px;
}
</style>
