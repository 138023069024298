<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากชื่อ ... <Enter>" v-on:keyup.enter="searchWord()">
          </base-input>
        </card>
      </div>
      <div class="mb-3">
        <card>
          <strong>Help : </strong>
            <base-button type="info" style="margin: 0px 5px;" icon size="sm" disabled>
              <i class="tim-icons icon-single-02"></i>
            </base-button> ระบบได้ MAP กับ สมาชิกแล้ว(ดูข้อมูลสมาชิก)
            <base-button type="danger" style="margin: 0px 5px;" icon size="sm" disabled>
              <i class="tim-icons icon-alert-circle-exc"></i>
            </base-button> ไม่รู้ที่มาว่ามาจากสมาชิกใหน (โอนแต้มให้กับสมาชิก)
        </card>
      </div>
      <div class="table-responsive">
        <base-table-true-wallet :data="table.data" :permis="permis" :columns="table.columns" @user-modals="findUserEvet" thead-classes="text-primary"> </base-table-true-wallet>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>
  <!-- //////// -->


  <modal :show.sync="modals.open"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
          <template>
            <strong>TrueMoney :</strong> {{ modals.title }}
          </template>
          <template v-if="modals.data.owner == null">
              <div class="text-center text-muted mb-4">
                  <small>แก้ไขการเติมเงิน</small>
              </div>
              <form role="form">
                  <label for="">ยอดเข้า</label>
                  <base-input alternative
                              class="mb-3"
                              placeholder="0"
                              v-model="modals.data.amount"
                              addon-left-icon="tim-icons icon-money-coins" disabled>
                  </base-input>
                  <label for="">เบอร์โทรสมาชิกที่ต้องการแมท</label>
                  <base-input alternative
                              type="text"
                              placeholder="092xxxxx"
                              v-model="modals.data.telMember"
                              addon-left-icon="tim-icons icon-badge">
                  </base-input>
                  <label for="">แจ้งหมายเหตุว่า</label>
                  <base-input alternative
                              type="text"
                              placeholder="แจ้งหมายเหตุว่า"
                              v-model="modals.data.commit" >
                  </base-input>
              </form>
          </template>
          <template v-if="modals.data.owner != null">
              <div class="mb-1">
                  <strong>UID: </strong> {{modals.data.owner._id}}
              </div>
              <div class="mb-1">
                  <strong>Name: </strong> {{modals.data.owner.name}}
              </div>
              <div class="mb-1">
                  <strong>Tel: </strong> {{modals.data.owner.tel}}
              </div>
              <div class="mb-1">
                  <strong>Commit: </strong> {{modals.data.owner.commit || '-'}}
              </div>
          </template>
          <template slot="footer">
            <base-button type="secondary" @click="modals.open = false">Close</base-button>
            <base-button v-show="modals.data.telMember && modals.data.commit" type="primary" style="width: 225px;" @click="saveChanges()">Save changes</base-button>
          </template>
      </card>
  </modal>



</div>
</template>
<script>
import {
  BaseTableTrueWallet,
  Modal
} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "owner", "amount",  "from" , "balance", "created_at","active"];
const tableData = [];

export default {
  components: {
    BaseTableTrueWallet,
    SlidingPagination,
    Modal,
    Loading
  },
  data() {
    return {
      permis:{
        delete: utils.permission('TRUEWALLET_DEL')
      },
      table: {
        title: "TRUE WALLET",
        columns: [...tableColumns],
        data: [...tableData]
      },
      modals: {
        open: false,
        title: '',
        data :{},
        telMember: '',
        commit: ''
      },
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/truewallet/'+selectedPage));
          //console.log('tableReq', tableReq.data);
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    findUserEvet(itesObj){
      if(utils.permission('TRUEWALLET_DEL')){
        this.$prompt("เหตุผลของการกระทำ").then((commit) => {
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/TransferSuccess/rejact',{'data':itesObj.data,'commit':commit}));
              if(tableReq.data.ResultData.nModified > 0){
                this.$alert('บันทึกเรียบร้อย','Success','success');
                this.table.data.splice(itesObj.index, 1);
                this.isLoading = false;
              }else{
                this.$alert('การแก้ไขไม่สำเร็จ','Error','error');
              }
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        },(error)=>{});
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    saveChanges(){
      if(utils.permission('TRUEWALLET_EDIT')){
        if(this.modals.data.telMember && this.modals.data.commit){
          this.isLoading = true;
          (async () => {
            try {
              let addPoint = await axios(config.api('post', '/truewallet/addPoint',{id:this.modals.data._id,tel:this.modals.data.telMember,commit:this.modals.data.commit}));
               console.log('addPoint',addPoint.data);
               if(addPoint.data.Code == 200){
                 this.modals.open = false;
                 this.isLoading = false;
                 this.modals.data.status = addPoint.data.ResultData.status
                 this.modals.data.owner = addPoint.data.ResultData.owner
               }else{
                 console.log('ERROR_ADDPOINT');
               }
            } catch (error) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else{
          this.modals.open = false;
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    searchWord(){
      if(utils.permission('TRUEWALLET_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/truewallet/1'));
              //console.log('tableReq', tableReq.data);
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/truewallet/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    }

  },
  mounted(){
    ///// GET List True
    if(utils.permission('TRUEWALLET_VIEW')){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/truewallet/1'));
          //console.log('tableReq', tableReq.data);
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = tableReq.data.ResultData.skipdata;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }else{
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      this.isLoading = false;
    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
