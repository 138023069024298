<template>
    <div class="row">
        <div class="col-lg-10">
            <card title="ช่วยเหลือ"> 
                <div v-for="(item, index) in memnu" :key="index" class="box-drop" :class="{butt:(showItemMain != item.path)}">
                    <button class="btn w-100" @click="selectTag(item)">{{item.name}}</button>
                    <div class="content p-2" v-show="showItemMain == item.path">
                        <report :tag="showItemSub" v-if="showItemMain == 'report'"></report>
                        <memners :tag="showItemSub" v-if="showItemMain == 'memners'"></memners>
                        <transfer :tag="showItemSub" v-if="showItemMain == 'transfer'"></transfer>
                        <config :tag="showItemSub" v-if="showItemMain == 'config'"></config>
                        <notify :tag="showItemSub" v-if="showItemMain == 'notify'"></notify>
                        <webpage :tag="showItemSub" v-if="showItemMain == 'webpage'"></webpage>
                        <logs :tag="showItemSub" v-if="showItemMain == 'logs'"></logs>
                    </div>
                </div>
            </card>
        </div>
        <div class="col-lg-2" style="position: relative;">
             <div class="help-menu">
                <ul v-for="(item, index) in memnu" :key="index">
                    <h4 class="m-0" style="font-weight: bold;">{{item.name}}</h4>
                    <li @click="selectTag(item,indexs)" :class="{active:(showItemSub==items.path)}" v-for="(items, indexs) in item.children" :key="indexs">
                        <i class="tim-icons icon-bulb-63"></i> {{items.name}}
                    </li>
                </ul>
             </div>
        </div> 
    </div>
</template>
<script> 
import report from './report.vue';
import memners from './memners.vue';
import transfer from './transfer.vue';
import config from './config.vue';
import webpage from './webpage.vue';
import logs from './logs.vue';
import notify from './notify.vue';

import Memnu from '@/data/help_menu.json';
export default {
    components:{
        report,
        memners,
        transfer,
        config,
        webpage,
        logs,
        notify
    },
    data() {
        return {
            memnu:Memnu,
            showItemMain: 'report',
            showItemSub: '#dashboard'
        }
    },
    methods: { 
        selectTag(item,index = 0){  
            this.showItemMain = item.path;
            this.showItemSub = item.children[index].path 
        }
    }, 
}
</script>
<style scoped>
    .help-menu{
        position: fixed;
        margin: 0;
        padding: 10px; 
        border: dotted 2px rgb(72, 72, 72);
        border-radius: 10px;
    }
    .help-menu > ul{
        margin: 0px;
        padding: 8px;
    }
    .help-menu > ul > li{
        display: block;
        padding: 2px;
        cursor: pointer;
    }
    .help-menu > ul > li:hover,.help-menu > ul > li.active{
        border-bottom: dotted 2px rgb(72, 72, 72);
    }
    .card .card-header .card-title {
        color: #ffffff;
        background-color: rgb(5, 112, 206);
        font-weight: 100;

    }
    .box-drop{
        border-radius: 10px;
        border: solid 2px #2f3e65;
        margin-bottom: 10px;
    }
    .box-drop > button{
        margin: 0px;
    }
    .butt > button{
        background: #bcbdbd !important;
    }
    .butt {
        border: solid 0px #2f3e65 !important;
    }

</style>