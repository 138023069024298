<template>
     <div>  
         {{seclectTage}}
        <section class="mb-3" ref="forwardotifi">
          <h3>Push Notification Forward HTTP</h3>
          <div class="body-htlp-tag">
               <div class="row mt-4">
                <div class="col-md-2">
                    <img src="https://play-lh.googleusercontent.com/e_4rOKx-TjfZMGS2Lf9K2IOoaYYxIDAPYEv32yeWYxFr9DwYeCazYFUjLQGLIBzZhg=w480-h960-rw" style="width: 100%;" alt="">
                </div>
                <div class="col-md-10">
                    <p>เป็นแอปพลิเคชันที่บันทึกการแจ้งเตือนแบบพุชเป็น HTTP {S} การส่งต่อ / ประวัติ</p>
                    <p class="m-0">ข้อมูลที่ได้รับในการแจ้งเตือน</p>
                    <p class="m-0">คุณสามารถ HTTP POST รับไปยัง URL ที่กำหนดไว้!....</p>
                    <p class="mt-4">
                        <a href="https://play.google.com/store/apps/details?id=com.jojoagogogo.nf&pcampaignid=web_share" class="btn btn-success btn-lg text-white" target="_blank" > <i class="pe-7s-download" style="font-size: 20px;"></i> DOWNLOAD NOW</a>
                    </p>
                </div>
                <div class="col-md-12 mt-5 mb-5">
                    <div class="row">
                        <div class="col-md-2">
                            <img src="https://play-lh.googleusercontent.com/Jg9xyEF_ShnhASkeYLVCWVHrEyKl1IJQHupWIcDldIwg1yo0YZLvETfNSmi95NIO0GGS=w5120-h2880-rw" style="width: 100%;" alt="">
                        </div>
                        <div class="col-md-2">
                            <img src="https://play-lh.googleusercontent.com/FEwJn_jzADkLIO5RGsTSy1VgQwhYP2gt-MZfbMtnKsJ6eE9-tvYzvlGID7yEFq8cg7A=w5120-h2880-rw" style="width: 100%;" alt="">
                        </div>
                        <div class="col-md-2">
                            <img src="https://play-lh.googleusercontent.com/ko5wQQqKE8yirHj8MemBPuOvFvIPy-oln_bV-tidytGhvD8IjaoK658uz2FSjGV3eyY=w5120-h2880-rw" style="width: 100%;" alt="">
                        </div>
                        <div class="col-md-2">
                            <img src="https://play-lh.googleusercontent.com/HE79N68HdkWN2AxqHw1jMVbCzpu7y3kXgPL1W0_IOEyZY_HxNfm8vqTG3h2H4TCSs1w=w5120-h2880-rw" style="width: 100%;" alt="">
                        </div>
                        <div class="col-md-2">
                            <img src="https://play-lh.googleusercontent.com/_m40goybxu5ArQT4QeHC19jszWY-Iaf_g6l0EgOPcEP9-Jixy03W_4tPcO_WUJz7pH8=w5120-h2880-rw" style="width: 100%;" alt="">
                        </div>
                        <div class="col-md-2">
                            <img src="https://play-lh.googleusercontent.com/E9P42vI3Em8Ena8wQeQ4iz-XfZbsrMDszB5yLBPT3l_7IMiCLBFeQQFdoo3icsQRJrY=w5120-h2880-rw" style="width: 100%;" alt="">
                        </div>
                    </div>
                </div>
               </div>
          </div>
        </section>
        <section class="mb-3" ref="setdata">
          <h3>ตั้งค่าการเข้าถึงข้อมูล</h3>
          <div class="body-htlp-tag">
               <div class="row mt-4">
                    <div class="col-md-6">
                         <div class="row">
                         <div class="col-md-4">
                              <img src="/img/help/setup/1.jpg" alt="" style="width: 100%;">
                         </div>
                         <div class="col-md-4">
                              <img src="/img/help/setup/2.jpg" alt="" style="width: 100%;">
                         </div>
                         <div class="col-md-4">
                              <img src="/img/help/setup/3.jpg" alt="" style="width: 100%;">
                         </div> 
                         </div>
                    </div>
                    <div class="col-md-6"> 
                         <p>เมือดาวน์เสร็จแล้วก็ทำการให้สิทธิ์กับโปรแกรเพื่อเข้าถึงข้อมูลข้อความและการแจ้งเตื่อนต่างๆ</p>
                         <p class="m-0"><strong>ภาพที่ 1</strong></p>
                         <p>คลิกที่มุมขวาตามไอคอนของรูปภาพ</p> 
                         <p class="m-0"><strong>ภาพที่ 2</strong></p>
                         <p>เลือก  Notification Forward / Save App </p> 
                         <p class="m-0"><strong>ภาพที่ 3</strong></p>
                         <p>เลือกทุกอย่างเป็นอันเสร็จ </p> 
                    </div>
               </div>
          </div>
        </section>
        <section class="mb-3" ref="setbankscb">
          <h3>ตั้งค่า SCB Notification and SMS</h3>
          <div class="body-htlp-tag">
               <div pills card class=" row">
                    <div class="col-12">
                         <div style="float: left; padding: 10px; width: 49%;"><button @click="tabnoti = 'sms'" class="w-100 btn btn-warning" :class="{'btn-warning':(tabnoti == 'sms'),'btn-action':(tabnoti != 'sms')}">แบบ SMS</button></div>
                         <div style="float: left; padding: 10px; width: 49%;"><button @click="tabnoti = 'app'" class="w-100 btn btn-action" :class="{'btn-warning':(tabnoti == 'app'),'btn-action':(tabnoti != 'app')}">แบบ Application</button></div>
                    </div>
                    <div class="col-12" title="แบบ SMS" v-show="tabnoti == 'sms'">
                         <div class="row mt-4">
                         <div class="col-md-6">
                              <div class="row">
                                   <div class="col-md-6">
                                        <img src="/img/help/scb/1-sms.jpg" alt="" style="width: 100%;">
                                   </div>
                                   <div class="col-md-6">  
                                        <img src="/img/help/scb/2.jpg" alt="" style="width: 100%;">
                                   </div> 
                              </div>
                         </div>
                         <div class="col-md-6">  
                              <p class="m-0"><strong>ภาพที่ 1</strong></p>
                              <p class="m-0">(1) เลือกเมนู Application</p> 
                              <p class="m-0">(2) เลือก APP แบบข้อความ</p> 
                              <p>แนะนำให้สมัตน SCB Alert ก่อน</p>
                              <p class="m-0"><strong>ภาพที่ 2</strong></p>
                              <p class="m-0">(1) เลือก  Use Forward</p> 
                              <p class="m-0">(2) กรอก URL ตามภาษาของการแจ้งเติอน</p>  
                              <p class="m-0">ภาษา <b>ไทย</b> forward URL https://notify.goodg.biz/noti/th</p>  
                              <p class="m-0">ภาษา <b>Eng</b> forward URL https://notify.goodg.biz/noti/en</p>  
                              <p class="m-0">(3) เลือก HTTP method POST</p>  
                              <p class="m-0">เลือนลงมา ----> </p>  
                              <p class="m-0">(4) จากเดิมเป็นช่องว่างให้พิมพ์ <b>ref1</b> ลงไป</p>   
                              <p class="m-0">(5) Key ของการลงเทียนของบัญชีนั้นๆแต่ละบัญชี Key จะไม่เหมือนกัน</p>
                              <p class="m-0">(6) จากเดิมเป็นช่องว่างให้พิมพ์ <b>ref2</b> ลงไป</p>   
                              <p class="m-0">(7) กรอกคำว่า <b>scb</b></p>  
                              <p class="m-0">(8) ทำการบันทึก</p>  
                         </div>
                         </div>
                    </div>
                    <div class="col-12" title="แบบ Application" v-show="tabnoti == 'app'">
                         <div class="row mt-4">
                         <div class="col-md-6">
                              <div class="row">
                                   <div class="col-md-6">
                                        <img src="/img/help/scb/1-app.jpg" alt="" style="width: 100%;">
                                   </div>
                                   <div class="col-md-6">  
                                        <img src="/img/help/scb/2.jpg" alt="" style="width: 100%;">
                                   </div> 
                              </div>
                         </div>
                         <div class="col-md-6">  
                              <p class="m-0"><strong>ภาพที่ 1</strong></p>
                              <p class="m-0">(1) เลือกเมนู Application</p> 
                              <p class="m-0">(2) เลือก APP SCB</p> 
                              <p>แนะนำให้ตั้งค่าการแจ้งที่ใน APP SCB ESEY เสียก่อน</p>
                              <p class="m-0"><strong>ภาพที่ 2</strong></p>
                              <p class="m-0">(1) เลือก  Use Forward</p> 
                              <p class="m-0">(2) กรอก URL ตามภาษาของการแจ้งเติอน</p>  
                              <p class="m-0">ภาษา <b>ไทย</b> forward URL https://notify.goodg.biz/noti/th</p>  
                              <p class="m-0">ภาษา <b>Eng</b> forward URL https://notify.goodg.biz/noti/en</p>  
                              <p class="m-0">(3) เลือก HTTP method POST</p>  
                              <p class="m-0">เลือนลงมา ----> </p>  
                              <p class="m-0">(4) จากเดิมเป็นช่องว่างให้พิมพ์ <b>ref1</b> ลงไป</p>   
                              <p class="m-0">(5) Key ของการลงเทียนของบัญชีนั้นๆแต่ละบัญชี Key จะไม่เหมือนกัน</p>
                              <p class="m-0">(6) จากเดิมเป็นช่องว่างให้พิมพ์ <b>ref2</b> ลงไป</p>   
                              <p class="m-0">(7) กรอกคำว่า <b>scb</b></p>
                              <p class="m-0">(8) ทำการบันทึก</p>
                         </div>
                         </div>
                    </div>
               </div>
          </div>
        </section>
        <section class="mb-3" ref="setbankkbang">
          <h3>ตั้งค่า KBANK Notification</h3>
          <div class="body-htlp-tag">
               <div class="row tabs"> 
                    <div class="col-12">
                         <div style="float: left; padding: 10px; width: 49%;"><button @click="tabnotik = 'sms'" class="w-100 btn btn-warning" :class="{'btn-warning':(tabnotik == 'sms'),'btn-action':(tabnotik != 'sms')}">แบบ Notification</button></div>
                         <div style="float: left; padding: 10px; width: 49%;"><button @click="tabnotik = 'app'" class="w-100 btn btn-action" :class="{'btn-warning':(tabnotik == 'app'),'btn-action':(tabnotik != 'app')}">ตั้งแจ้งเตือน ธนาคารกสิกร</button></div>
                    </div>
                    <div class="col-12" title="ตั้งค่าส่งข้อความ" v-show="tabnotik == 'sms'">
                         <div class="row mt-4">
                              <div class="col-md-6">
                                   <div class="row">
                                        <div class="col-md-6">
                                             <img src="/img/help/kbank/1.jpg" alt="" style="width: 100%;">
                                        </div>
                                        <div class="col-md-6">
                                             <img src="/img/help/kbank/2.jpg" alt="" style="width: 100%;">
                                        </div> 
                                   </div>
                              </div>
                              <div class="col-md-6">  
                                   <p class="m-0"><strong>ภาพที่ 1</strong></p>
                                   <p class="m-0">(1) เลือกเมนู Application</p> 
                                   <p class="m-0">(2) เลือก APP ธนาคารกสิกร</p> 
                                   <p>แนะนำควรตั้งค่าการแจ้งเตือนจาก APP ธนาคารกสิกร ด้วย</p>
                                   <p class="m-0"><strong>ภาพที่ 2</strong></p>
                                   <p class="m-0">(1) เลือก  Use Forward</p> 
                                   <p class="m-0">(2) กรอก URL ตามภาษาของการแจ้งเติอน</p>  
                                   <p class="m-0">ภาษา <b>ไทย</b> forward URL https://notify.goodg.biz/noti/th</p>  
                                   <p class="m-0">ภาษา <b>Eng</b> forward URL https://notify.goodg.biz/noti/en</p>  
                                   <p class="m-0">(3) เลือก HTTP method POST</p>  
                                   <p class="m-0">เลือนลงมา ----> </p>  
                                   <p class="m-0">(4) จากเดิมเป็นช่องว่างให้พิมพ์ <b>ref1</b> ลงไป</p>   
                                   <p class="m-0">(5) Key ของการลงเทียนของบัญชีนั้นๆแต่ละบัญชี Key จะไม่เหมือนกัน</p>
                                   <p class="m-0">(6) จากเดิมเป็นช่องว่างให้พิมพ์ <b>ref2</b> ลงไป</p>   
                                   <p class="m-0">(7) กรอกคำว่า <b>kbank</b></p>  
                                   <p class="m-0">(8) ทำการบันทึก</p>  
                              </div>
                         </div>
                    </div> 
                    <div class="col-12" title="ตั้งแจ้งเตือน ธนาคารกสิกร" v-show="tabnotik == 'app'">
                         <div class="row">
                              <div class="col-md-4 mt-4 mb-4">
                                   <img src="/img/help/kbank/bank_noti/1.jpg" alt="" style="width: 100%;">
                              </div>
                              <div class="col-md-4 mt-4 mb-4">
                                   <img src="/img/help/kbank/bank_noti/2.jpg" alt="" style="width: 100%;">
                              </div>
                              <div class="col-md-4 mt-4 mb-4">
                                   <img src="/img/help/kbank/bank_noti/3.jpg" alt="" style="width: 100%;">
                              </div>
                              <div class="col-md-4 mt-4 mb-4">
                                   <img src="/img/help/kbank/bank_noti/4.jpg" alt="" style="width: 100%;">
                              </div>
                              <div class="col-md-4 mt-4 mb-4">
                                   <img src="/img/help/kbank/bank_noti/5.jpg" alt="" style="width: 100%;">
                              </div>
                              <div class="col-md-4 mt-4 mb-4">
                                   <img src="/img/help/kbank/bank_noti/6.jpg" alt="" style="width: 100%;">
                              </div> 
                         </div> 
                    </div>
               </div>
          </div>
        </section>
        <section class="mb-3" ref="setbanktrue">
          <h3>ตั้งค่า TRUE WALLET Notification</h3>
          <div class="body-htlp-tag">
               <div class="row tabs">
                    <div class="col-12">
                         <div style="float: left; padding: 10px; width: 49%;"><button @click="tabnotit = 'sms'" class="w-100 btn btn-warning" :class="{'btn-warning':(tabnotit == 'sms'),'btn-action':(tabnotit != 'sms')}">แบบ Notification</button></div>
                         <div style="float: left; padding: 10px; width: 49%;"><button @click="tabnotit = 'app'" class="w-100 btn btn-action" :class="{'btn-warning':(tabnotit == 'app'),'btn-action':(tabnotit != 'app')}">แจ้งเตือน (บริการจากทูมั้นนี่) ค่าทำเนียมรับค่าโอน</button></div>
                    </div>
                    <div class="col-12" title="แบบ Notification" v-show="tabnotit == 'sms'">
                         <div class="row mt-4">
                         <div class="col-md-6">
                              <div class="row">
                                   <div class="col-md-6">
                                        <img src="/img/help/tmn/1.jpg" alt="" style="width: 100%;">
                                   </div>
                                   <div class="col-md-6">
                                        <img src="/img/help/tmn/2.jpg" alt="" style="width: 100%;">
                                   </div>
                              </div>
                         </div>
                         <div class="col-md-6">
                              <p class="m-0"><strong>ภาพที่ 1</strong></p>
                              <p class="m-0">(1) เลือกเมนู Application</p>
                              <p class="m-0">(2) เลือก APP TrueMoney</p>
                              <p>แนะนำตั้งค่าการแจ้งเตือน True Wallet เสียก่อน</p>
                              <p class="m-0"><strong>ภาพที่ 2</strong></p>
                              <p class="m-0">(1) เลือก Use Forward</p>
                              <p class="m-0">(2) กรอก URL ตามภาษาของการแจ้งเติอน</p>
                              <p class="m-0">ภาษา <b>ไทย</b> forward URL https://notify.goodg.biz/noti/th</p>
                              <p class="m-0">ภาษา <b>Eng</b> forward URL https://notify.goodg.biz/noti/en</p>
                              <p class="m-0">(3) เลือก HTTP method POST</p>
                              <p class="m-0">เลือนลงมา ----> </p>
                              <p class="m-0">(4) จากเดิมเป็นช่องว่างให้พิมพ์ <b>ref1</b> ลงไป</p>
                              <p class="m-0">(5) Key ของการลงเทียนของบัญชีนั้นๆแต่ละบัญชี Key จะไม่เหมือนกัน</p>
                              <p class="m-0">(6) จากเดิมเป็นช่องว่างให้พิมพ์ <b>ref2</b> ลงไป</p>
                              <p class="m-0">(7) กรอกคำว่า <b>tmn</b></p>
                              <p class="m-0">(8) ทำการบันทึก</p>
                         </div>
                         </div>
                    </div>
                    <div class="col-12" title="แจ้งเตือน (บริการจากทูมั้นนี่) ค่าทำเนียมรับค่าโอน" v-show="tabnotit == 'app'">
                         <div class="row mt-4">
                         <div class="col-md-6">
                              <div class="row">
                                   <div class="col-md-4">
                                        <img src="/img/help/tmn/servicetrue/1.jpg" alt="" style="width: 100%;">
                                   </div>
                                   <div class="col-md-4">
                                        <img src="/img/help/tmn/servicetrue/2.jpg" alt="" style="width: 100%;">
                                   </div>
                                   <div class="col-md-4">
                                        <img src="/img/help/tmn/servicetrue/3.jpg" alt="" style="width: 100%;">
                                   </div>
                              </div>
                         </div>
                         <div class="col-md-6">
                              <p class="m-0"><strong>ภาพที่ 1</strong></p>
                              <p class="">เลือกเมนู ดูทั้งหมด จากหน้าโฮม</p>
                              <p class="m-0"><strong>ภาพที่ 2</strong></p>
                              <p class="">เลือนลงมายาวๆหาเมนนู "ค่าทำเนียมรับค่าโอน"</p>
                              <p class="m-0"><strong>ภาพที่ 3</strong></p>
                              <p class="m-0 text-danger">อ่านเงือนไขละค่าทำเนียมให้ดีก่อนทำรายการ</p>
                              <p class="">สรุปการเปิดใช้งานของ API webHook นี้คือ ต้องทำรายการทั้งหมด 100 ครั้งเสียก่อน
                                   แต่ละจะต้องไม่เกิน 10 บาท ครบ 100 ครั้งก็ให้กรอก URL ตามนี้</p>
                              <p class="m-0"><b>webHook URL</b> https://notify.goodg.biz/tmnserver/<b>{Key}</b></p>
                              <p class="m-0"><b>Key</b> ของการลงเทียนของบัญชีนั้นๆแต่ละบัญชี <b>Key</b> จะไม่เหมือนกัน</p>
                              <p class="m-0"><b>ตัวอย่าง URL</b>
                                   https://notify.goodg.biz/tmnserver/<b>669a8751aae42179b7d69a52</b></p>
                         </div>
                         </div>
                    </div>
               </div>
          </div>
        </section>
     </div>
</template>
<script>
export default { 
     props: {
          tag:{
               type:String,
               default:'forwardotifi'
          }
     }, 
     data(){
          return {
               tabnoti:'sms',
               tabnotik:'sms',
               tabnotit:'sms'
          }
     },
     computed:{
          seclectTage(){ 
               try {
                    let ele = this.$refs[this.tag]; 
                    if(ele.offsetTop > 0){ 
                         ele.scrollIntoView({behavior: 'smooth'})
                         // window.scrollTo(ele.offsetTop);
                    } 
               } catch (error) {}
          }
     },
     mounted() {
          
     },
}
</script>
<style scoped>
     .body-htlp-tag{
          margin-bottom: 50px;
          border-bottom: dotted 2px rgb(59, 58, 58);
          padding-bottom: 50px !important;
     }
     .btn-action {
          background: #c8c8c8;
     }
</style>