<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <div class="row">
          <div class="col-9">
            <card>
              <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากเลขบัญชีเลขท้าย/ชื่อยูเซอร์ ... <Enter>" v-on:keyup.enter="searchWord()">
              </base-input>
            </card>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-6">
                <base-button type="secondary" size="md" class="pull-right ml-3 mt-3" @click="searchWord()"> <i class="tim-icons icon-zoom-split"></i> ค้นหา </base-button>
              </div>
              <div class="col-6">
                <base-button type="warning" size="md" class="pull-right ml-3 mt-3" @click="Download()"> <i class="tim-icons icon-cloud-download-93"></i> XLSX </base-button>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <base-alert v-if="isAlert.open" type="danger" v-on:click="isAlert.open == false">
          <strong> {{isAlert.tip}} </strong> {{isAlert.msg}}
          <button type="button" aria-label="Close" class="close" @click="isAlert.open = false"><span aria-hidden="true"><i class="tim-icons icon-simple-remove"></i></span></button>
        </base-alert>
        <base-table-deposit-success :data="table.data" :columnsTh="table.columnsTh" :columns="table.columns" :permis="permis" @user-modals="eventProd" :sort="table.sort" @SortTable="SortTable" thead-classes="text-primary"> </base-table-deposit-success>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>


</div>
</template>
<script>
import { BaseTableDepositSuccess, Modal , BaseAlert} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "type",  "byacc", "amount", "balance", "from" , "account", "atdate" , "created_at", "active"];
const tableColumnsTh = [ "ธนคาร", "เลขบัญชี", "จำนวน", "คงเหลือ", "จากบัญชี" , "ยูเซอร์",  "เวลาโอน" , "วันที่", "กระทำ"];
const tableData = [];

export default {
  components: {
    BaseTableDepositSuccess,
    SlidingPagination,
    Modal,
    BaseAlert,
    Loading
  },
  data() {
    return {
      permis:{
        delete: utils.permission('LISTTRANSFERSUCCESS_DEL'),
        edit : utils.permission('LISTTRANSFERSUCCESS_EDIT')
      },
      table: {
        sort:{
          even:'desc',
          col:'created_at'
        },
        title: "รายการฝากสำเร็จ",
        columns: [...tableColumns],
        columnsTh: [...tableColumnsTh],
        data: [...tableData]
      },
      modals: {
        open: false,
        title: '',
        data :{},
        telMember: '',
        commit: ''
      },
      isAlert: {open:false,tip:'',msg:''},
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/TransferSuccess/'+selectedPage));
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    eventProd(itesObj) {
      ////// delete
      if(utils.permission('LISTTRANSFERSUCCESS_DEL') && itesObj.eve == 'delete'){
        this.$prompt("เหตุผลของการกระทำ").then((commit) => {
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/TransferSuccess/rejact',{'data':itesObj.data,'commit':commit}));
              if(tableReq.data.ResultData.nModified > 0){
                this.$alert('บันทึกเรียบร้อย','Success','success');
                this.table.data.splice(itesObj.index, 1);
                this.isLoading = false;
              }else{
                this.$alert('การแก้ไขไม่สำเร็จ','Error','error');
              }
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        },(error)=>{});
      }
      //console.log('itesObj',itesObj);
    },
    searchWord(){
      if(utils.permission('LISTTRANSFERSUCCESS_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/TransferSuccess/1'));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/TransferSuccess/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    saveChanges(){
      // if(utils.permission('LISTTRANSFERSUCCESS_EDIT')){
      //   if(this.modals.data.telMember && this.modals.data.commit){
      //     this.isLoading = true;
      //     axios(config.api('post', '/TransferSuccess/addPoint',{id:this.modals.data._id,tel:this.modals.data.telMember,commit:this.modals.data.commit})).then((addPoint)=>{
      //       this.modals.open = false;
      //       this.isLoading = false;
      //       this.modals.data.status = addPoint.data.ResultData.status
      //       this.modals.data.owner = addPoint.data.ResultData.owner
      //     },(error)=>{
      //       this.isLoading = false;
      //       this.modals.open = false;
      //       this.$alert(error.response.data.Message,'Error','error');
      //     });
      //   }else{
      //     this.modals.open = false;
      //   }
      // }else{
      //   this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      // }
    },
    Download(){
      if(this.table.data.length){
        this.isLoading = true; 
        let DataSheet = [
            {
                sheet:  'รายการฝากสำเร็จ',
                columns:[ 
                    { label: "ธนคาร", value: "type" },
                    { label: "เลขบัญชี", value: "byacc" },
                    { label: "จำนวน", value: "amount" },
                    { label: "คงเหลือ", value: "balance" },
                    { label: "จากบัญชี", value: "from" },
                    { label: "ยูเซอร์", value: "account" },
                    { label: "เวลาโอน", value: "atdate" }, 
                    { label: "เวลา", value: "created_at" , format:'h:mm AM/PM'},
                ],
                content:this.table.data
            }
        ];
        axios(config.api('post', `/toxlsx`,DataSheet)).then((response) => { 
          window.open(`${config.apiplayload}/${response.data.ResultData}`,'_blank');
          this.isLoading = false; 
        }, (error) => {
          this.isLoading = false;
          this.$alert(error.response.data.Message, 'Error', 'error');
        });
      }else{
        this.$alert('ยังไม่มีข้อมูล', 'ข้อความ', 'warning');
      }
    },
    SortTable(column){ 
      let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
      let ObjK = {};
      ObjK[column] = this.table.data;
      this.table.sort.col = column;
      this.table.sort.even = sortType; 
      this.table.data = utils.Sort[sortType](ObjK) 
    },
  },
  mounted(){
    ///// GET List True
    if(utils.permission('LISTTRANSFERSUCCESS_VIEW')){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/TransferSuccess/1'));
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = tableReq.data.ResultData.skipdata;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }else{
      this.isLoading = false;
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');

    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
