<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <div class="row">
            <div class="col-lg-9">
              <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord(searchName,'search')" placeholder="ค้นหาจากชื่อ หรือ เบอร์โทร ... <Enter>" v-on:keyup.enter="searchWord()">
              </base-input>
            </div>
            <div class="col-lg-3">
              <base-button type="primary" @click="openForm()">เพิ่มผู้ดูแล</base-button>
            </div>
          </div>
        </card>
      </div>
      <hr>
      <div class="table-responsive">
        <base-alert v-if="isAlert.open" :type="isAlert.type" v-on:click="isAlert.open == false">
          <strong> {{isAlert.tip}} </strong> {{isAlert.msg}}
          <button type="button" aria-label="Close" class="close" @click="isAlert.open = false"><span aria-hidden="true"><i class="tim-icons icon-simple-remove"></i></span></button>
        </base-alert>
        <base-table-mods :data="table.data" :columns="table.columns" @user-modals="findUserEvet" thead-classes="text-primary"> </base-table-mods>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>
  <!-- //////// --> 
  <modal :show.sync="modals.open" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
          <template>
            <strong v-if="modals.data.eve == 'info'">{{`ข้อมูลผู้ดูแลของ ${modals.data.item.name}`}}</strong>
            <strong v-if="modals.data.eve == 'add'">เพิ่มผู้ดูแลใหม่</strong>
            <strong v-if="modals.data.eve == 'newGroup'">จัดการกลุ่มผู้ดูแล</strong>
          </template>
          <template v-if="modals.data.eve == 'info' || modals.data.eve == 'add'">
            <form role="form" class="row">
              <div class="col-6">
                <base-input label="ชื่อ" type="text" v-model="modals.data.item.name" placeholder="Enter text"></base-input>
              </div>
              <div class="col-6">
                <base-input label="ยูเซอร์" v-if="modals.data.eve == 'add'" :class="{'has-success': hasUser == 'nohave','has-danger': hasUser == 'have','has': hasUser == '' }" v-on:change="searchWord(modals.data.item.user,'check')" type="text" v-model="modals.data.item.user" placeholder="Enter text"></base-input>
                <base-input label="ยูเซอร์" v-if="modals.data.eve == 'info'" type="text" v-model="modals.data.item.user" placeholder="Enter text"></base-input>
              </div>
              <div class="col-6">
                <base-input label="เบอร์โทร" type="text" v-model="modals.data.item.tel" placeholder="Enter text"></base-input>
              </div>
              <div class="col-6">
                <base-input label="รหัสผ่าน" type="text" v-model="modals.data.item.pass" placeholder="Enter text"></base-input>
              </div>
              <div class="col-12">
                <label for=""> <h4> <u>จัดกลุ่มผู้ดูแล</u> </h4> </label>
                <ul class="selectUl">
                  <li :class="{'active': rank == item.rank}" v-for="(item,index) in adminLevel" :key="index" @click="selectGroup(item)">{{item.title}}</li>
                  <li @click="modals.data.eve = 'newGroup';rank = ''">จัดการกลุ่ม+</li>
                </ul>
              </div>
              <hr>
              <div class="col-12">
                <div class="row">
                  <div class="col-6" v-for="item in permission">
                    <h4><u>{{item.label}}</u></h4>
                    <div class="row">
                      <div class="col-6" v-for="valueItem in item.data">
                        <h6 class="mt-2">{{valueItem.name}}</h6>
                        <ul class="selectUl">
                          <li v-bind:class="{'active': activeOption(permis.value) }" @click="selectOption(permis.value)"  v-for="permis in valueItem.permis">
                            {{permis.name}}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr/>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-if="modals.data.eve == 'newGroup'">
            <div class="row">
              <div class="col-12">
                <label for=""> <h4> <u>รายการ กลุ่ม</u> </h4> <span>กำเลือกถ้าต้องการแก้ไข</span> </label>
                <ul class="selectUl">
                  <li :class="{'active': rank == item.rank}" v-for="(item,index) in adminLevel" :key="index" @click="editGroup(item)">{{item.title}}</li>
                  <li @click="newGroup()">เพิ่มกลุ่ม+</li>
                </ul>
              </div>
              <hr>
              <div class="col-12">
                <label for=""> <h4> <u>กลุ่ม</u> </h4> </label>
                <base-input label="ชื่อกลุ่ม" type="text" v-model="levelForm.title" placeholder="Name Group"></base-input>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-6" v-for="item in permission">
                    <h4><u>{{item.label}}</u></h4>
                    <div class="row">
                      <div class="col-6" v-for="valueItem in item.data">
                        <h6 class="mt-2">{{valueItem.name}}</h6>
                        <ul class="selectUl">
                          <li v-bind:class="{'active': activeGroupOption(permis.value) }" @click="selectGroupOption(permis.value)"  v-for="permis in valueItem.permis">
                            {{permis.name}}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr/>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template slot="footer">
            <base-button type="secondary" @click="CloseModal()">Close</base-button>
            <base-button v-if="modals.data.eve == 'info'" type="primary" style="width: 225px;" @click="saveChanges()">Save changes</base-button>
            <base-button v-if="modals.data.eve == 'newGroup'" type="primary" style="width: 225px;" @click="saveGroup()">Save</base-button>
            <base-button v-if="modals.data.eve == 'add' && hasUser == 'nohave' && modals.data.item.name && modals.data.item.tel && modals.data.item.pass" type="primary" style="width: 225px;" @click="saveAdd()">Save</base-button>
          </template>
      </card>
  </modal>



</div>
</template>
<script>
import {
  BaseTableMods,
  Modal,
  BaseAlert
} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "name", "user", "tel", "status", "updated_at"];
const tableData = [];

export default {
  components: {
    BaseTableMods,
    SlidingPagination,
    Modal,
    BaseAlert,
    Loading
  },
  data() {
    return {
      table: {
        title: "รายชื่อผู้ดูแลระบบ",
        columns: [...tableColumns],
        data: [...tableData]
      },
      adminInfo: utils.localDB.jwt('token'),
      hasUser: '',
      modals: {
        open: false,
        title: '',
        data :{
          "eve": "",
          "item": {
            "_id": "",
            "name": "",
            "avata": "",
            "user": "",
            "pass": "",
            "tel": "",
            "permission": [],
            "status": true
          }
        },
        telMember: '',
        commit: ''
      },
      permission :[],
      isAlert: {open:false,tip:'',msg:'',type:'danger'},
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      adminLevel: [],
      rank:'',
      levelForm:{_id:'',title:'',permis:[]},
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        let tableReq = await axios(config.api('get', '/modlist/'+selectedPage));
        //console.log('tableReq', tableReq.data);
        this.table.data = tableReq.data.ResultData.skipdata;
        this.currentPage = selectedPage;
        this.isLoading = false;
      })();
    },
    //// find indexOf
    indexOFF(id){
      for (var i = 0; i < this.table.data.length; i++) {
        if(this.table.data[i]._id == id){
          return i;
        }
      }
      return -1;
    },
    CloseModal(){
      this.modals.open = false;
      this.newGroup();
    },
    ////
    saveChanges(){
      if(utils.permission('MODLIST_EDIT')){
        this.isLoading = true;
        if(this.modals.data.item.permission.indexOf('MASTER') > -1){
          if(utils.permission('MASTER')){
              axios(config.api('post', '/modlist/edit',this.modals.data.item)).then((response)=>{
                this.isLoading = false;
                this.modals.open = false;
              },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
              });
          }else{
            this.$alert('คุณไม่ได้รับอณุญาติให้แก้ไข ไอดีนี้','Permission','warning');
          }
        }else{
          axios(config.api('post', '/modlist/edit',this.modals.data.item)).then((response)=>{
            this.isLoading = false;
            this.modals.open = false;
          },(error)=>{
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
          });
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      }
    },
    saveAdd(){
      if(utils.permission('MODLIST_ADD')){
        this.isLoading = true;
        axios(config.api('post', '/modlist/add',this.modals.data.item)).then((response)=>{
          this.isLoading = false;
          this.modals.open = false;
          //this.searchWord(response.,'search');
        },(error)=>{
          this.isLoading = false;
          this.modals.open = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{

      }
    },
    findUserEvet(datas){
      //// Update Status
      if(datas.eve == 'block' || datas.eve == 'del'){
        this.$confirm(`แน่ใจ หรือไม่ ว่าจะทำรายการนี้ กับ ${datas.item.name}`).then(() => {
          this.isLoading = true;
          axios(config.api('post', '/modlist/edit',datas.item)).then((response)=>{
            this.isLoading = false;
            if(datas.eve == 'del'){
              let index = this.indexOFF(datas.item._id);
              this.table.data.splice(index, 1);
            }
          },(error)=>{
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
          });
        },(error)=>{
          datas.item.status = datas.before;
        });
      }else{
        this.modals.open = true;
        this.modals.data = datas;
        this.rank = datas.item.permission.join('-');

      }
      //console.log(datas);
    },
    activeOption(value){
      try {
        if(this.modals.data.item.permission.indexOf(value) > -1){
          return true;
        }else{
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    selectOption(value){
      try {
        if(this.modals.data.item.permission.indexOf(value) > -1){
          let index = this.modals.data.item.permission.indexOf(value);
          this.modals.data.item.permission.splice(index, 1);
        }else{
          this.modals.data.item.permission.push(value);
        }
      } catch (e) {
        this.modals.data.item.permission.push(value);
      }
    },
    activeGroupOption(value){
      try {
        if(this.levelForm.permis.indexOf(value) > -1){
          return true;
        }else{
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    selectGroupOption(value){
      try {
        if(this.levelForm.permis.indexOf(value) > -1){
          let index = this.levelForm.permis.indexOf(value);
          this.levelForm.permis.splice(index, 1);
        }else{
          this.levelForm.permis.push(value);
        }
      } catch (e) {
        this.levelForm.permis.push(value);
      }
    },
    selectGroup(items){
      this.modals.data.item.permission = items.permis;
      this.rank = items.rank;
    },
    openForm(){
      if(utils.permission('MODLIST_ADD')){
         this.modals.open = true;
         this.modals.data = {
           eve: 'add',
           item : {
             permission:[]
           }
         };
       }else{
         this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
         this.isLoading = false;
       }
    },
    searchWord(value,type){
      if(utils.permission('MODLIST_VIEW')){
        try {
          if(value.length <= 0){
            this.isLoading = true;
            (async () => {
              try {
                let tableReq = await axios(config.api('get', '/modlist/1'));
                if(type == 'search'){
                  this.totalPages = tableReq.data.ResultData.numpage.page;
                  this.table.data = tableReq.data.ResultData.skipdata;
                  this.isLoading = false;
                }else{
                  this.hasUser = '';
                }
                this.isLoading = false;
              } catch (error) {
                this.$alert(error.response.data.Message,'Error','error');
              }
            })();
          }else if(value.length > 3){
            (async () => {
              try {
                let tableReq = await axios(config.api('post', '/modlist/search',{kword:value}));
                if(type == 'search'){
                  this.totalPages = tableReq.data.ResultData.numpage.page;
                  this.table.data = tableReq.data.ResultData.skipdata;
                  this.isLoading = false;
                }else{
                  if(tableReq.data.ResultData.skipdata.length){
                    this.hasUser = 'have';
                  }else{
                    this.hasUser = 'nohave';
                  }
                }
                this.isLoading = false;
              } catch (error) {
                this.$alert(error.response.data.Message,'Error','error');
              }
            })();

          }
        } catch (e) {

        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    levelPoint(){
      axios(config.api('get', '/permis/groups')).then((response)=>{
        this.adminLevel = response.data.ResultData;
      },(e)=>{
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    newGroup(){
      this.levelForm ={_id:'',title:'',permis:[]};
      this.rank = '';
    },
    editGroup(item){
      this.levelForm = item;
      this.rank = item.rank;
    },
    saveGroup(){
      if(this.levelForm._id){ /// Edit
        axios(config.api('post', '/permis/groups/edit',this.levelForm)).then((response)=>{
          this.$alert('แก้ไขกลุ่มเรียบร้อย','Success','success').then(()=>{
            this.levelPoint();
            this.newGroup();
          });
        },(e)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{ /// New
        axios(config.api('post', '/permis/groups/add',this.levelForm)).then((response)=>{
          this.$alert('เพิ่มกลุ่มเรียบร้อย','Success','success').then(()=>{
            this.levelPoint();
            this.newGroup();
          });
        },(e)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      }
    }

  },
  mounted(){
    if(utils.permission('MODLIST_VIEW')){
    ///// GET List True
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/modlist/1'));
          //console.log('tableReq', tableReq.data);
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = tableReq.data.ResultData.skipdata;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();

     ///// permission
     let dataPerm = config.memnu;
     let listPermis = [];
     for (var i = 0; i < dataPerm.length; i++) {
       let dataArray = [];
       for (var s = 0; s < dataPerm[i].data.length; s++) {
        // console.log();
          if(dataPerm[i].data[s].enable == true){
            dataArray.push({name:dataPerm[i].data[s].name,permis: dataPerm[i].data[s].permission})
          };
       }
       listPermis.push({label:dataPerm[i].name, data: dataArray});
     }
     this.permission = listPermis;
   }else{
     this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
     this.isLoading = false;
     this.permission = [];
   }
   /////////////////
   this.levelPoint();
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
.selectUl{
  padding: 0px;
  margin: 5px 0px 40px 0px;
}
.selectUl li{
    display: inline-block;
    background: #e9eaed;
    background-size: 210% 210%;
    background-position: 100% 0;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    text-align: center;
    width: 38%;
    margin: 2px 6px;
    float: left;
    border-radius: 5px;
    cursor: pointer;
}
.selectUl li.active{
 background: #e14eca;
}
</style>
