<template>
    <div class="row" v-if="!isLoading">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="col-12">
            <card title="รายการข้อมูลสมาชิก">
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="htitle">ชื่อ</td>
                            <td>{{mem.firstName}}</td>
                            <td class="htitle">นามสกุล</td>
                            <td>{{mem.lastName}}</td>
                        </tr>
                        <tr>
                            <td class="htitle">เบอร์โทร</td>
                            <td>{{mem.tel}}</td>
                            <td class="htitle">รหัสผ่าน</td>
                            <td>{{mem.passwod}}</td>
                        </tr>
                        <tr>
                            <td class="htitle">ธนคาร</td>
                            <td>{{mem.typeBank}}</td>
                            <td class="htitle">เลขบัญชี</td>
                            <td>{{mem.numberBank}}</td>
                        </tr>
                        <tr>
                            <td class="htitle">ไอดีเกม</td>
                            <td>{{mem.gameId}}</td>
                            <td class="htitle">วันที่สมัคร</td>
                            <td>{{formatDate(mem.created_at)}}</td>
                        </tr>
                        <tr>
                            <td class="htitle">ประเป๋าสำรอง</td>
                            <td>{{mem.balance}}</td>
                            <td class="htitle">เครดิต</td>
                            <td>{{mem.credit}}</td>
                        </tr>
                        <tr>
                            <td class="htitle">ยอดฝาก</td>
                            <td>{{mem.deposit}}</td>
                            <td class="htitle">ยอดถอน</td>
                            <td>{{mem.withdraw}}</td>
                        </tr>
                        <tr>
                            <td colspan="2"  class="htitle">สรุปกำไร</td>
                            <td colspan="2">{{mem.withdraw-mem.deposit}} </td> 
                        </tr>
                    </tbody>
                </table>
                {{  }}
            </card>
        </div> 
        <div class="col-12 mt-3" >
            <card title="ไทม์ไลน์">
                <base-table-costomer-info :data="table.data" :columns="table.columns" :columnth="table.columnsTH" :sort="table.sort" @SortTable="SortTable" @OpenDetail="handleOpenDetail" :title="table.title" />
            </card>
        </div>
        <!-- popup -->

        <div class="modal fade" tabindex="-1" :class="{ show: cardDetail.status }" style="display: block;" aria-modal="true" role="dialog" v-if="cardDetail.status">
        <div class="modal-dialog modal-lg">
                <div class="modal-content popup-content">
                    <div class="modal-header">
                        <b-button variant="danger" class="close-btn" @click="closeCardDetail">x</b-button>
                    </div>
                    <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
                        <b-card class="custom-card border-dark p-3 my-4">
                            <hr />
                            <div class="row-content">
                                <div class="label">ยอดเสียทั้งหมด</div>
                                <div class="value">{{ loselogData.lose }}</div>
                                <div class="label">ยอดเสียทั้งหมด</div>
                                <div class="value">{{ loselogData.loseget }}</div>
                            </div>
                            <hr />
                                <div class="row-content">
                                <div class="label">วันที่/เวลา</div>
                                <div class="value">{{formatDate(loselogData.created_at)}}</div>
                            </div>
                            <hr />
                                <div class="row-content">
                                <div class="label">ฝาก</div>
                                <div class="value">{{ loselogData.totalDeposit }}</div>
                                <div class="label">ถอน</div>
                                <div class="value">{{ loselogData.totalWithdraw }}</div>
                            </div>
                        <hr />
                        <div>
                            <table class="table">
                            <thead class="table-dark">
                                <tr>
                                <th scope="col" style="color: black; text-align:center;">กระทำ</th>
                                <th scope="col" class="text-end" style="color: black; text-align:center;">จำนวน</th>
                                <th scope="col" style="color: black; text-align:center;">วันที่/เวลา</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in finance" :key="index">
                                <td style="color: black;">{{ item.type }}</td>
                                <td class="text-end" style="color: black;">{{ item.amount }}</td>
                                <td style="color: black;">{{ item.dateTime }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { BaseAlert,BaseTableCostomerInfo } from "@/components";
import axios from 'axios';
import config from '@/config.js';
import utils from '@/utils';

// Import component 
const tableColumns = [ "type", "event", "amount", "result",  "created_at" , "refer"];
const tableColumnsTH = ["ประเภท", "กระทำ", "จำนวน", "โอโต้",  "วันที่" , "อ้างอิง"];
const tableData = []; 
export default {
    data() {
        return { 
            loselog:{},
            mem:{},
            table: {
                sort:{
                    even:'desc',
                    col:'created_at'
                },
                title: "รายการ",
                columns: [...tableColumns],
                columnsTH: [...tableColumnsTH],
                data: [...tableData]
            },
            isLoading: true,
            fullPage: true,
            cardDetail:{
                status: false,
                loselogID:''
            },
            loselogData:[],
            finance:{}
        }
    },
    components: {
            Loading,
            BaseAlert,
            BaseTableCostomerInfo
        },
    methods: {
        loadData(){
            // console.log('ID_>',this.$route.params.id); 
            axios(config.api('get', `/costomer/info/${this.$route.params.id}`)).then((response)=>{
                this.isLoading = false; 
                this.mem = response.data.ResultData;
                this.table.data = response.data.ResultData.finance;
                this.loselog = response.data.ResultData.loselog;
                //console.log(this.table.data);
                // let withdata = utils.sortB({'created_at':response.data.ResultData.with});
                // let deposdata = utils.sortB({'created_at':response.data.ResultData.depos});
                // let useprodata = utils.sortB({'created_at':response.data.ResultData.usepro});
                // let frienddata = utils.sortB({'created_at':response.data.ResultData.friend});
                // let logeventdata = utils.sortB({'created_at':response.data.ResultData.logevent});
                // let playlogdata = utils.sortB({'created_at':response.data.ResultData.playlog});
                // let lossdata = utils.sortB({'created_at':response.data.ResultData.loss});
                // if(withdata.length){ this.with.data = withdata; } 
                // if(deposdata.length){ this.depos.data = deposdata; } 
                // if(useprodata.length){ this.usepro.data = useprodata; } 
                // if(frienddata.length){ this.friend.data = frienddata; }
                // if(logeventdata.length){ this.logevent.data = logeventdata; } 
                // if(playlogdata.length){ this.playlog.data = playlogdata; } 
                // if(lossdata.length){ this.loss.data = lossdata; } 
                // //console.log('this.playlog.data',utils.sortB({'created_at':response.data.ResultData.with}));
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });
        }, 
        SortTable(column){ 
            // console.log('column',column);
            
            let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
            let ObjK = {};
            ObjK[column] = this.table.data;
            this.table.sort.col = column;
            this.table.sort.even = sortType; 
            this.table.data = utils.Sort[sortType](ObjK) 

        },
        handleOpenDetail(detail) {
            // ดำเนินการเมื่อปุ่มถูกคลิก เช่น เปิด modal หรือแสดงผลข้อมูล
            console.log("Detail:", detail.refer.id);
            axios(config.api('get', `/costomer/loselog/${detail.refer.id}`)).then((response)=>{
                //console.log(response.data.ResultData.loseLog[0].created_at);
                this.loselogData.lose = response.data.ResultData.loseLog[0].lose;
                this.loselogData.loseget = response.data.ResultData.loseLog[0].loseget;
                this.loselogData.created_at = response.data.ResultData.loseLog[0].created_at;
                this.loselogData.totalDeposit = response.data.ResultData.loseLog[0].totalDeposit;
                this.loselogData.totalWithdraw = response.data.ResultData.loseLog[0].totalWithdraw;
                this.finance = response.data.ResultData.loseLog[0].finance;
                console.log(this.finance);
                
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });
            // this.loselogData = detail;
            console.log(this.cardDetail.status);
            this.openCardDetail();
        },
        openCardDetail(){
            this.cardDetail.status = true;
        },
        closeCardDetail(){
            this.cardDetail.status = false;
        },
        formatDate(text){
            return utils.forMatDateTimes(text)
        }
    },
    mounted(){
        this.loadData();
        if(window.location.href.split("?").length <= 1){
            window.location.href = '/?#/login'
        }
    }
}
</script>
<style media="screen" scoped> 
.htitle{
        background: #1e1e276e;
        border-left: solid 3px #3ca070;
        border-right: solid 1px #39393f;
}
table {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: #000;
    border: 1px solid #000;
}

thead {
  background-color: #4CAF50;
  color: white;
}

thead th {
  padding: 12px;
  text-align: left;
  font-size: 16px;
  background-color: #f9b5ff; /* พื้นหลังสีเทาของหัวตาราง */
  color: #fff; /* ตัวอักษรสีขาวสำหรับหัวตาราง */
}

tbody tr {
  border-bottom: 1px solid #dddddd;
  transition: background-color 0.3s ease;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

tbody td {
  padding: 10px;
  text-align: left;
}

tbody td:nth-child(1) {
  text-align: center;
}

tbody td:nth-child(2) {
  text-align: right;
}

tbody td:nth-child(3) {
  text-align: center;
  color: #000;
  font-size: 14px;
}
th, td {
    border: 1px solid #000; /* เส้นขอบระหว่างเซลล์ */
  padding: 8px;
  text-align: left;
  
}
th {
  background-color: #000;
}
.custom-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: black;
}

.row-content {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.label, .value {
  flex: 1;
  text-align: center;
}

hr {
  border: 0;
  border-top: 1px solid gray;
  margin: 10px 0;
}
.modal {
    display: none;
    padding-top: 100px;
}
.modal.show {
  display: block;
}
.modal.fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* สีพื้นหลังโปร่งแสง */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* ให้อยู่ด้านหน้าสุด */
}
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    font-size: 20px;
    border: none;
    cursor: pointer;
    z-index: 1051;
}
</style>
    