<template>
    <div class="row">
      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
      <div class="col-12">
        <card :title="table.title">
          <card>
            <div class="row">
              <div class="col-5">
                <div class="form-group">
                  <label class="control-label">ปี</label>
                  <select class="form-control" v-model="forms.y">
                    <option v-for="(item,index) in (10)" :value="(2021+item)">{{(2021+item)}}</option>
                  </select>
                </div>
              </div>
              <div class="col-5">
                <div class="form-group">
                  <label class="control-label">เดือน</label>
                  <select class="form-control" v-model="forms.m">
                    <option v-for="(item,index) in (12)" :value="item">{{item}}</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <base-button type="default" style="margin-top: 16px;"  @click="LoadData()">ค้นหา</base-button>
              </div>
            </div>
          </card>
          <div class="table-responsive">
            <record-table-bank :data="table.data" :columns="table.columns" @InfoLisEve="InfoLianEve" thead-classes="text-primary" />
          </div>
        </card>
      </div>
      <!-- /// modals /// -->
      <modal :show.sync="modals.open" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
          <card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
              <template>
                <strong>{{tableList.title}}</strong>
              </template>
              <template>
                <base-table :data="tableList.data" :columns="tableList.columns" />
              </template>
              <template>
                <base-table class="table table-striped " :data="tableList.dataSum" :columns="tableList.columnsSum" />
              </template>
          </card>
      </modal>
    </div>
</template>
<script>
import { RecordTableBank , Modal , BaseTable } from "@/components";
import utils from '@/utils';
import config from '@/config.js';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
const tableColumns = ["type","nameBank","number", "name"];
const tableColumnList = ["no","id","amount","created_at"];
const tableColumnListSum = ["no","detail","amount","created_at"];
const tableData = [];
const tableDataList = [];
export default {
  components: {
    RecordTableBank,
    BaseTable,
    Modal,
    Loading
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      formDate: new Date(),
      tableList:{
        title: "รายการ",
        columns: [...tableColumnList],
        data: [...tableDataList],
        columnsSum: [...tableColumnListSum],
        dataSum: [...tableDataList]
      },
      table: {
        title: "ยอดย้อนหลัง",
        sum:{
          members:0,
          membersTopup: 0,
          creditfree:0,
          Deposit:0,
          listGetMoney:0,
          Withdraw:0,
          Memloss:0,
          Loss:0,
          lucre:0
        },
        columns: [...tableColumns],
        data: [...tableData],
      },
      modals: {
        open: false,
        data :{},
      },
      forms: {
        y:'2022',
        m: new Date().getMonth()+1
      }
    };
  },
  methods: {
    LoadData(){
      this.isLoading = true;
      axios(config.api('post', '/report/RecordBank/',{"y": this.forms.y ,"m": this.forms.m})).then((response)=>{
        this.table.data = response.data.ResultData;
        this.isLoading = false;
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });

    },
    InfoLianEve(evt){
      let NewList = [];
      let Sumamount = 0;
      evt.list.forEach((item, i) => {
        Sumamount += item.amount;
        NewList.push({no:i+1,id:item._id,amount:item.amount,created_at:item.created_at});
      });
      this.tableList.title = evt.title;
      this.modals.open = true;
      this.tableList.data = NewList;
      this.tableList.dataSum = [{no: `1-${NewList.length}` ,detail:`รวมทั้งหมด ${NewList.length} รายการ เป็นจำนวนเงิน`,amount:Sumamount,created_at: Date()}];
    }
  },
  mounted(){
    this.LoadData();
  },

};
</script>
<style scoped> 
</style>
