<template>
     <div>  
         {{seclectTage}}
        <section class="mb-3" ref="listwithdraw">
          <h3>รายการถอนเงิน</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/transfer/listwithdraw/table.png" style="width: 100%;" alt="dashboard">
                         <p>รายการถอนที่สมาชิกรีเควสมาใหม่ล่าสุดจะอยู่บนสุด</p>
                    </div>
                    <div class="col-3">
                         <ul class="iconmenu">
                              <li>
                                   <button class="btn btn-success icon sm" @click="event = 'with'">
                                        <i class="tim-icons icon-coins"></i>
                                   </button> โอนเงินให้สมาชิก
                              </li>
                              <li>
                                   <button class="btn btn-primary icon sm" @click="event = 'reject'">
                                        <i class="tim-icons icon-simple-remove"></i>
                                   </button> ปฏิเสธและคืนเครดิต
                              </li>
                              <li>
                                   <button class="btn btn-default icon sm" @click="event = 'byadmin'">
                                        <i class="tim-icons icon-single-02"></i>
                                   </button> ผู้กระทำ
                              </li>
                              <li>
                                   <div class="form-control" style="width: 136px; overflow: hidden;">
                                        <i class="tim-icons icon-zoom-split"></i> ค้าหาชื่อหรือเบอร์
                                   </div> 
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="width: 95px; margin: 5px;" class="tale-status-wait"> รอ </span></li>
                                        <li>รอให้ผู้ดูแลกระทำ</li>
                                   </ul> 
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="width: 95px; margin: 5px;" class="tale-status-active"> เสร็จสิ้น </span></li>
                                        <li>เสร็จสิ้นโอนแล้ว</li>
                                   </ul> 
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="width: 95px; margin: 5px;" class="tale-status-reject"> ปฏิเสธ </span></li>
                                        <li>ถูกยกเลิก</li>
                                   </ul> 
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="width: 95px; margin: 5px;" class="tale-status-failed"> ล้มเหลว </span></li>
                                        <li>สถานะจาก Auto ล้มเหลว</li>
                                   </ul> 
                              </li>
                         </ul>
                    </div>
                    <div class="col-9">
                         <div v-show="event == 'with'">
                              <img src="/img/help/transfer/listwithdraw/withdraw.png" style="width: 100%;" alt="dashboard">
                              <p>หน้านี้จะแสดงรายละเอียดของสมาชิกรวมถึงรายการโปรโมชั่นที่สมาชิกเลือก (หน้านี้เป้นการเลือกแบบแมนนวน)</p>
                         </div>
                         <div v-show="event == 'reject'">
                              <img src="/img/help/transfer/listwithdraw/rejact.png" style="width: 100%;" alt="dashboard">
                              <p>กรอกรายละเอียดหรือเหตุผลที่บอกว่าเรายกเลิกเพราะอะไร</p>
                         </div>
                         <div v-show="event == 'byadmin'">
                              <img src="/img/help/transfer/listwithdraw/byadmin.png" style="width: 100%;" alt="dashboard">
                              <p>หน้านี้แสดงรายละเอียดที่รายงานบอกว่าแอดมินคนใหนกะทำอะไรลงไปเพราะอะไร</p>
                         </div>
                    </div>
               </div>
          </div>
        </section>
        <section class="mt-3" ref="listGetMoney">
          <h3>เพิ่มเงินให้ลูกค้า</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/transfer/listGetMoney/table.png" style="width: 100%;" alt="dashboard">
                         <p>รายการ</p>
                    </div>
                    <div class="col-3">
                         <ul class="iconmenu"> 
                              <li>
                                   <button class="btn btn-primary icon sm" @click="eventGetMoney = 'reGetMoneyject'">
                                        เพิ่มรายการโอน
                                   </button>  
                              </li> 
                         </ul>
                    </div>
                    <div class="col-9">
                         <div v-show="eventGetMoney == 'reGetMoneyject'">
                              <img src="/img/help/transfer/listGetMoney/add.png" style="width: 100%;" alt="dashboard">
                              <p>หน้านี้จะแสดงรายละเอียดของสมาชิกรวมถึงรายการโปรโมชั่นที่สมาชิกเลือก (หน้านี้เป้นการเลือกแบบแมนนวน)</p>
                         </div>
                          
                    </div>
               </div>
          </div>
        </section>
        <section class="mt-3 mb-3" ref="listTransferFail">
          <h3>รายงานฝากไม่สำเร็จ</h3>
          <div class="body-htlp-tag">-</div>
        </section>  
        <section class="mt-3 mb-3" ref="listTransferSuccess">
          <h3>รายงานฝากสำเร็จ</h3>
          <div class="body-htlp-tag">-</div>
        </section>  
        <section class="mt-3 mb-3" ref="friendspoint">
          <h3>ชวนเพื่อน</h3>
          <div class="body-htlp-tag">-</div>
        </section>  
     </div>
</template>
<script>
export default { 
     props: {
          tag:{
               type:String,
               default:'dashboard'
          }
     }, 
     data() {
          return {
               event: 'with',
               eventGetMoney:'reGetMoneyject'
          }
     },
     computed:{
          seclectTage(){ 
               try {
                    let ele = this.$refs[this.tag]; 
                    if(ele.offsetTop > 0){ 
                         ele.scrollIntoView({behavior: 'smooth'})
                         // window.scrollTo(ele.offsetTop);
                    } 
               } catch (error) {}
          }
     },
     mounted() {
          
     },
}
</script>
<style scoped>
     .body-htlp-tag{
          margin-bottom: 50px;
          border-bottom: dotted 2px rgb(59, 58, 58);
          padding-bottom: 50px !important;
     }
     .btn-action {
          background: #c8c8c8;
     }
     .iconmenu,.iconmenu-sub{
          margin: 0px;
          padding: 0px;
     }
     .iconmenu > li {
          display: block;
     }
     .iconmenu-sub > li{
          display: inline-block;
     }
</style>