<template>
  <div class="col-12" >
    <div class="row"  v-if="!ifLoading">
      <div class="col-12 mt-2">
        <h4>รายละเอียด</h4>
      </div>
      <div class="col-6">
        <base-input label="ผู้ทำร้องขอ"  :text="newData.admin.name" />
      </div>
      <div class="col-6">
        <base-input label="ไอดีเกม"  :text="newData.gameId" />
      </div>
      <div class="col-4 mt-2">
        <base-input label="จำนวนถอน"  :text="newData.withdraw" />
      </div>
      <div class="col-4 mt-2">
        <base-input label="จำนวนเครดิต"  :text="newData.credit" />
      </div>
      <div class="col-4 mt-2">
        <base-input label="จำกัดการถอน"  :text="newData.withMax" />
      </div>

      <div class="col-12 mt-3">
        <hr>
        <h4>รายละโปรโมชั่น</h4>
        <div class="row" v-if="!Typeof(newData.promotion)">
          <div class="col-12">
            <base-input label="ชื่อโปรโมชั่น"  :text="newData.typePromotion" />
          </div>
        </div>
        <div class="row" v-if="Typeof(newData.promotion)">
          <div class="col-12">
            <base-input label="ชื่อโปรโมชั่น"  :text="newData.promotion.title" />
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <base-input label="รับโบนัสสูงสุด"  :text="newData.promotion.getbonutLinit" />
              </div>
              <div class="col-6">
                <base-input label="กำหนดทำยอด"  :text="newData.promotion.withdrawLimit" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-12 mt-3">
        <hr>
        <h4>โบนัสหลังใช้โปร</h4>
        <div class="row">
          <div class="col-6">
            <base-input label="เงินก่อนรับโบนัส"  :text="newData.usePromotion.amount" />
          </div>
          <div class="col-6">
            <base-input label="เงินหลังรับโบนัส"  :text="newData.usePromotion.useProPoint" />
          </div>
        </div>
      </div>

      <div class="col-12 mt-5">
        <hr>
        <base-button type="default" v-if="!logPlay.open" style="width: 100%;" class="mt-2"  @click="logPlayGames()">ดูข้อมูลการเล่นเกม</base-button>
      </div>
      <div class="col-12 mt-5 mb-5" v-if="logPlay.open">
        <base-table-lo-game :domain="doimages" :withdraw="{w:newData.withdraw,t:newData.usePromotion.useProPoint}"  :data="logPlay.dataLog" />
      </div>
      <div class="col-12 mb-5" v-if="logPlay.open">
        <base-button type="default" style="width: 100%;" class="mt-2"  @click="logPlayGames()">ย่อหน้าลง</base-button>
        <hr>
      </div>

      <div class="col-12 mt-4">
        <h4>คำอธิบายในการแก้ไข</h4>
        <text-area v-model="commit" disabled />
      </div>
      <div class="col-12 mt-4 mb-5">
         <div class="row">
           <div class="col-6 text-center">
             <base-button type="success" style="width: 100%;" class="mt-2"  @click="summit('active')">อนุมัติ</base-button>
           </div>
           <div class="col-6 text-center">
             <base-button type="danger" style="width: 100%;" class="mt-2"  @click="summit('reject')">ไม่อนุมัติ</base-button>
           </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
import { TextArea , BaseTableLoGame} from "@/components";
export default {
  name: 'getmoney',
  components:{
    TextArea,
    BaseTableLoGame
  },
  props: {
    data: {
      type: Object,
      default: {},
      description: " "
    }
  },
  data() {
    return {
      logPlay:{
        open:false,
        dataLog:[]
      },
      doimages: `https://images.${utils.getDomain()}`,
      ifLoading:false,
      oldData:this.data.post,
      newData: {
        admin:{name:''},
        gameId:'',
        withdraw :0,
        credit :0,
        withMax:0,
        promotion:'-',
        usePromotion: {
          amount: 0,
          useProPoint: 0,
        }
      },
      commit:this.data.commit,
      zoomIn:false,
    };
  },
  computed: {

  },
  methods: {
    isLoading(evt){
      this.ifLoading = evt; 
    },
    isUndf(value){
      try {
        return value;
      } catch (e) {
        return '';
      }
    },
    Typeof(evt){
      return (typeof evt == 'object');
    },
    LoadData(){
      this.isLoading(true);
      axios(config.api('get', `/confirm/find/${this.data.isPage}/${this.data.post._id}`)).then((response)=>{
        this.newData = response.data.ResultData;
        this.isLoading(false);
      },(error)=>{
        this.isLoading(false);
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    SendData(Objdata,callback){
      this.isLoading(true);
      axios(config.api('post', `/confirm/SendData`,Objdata)).then((response)=>{
        this.isLoading(false);
        callback(null,response.data.ResultData);
      },(error)=>{
        this.isLoading(false);
        callback(error.response.data.Message,null);
        // this.$alert(error.response.data.Message,'Error','error');
      });
    },
    SendMethod(Objdata,callback){
      this.isLoading(true);
      axios(config.api('post', this.data.method , Objdata)).then((response)=>{
        this.isLoading(false);
        callback(null,response.data.ResultData);
      },(error)=>{
        this.isLoading(false);
        callback(error.response.data.Message, null);
        // this.$alert(error.response.data.Message,'Error','error');
      });
    },
    summit(eve){
      if(eve == 'reject'){
        this.$prompt("ขอเหตุผลที่ไม่อนุมัติ").then((commit) => {
          this.SendData({
            _id: this.data._id,
            ac:'reject',
            commit: commit,
          },(err,resp)=>{
            if(err == null){
              this.data.post.eve = 'rejact';
              this.data.post.commit = commit
              this.SendMethod(this.data.post,(err,resp)=>{
                if(err == null){
                  this.$alert('บันทึกข้อมูลเรียบร้อย','Success','success').then(()=>{
                    this.$emit('backPage', {action:{title:'table'}});
                  });
                }else{
                  this.$alert(err,'Error','error').then(()=>{
                    this.SendData({ _id: this.data._id, ac:'wait', commit: commit});
                  });
                }
              });
            }else{
              this.$alert(err,'Error','error');
              this.$emit('backPage', {action:{title:'table'}});
            }
          });
        });
      }else{
        this.$confirm("แน่ใจแล้วไช่มั้ย?","แน่ใจ","question").then(()=>{
          this.SendData({
            _id: this.data._id,
            ac:'active',
            commit: 'อนุมัติ',
          },(err,resp)=>{
            if(err == null){
              this.SendMethod(this.data.post,(err,respev)=>{
                if(err == null){
                  this.$alert('อนุมัติเรียบร้อย','Success','success').then(()=>{
                    this.$emit('backPage', {action:{title:'table'}});
                  });
                }else{
                  this.$alert(err,'Error','error');
                  this.$emit('backPage', {action:{title:'table'}});
                }
              });
            }else{
              this.$alert(err,'Error','error');
            }
          });
        })
      }
    },
    LoadLogGames(){
      let DataJson = {
        gameId: this.newData.gameId,
        usePro: this.newData.usePromotion._id,
      }
      this.isLoading(true);
      axios(config.api('post', `/confirm/loadLogGames`,DataJson)).then((response)=>{
        this.isLoading(false);
        this.logPlay.dataLog = response.data.ResultData;
      },(error)=>{
        this.isLoading(false);
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    logPlayGames(){
      if(this.logPlay.dataLog.length){
        this.logPlay.open = (this.logPlay.open)?false:true;
      }else{
        this.LoadLogGames();
        this.logPlay.open = (this.logPlay.open)?false:true;
      }
    }
  },
  mounted(){
    this.LoadData();
    console.log('Withdraw data',this.data.method);
  }
};
</script>
<style>
</style>
