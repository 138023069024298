<template>
  <div class="row">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <div class="col-12">
      <card :title="table.title" v-if="dataItem.isPage == 'table'">
        <div class="row">
          <div class="col-4 offset-8">
            <base-button type="secondary" class="pull-right ml-3" @click="CreateLink()"> สร้างลิ้งค์ </base-button>
            <base-button type="info" class="pull-right ml-3" @click="Setting()">ตั้งค่า Google Key </base-button>
          </div>
        </div>
        <div class="table-responsive">
          <table-list-af :data="table.data" :columns="table.columns" :columnth="table.columnsTH" @VieStage="VieStage"
            thead-classes="text-primary" />
        </div>
      </card>
    </div>
    <!-- ///// Model -->
    <modal :show.sync="modals.open" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
      <div class="card mb-0">
        <div class="card-body" v-if="modals.typeform == 'CreateLink'">
          <h3 class="card-title mb-0">เพิ่มรายการ AF</h3>
          <p class="card-text m-0"> เพิ่มช่องทางการเข้าถึงและเก็บข้อมูลยอดการเข้าถึง</p>
          <div class="mt-2 mb-2">
            <div class="row">
              <div class="col-12">
                <base-input label="คีย์ หรือ หัวข้อ " type="taxt" v-model="modals.form.title"></base-input>
              </div>
              <div class="col-12">
                <base-input label="Image Url" type="taxt" v-model="modals.form.image"></base-input>
              </div>
              <div class="col-12">
                <base-input label="ลิ้งปลายทาง" type="taxt" help="https://domain.com"
                  v-model="modals.form.url"></base-input>
              </div>
              <div class="col-12">
                <text-area label="รายละเอียด" style="" v-model="modals.form.description" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="modals.typeform == 'Setting'">
          <h3 class="card-title mb-0">ตั้งค่า</h3>
          <p class="card-text m-0">ตั้งค่าของ Google Tag and Analytics</p>
          <div class="mt-2 mb-2">
            <div class="row"> 
              <div class="col-12">
                <base-input label="หัวข้อ " type="taxt" v-model="modals.form.google.analytics.name"></base-input>
              </div> 
              <div class="col-12">
                <base-input label="เว็บไซต์ " type="taxt" v-model="modals.form.google.analytics.url"></base-input>
              </div>
              <div class="col-12">
                <base-input label="Key Code Analytics" type="taxt" v-model="modals.form.google.analytics.measurementid"></base-input>
              </div>
              <div class="col-12">
                <base-input label="Key Code Tag" type="taxt" v-model="modals.form.google.tag.measurementid"></base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6">
              <base-button type="success" style="width: 100%;" @click="saveData()">
                บันทึก
              </base-button>
            </div>
            <div class="col-6">
              <base-button type="danger" style="width: 100%;" @click="modals.open = false">
                ยกเลิก
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { TableListAf, Modal, TextArea } from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
const tableColumns = ["social", "short", "enable", "detail", "regis", "click", "created_at", "eve"];
const tableColumnsTH = ["คีย์", "ลิ้งค์", "สถานะ", "รายละเอียด", "ผู้สมัคร", "ผู้สนใจ", "วันที่", ""];
const tableData = [];
//
export default {
  components: {
    TableListAf,
    TextArea,
    Loading,
    Modal
  },
  data() {
    return {
      table: {
        title: "รายการลิ้งค์ AF",
        columns: [...tableColumns],
        columnsTH: [...tableColumnsTH],
        data: [...tableData]
      },
      setting: {},
      modals: {
        open: false,
        typeform: '',
        form: {}
      },
      dataItem: { isPage: 'table' },
      isLoading: true,
      fullPage: true,
    };
  },
  methods: {
    LoadData() {
      this.isLoading = true;
      axios(config.api('get', '/short/list')).then((response) => {
        this.table.data = response.data.ResultData.list;
        this.setting = response.data.ResultData.user;
        this.isLoading = false;
        // console.log('ResultData',response.data.ResultData);
      }, (error) => {
        this.isLoading = false;
        this.$alert(error.response.data.Message, 'Error', 'error');
      });
    },
    CreateLink() {
      this.modals.open = true;
      this.modals.typeform = 'CreateLink';
      this.modals.form = {
        image: '',
        url: '',
        title: '',
        description: '',
        typelink: ''
      }
    },
    Setting() {
      this.modals.open = true;
      this.modals.typeform = 'Setting';
      this.modals.form = this.setting
    },
    saveData() {
      this.isLoading = true;
        axios(config.api('post', '/short/add',{typelink:this.modals.typeform,form:this.modals.form})).then((response)=>{
          this.isLoading = false;
          this.LoadData();
          this.modals.open = false;
          this.$alert('บันทึกเรียบร้อย','ข้อความ','success');
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
    },
    VieStage(evet){
      console.log('evetdddd',evet);  
      this.$router.push(`/AFLink/view/${evet.social}`);
    }
  },
  mounted() {
    this.LoadData();
  }
};
</script>
<style></style>
