<template>
    <div class="row">
      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
      <div class="col-12">
        <card :title="table.title">
          <card>
            <div class="row">
              <div class="col-5">
                <div class="form-group">
                  <label class="control-label">ปี</label>
                  <select class="form-control" v-model="forms.y">
                    <option v-for="(item,index) in (10)" :value="(2021+item)">{{(2021+item)}}</option>
                  </select>
                </div>
              </div>
              <div class="col-5">
                <div class="form-group">
                  <label class="control-label">เดือน</label>
                  <select class="form-control" v-model="forms.m">
                    <option v-for="(item,index) in (12)" :value="item">{{item}}</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <base-button type="default" style="margin-top: 16px;"  @click="LoadData()">ค้นหา</base-button>
              </div>
            </div>
          </card>
          <div class="table-responsive">
            <record-table :data="table.data" :columns="table.columns" :sum="table.sum" thead-classes="text-primary" />
          </div>
        </card>
      </div>

    </div>
</template>
<script>
import { RecordTable } from "@/components";
import utils from '@/utils';
import config from '@/config.js';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
const tableColumns = ["dates", "members", "membersTopup", "creditfree",  "Deposit", "listGetMoney", "Withdraw", "Memloss", "Loss", "lucre"];
const tableData = [];

export default {
  components: {
    RecordTable,
    Loading
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      formDate: new Date(),
      table: {
        title: "ยอดย้อนหลัง",
        sum:{
          members:0,
          membersTopup: 0,
          creditfree:0,
          Deposit:0,
          listGetMoney:0,
          Withdraw:0,
          Memloss:0,
          Loss:0,
          lucre:0
        },
        columns: [...tableColumns],
        data: [...tableData]
      },
      forms: {
        y:'2022',
        m: new Date().getMonth()+1
      }
    };
  },
  methods: {
    LoadData(){
      this.isLoading = true;
      axios(config.api('post', '/report/RecordList/',{"y": this.forms.y ,"m": this.forms.m})).then((response)=>{
        response.data.ResultData.forEach((item, i) => {
          this.table.sum.members +=  item.members;
          this.table.sum.membersTopup +=  item.membersTopup;
          this.table.sum.creditfree +=  item.creditfree;
          this.table.sum.Deposit +=  item.Deposit;
          this.table.sum.listGetMoney +=  item.listGetMoney;
          this.table.sum.Withdraw +=  item.Withdraw;
          this.table.sum.Memloss +=  item.Memloss;
          this.table.sum.Loss +=  item.Loss;
          this.table.sum.lucre +=  item.lucre;
        });
        this.table.data = response.data.ResultData;
        this.isLoading = false;
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });

    },
  },
  mounted(){
    this.LoadData();
  },

};
</script>
<style>
</style>
