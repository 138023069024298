<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <!-- <div class="mb-3">
        <card>
          <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากยูเซอร์ของผู้เล่น ... <Enter>" v-on:keyup.enter="searchWord()">
          </base-input>
        </card>
      </div> -->
      <div class="table-responsive">
        <base-table-games-op :data="table.data" :columns="table.columns" @withd-modals="prodsEvet"  thead-classes="text-primary"> </base-table-games-op>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>
  <!-- //////// -->

  <modal :show.sync="modals.open"  body-classes="p-0"  modal-classes="modal-dialog-centered">
     <card type="secondary" header-classes="bg-white pb-5" body-classes=" px-lg-5 py-lg-5" class="border-0 mb-0" v-if="modals.eve == 'info'">
       <template>
         <strong>แก้ไขรายการ</strong>
       </template>
       <template>
         <form role="form" class="row">
           <div class="col-12">
             <div class="row">
               <div class="col-12">
                 <ul class="tagInput">
                   <li><base-input label="เวลา" type="text" v-model="frorm.time"  placeholder="Enter text"></base-input></li>
                   <li><base-button type="info" title="ดูข้อมูล"  icon size="sm" @click="ReteTime('add',frorm.time)">  <small>เพิ่ม</small>  </base-button></li>
                 </ul>
               </div>
               <div class="col-12">
                 <ul class="tagItem">
                   <li v-for="(column, index) in modals.data.times" @click="ReteTime('del',{'column':column,'index':index})">
                     <p style="color: #ffffff !important;">{{ column }} วิ</p>
                   </li>
                 </ul>
               </div>
             </div>
           </div>
           <div class="inline"></div>
           <div class="col-12">
             <div class="row">
               <div class="col-4">
                 <ul class="tagInput">
                   <li><base-input label="ราคาต่ำ" type="text" v-model="frorm.rete.feas" placeholder="Enter text"></base-input></li>
                 </ul>
               </div>
               <div class="col-4">
                 <ul class="tagInput">
                   <li><base-input label="ราคากลาง" type="text" v-model="frorm.rete.cent" placeholder="Enter text"></base-input></li>
                 </ul>
               </div>
               <div class="col-4">
                 <ul class="tagInput">
                   <li><base-input label="ราคาสูง" type="text" v-model="frorm.rete.last" placeholder="Enter text"></base-input></li>
                   <li><base-button type="info" title="ดูข้อมูล"  icon size="sm" @click="ReteMonney('add',frorm.rete)"> <small>เพิ่ม</small> </base-button></li>
                 </ul>
               </div>
               <div class="col-12">
                 <ul class="tagItem">
                   <li v-for="(column, index) in modals.data.rete" @click="ReteMonney('del',{'column':column,'index':index})">
                     <p style="color: #ffffff !important;">{{ column.join('-') }} ฿</p>
                   </li>
                 </ul>
               </div>
             </div>
           </div>
         </form>
       </template>
       <template slot="footer">
         <base-button type="secondary" class="ml-auto" @click="modals.open = false">Close  </base-button>
         <base-button type="primary" style="width: 225px;" @click="saveChanges()">Save changes</base-button>
       </template>
     </card>
  </modal>

  <!-- //////// -->
</div>
</template>
<script>
import { BaseTableGamesOp , Modal} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "icon", "game", "times", "rete" , "player", "gameplay"];
const tableData = [];

export default {
  components: {
    BaseTableGamesOp,
    SlidingPagination,
    Modal,
    Loading
  },
  data() {
    return {
      table: {
        title: "ออฟชั่นเกม",
        columns: [...tableColumns],
        data: [...tableData]
      },
      modals: {
        open: false,
        eve: '',
        data :{},
      },
      frorm:{
        time:0,
        rete:{
          feas:0,
          cent:0,
          last:0
        }
      },
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', `/gamesOp/games/`));
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    prodsEvet(item){
       if(utils.permission('GAMES_OPTION_VIEW')){
          if(item.eve == 'activeInfo'){
           this.$fire({
            type: 'info',
            title: 'ทำรายการ',
            text: item.item.commit,
            footer: `โดย : ${item.item.admin}`
          });
         }else{
           this.modals.open = true;
           this.modals.eve = item.eve;
           this.modals.data = item.item;
         }
       }else{
         this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
       }
    },
    ReteTime(type,value){
      if(type == 'add'){
        let valun = parseInt(value);
        if(valun){
          this.modals.data.times.push(valun)
          this.frorm.time = 0;
        }
      }else if(type == 'del'){
        this.$confirm(`แน่ใจหรือไม่ว่าจะลบเวลา ${value.column} วินาที`,'Question','question').then(()=>{
          this.modals.data.times.splice(value.index,1);
        });
      }
    },
    ReteMonney(type,value){
      if(type == 'add'){
        let feas = parseInt(value.feas);
        let cent = parseInt(value.cent);
        let last = parseInt(value.last);
        if(feas && cent && last){
          let RetePri = [];
          RetePri.push(feas);
          RetePri.push(cent);
          RetePri.push(last);
          this.modals.data.rete.push(RetePri);
          this.frorm.rete.feas = 0;
          this.frorm.rete.cent = 0;
          this.frorm.rete.last = 0;
        }
      }else if(type == 'del'){
        this.$confirm(`แน่ใจหรือไม่ว่าจะลบเวลา ${value.column} ฿`,'Question','question').then(()=>{
          this.modals.data.rete.splice(value.index,1);
        });
      }
    },
    saveChanges(){
      if(utils.permission('GAMES_OPTION_EDIT')){
        if (this.modals.data.rete.length && this.modals.data.times.length) {
          let JsonModel = {
              "gameid": this.modals.data.id,
              "option":{
                  "times": this.modals.data.times,
                  "rete": this.modals.data.rete,
                  "player": this.modals.data.player,
                  "gameplay": this.modals.data.gameplay
              }
          }
          axios(config.api('post', '/gamesOp/option',JsonModel)).then((response)=>{
            this.isLoading = false;
            this.$alert(response.data.Message,'Success','success');
            //console.log('response',response.data);
          },(error)=>{
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
           // console.log('error',error.response.data);
          });
        }
      }
    },
  },
  mounted(){
    ///// GET List True
    if(utils.permission('GAMES_OPTION_VIEW')){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/gamesOp/games/'));
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = tableReq.data.ResultData.skipdata;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }else{
      this.isLoading = false;
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
.tagItem , .tagInput{
  margin: 0px;
  padding: 0px;
}
.tagInput > li {
  display: inline-block;
}
.tagItem > li {
  display: inline-block;
  margin: 5px;
  padding: 5px 20px;
  background: #e14eca;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
</style>
