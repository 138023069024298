<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <div class="row">
            <div class="col-10">
              <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากไอดี ... <Enter>" v-on:keyup.enter="searchWord()">
              </base-input>
            </div>
            <div class="col-2">
              <base-button type="secondary" class="pull-right" @click="openForm()"  > ตั้งค่าการดรอป </base-button>
            </div>
          </div>
        </card>
      </div>
      <div class="table-responsive">
        <base-table-spin :data="table.data" :columns="table.columns" :sort="table.sort" @SortTable="SortTable" thead-classes="text-primary"> </base-table-spin>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>
  ////////
  <modal :show.sync="modals" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
    <card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
      <template>
        <form role="form" class="row">
          <div class="col-12 mb-3 box-tpic text-center"> 
            <h2 class="m-0">แนะนำ</h2>
            <p class="m-0" style="font-size: 16px !important;">ความยากขึ้นอยู่ที่เปอร์เซน</p>
          </div>
          <div class="col-6 boxflea" v-for="(item, index) in formControl" :key="index">  
            <table class="table">
             <tbody> 
              <tr>
                <td class="lable">ประเภท</td>
                <td>{{item.type}}</td> 
              </tr>
              <tr>
                <td class="lable">แต้ม</td>
                <td>{{item.point}}</td> 
              </tr>
              <tr>
                <td class="lable">โอกาสดรอป x%</td>
                <td><base-input type="number" v-model="item.chance" placeholder="โอกาสดรอป"></base-input></td> 
              </tr> 
             </tbody>
            </table> 
          </div>
        </form>
      </template>
      <template slot="footer">
        <div class="row">
          <div class="col-6">
            <base-button type="secondary" class="w-100" @click="openForm()">Close</base-button> 
          </div>
          <div class="col-6">
            <base-button type="primary" class="w-100"  @click="saveChanges()">Save changes</base-button>
          </div>
        </div>
      </template>
    </card>
  </modal>
</div>
</template>
<script>
import { BaseTableSpin , Modal} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "#", "gameId", "freespinBefore", "freespinAfter" , "balanceBefore", "balanceAfter","type","score","created_at"];
const tableData = [];

export default {
  components: {
    BaseTableSpin,
    SlidingPagination,
    Modal,
    Loading
  },
  data() {
    return {
      table: {
        sort:{
          even:'desc',
          col:'created_at'
        },
        title: "รายการผู้เล่น วงล้อหรรษาพาโชค",
        columns: [...tableColumns],
        data: [...tableData]
      },
      modals:false,
      formControl:[],
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: { 
    LoadData(){
      if(utils.permission('FREESPIN_VIEW')){
        (async () => {
          try {
            let tableReq = await axios(config.api('get', '/minigame/wheel/page/1'));
            let listControl = await axios(config.api('get', '/minigame/wheel/control'));
            this.formControl = listControl.data.ResultData; 
            this.totalPages = tableReq.data.ResultData.numpage.page;
            this.table.data = tableReq.data.ResultData.skipdata;
            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
          }
        })();
      }else{
        this.isLoading = false;
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      }
    },
    SortTable(column){ 
      let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
      let ObjK = {};
      ObjK[column] = this.table.data;
      this.table.sort.col = column;
      this.table.sort.even = sortType; 
      this.table.data = utils.Sort[sortType](ObjK) 
    },
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/minigame/wheel/page/'+selectedPage));
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    searchWord(){
      if(utils.permission('FREESPIN_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/minigame/wheel/page/1'));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/minigame/wheel/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    }, 
    openForm(){
      this.modals = (this.modals)?false:true;
    }, 
    saveChanges(){
      this.isLoading = true;
      axios(config.api('post', '/minigame/wheel/control',{data:this.formControl})).then((response)=>{
        this.isLoading = false;
        this.$alert('แก้ไขสำเร็จ','ข้อความ','success');
        this.openForm();
        this.LoadData();
      },(err)=>{
        this.isLoading = false;
        this.$alert(err.response.data.Message,'Error','error');
      });
    }
  },
  mounted(){ 
    this.LoadData(); 
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
.boxflea{ 
  padding: 5px;
}
.boxflea > table{
  border: solid 1px rgb(157, 157, 157); 
}
.boxflea > table .lable{
  background-color: #e3f7e7; 
  font-weight: 900;
}
</style>
