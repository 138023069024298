<template>
    <div class="col-12">
      <div class="row">
        <div class="col-6" style="border-right: solid 2px #1e1e28;">
          <h3>ยังไม่แก้ไข</h3>
          <hr>
          <div class="row">
            <div class="col-12 mt-2">
              <base-input label="ชื่อ"  :text="newData.firstName"  placeholder="firstName" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="นามสกุล"  :text="newData.lastName"  placeholder="lastName" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="กระเป๋าสำรอง"  :text="`${newData.balance}`"  placeholder="balance" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="กระเป๋าหลัก"  :text="`${newData.credit}`"  placeholder="credit" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="เลขบันชีธนคาร"  :text="newData.numberBank"  placeholder="numberBank" />
            </div>
            <div class="col-6 mt-2">
              <img :src="iconBank(newData.typeBank)" :alt="newData.typeBank" style="width: 75px;">
            </div>
            <div class="col-6 mt-2">
              <base-input label="รหัสผ่าน"  :text="newData.passwod"  placeholder="passwod" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="เบอร์โทร"  :text="newData.tel"  placeholder="tel" />
            </div>

          </div>
        </div>
        <div class="col-6">
          <h3>สิ่งที่แก้ไข</h3>
          <hr>
          <div class="row">
            <div class="col-6 mt-2">
              <base-input label="ชื่อ" :class="{'active': fidHas(oldData.firstName,newData.firstName)}" :text="oldData.firstName"  placeholder="firstName" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="นามสกุล" :class="{'active': fidHas(oldData.lastName,newData.lastName)}" :text="oldData.lastName"  placeholder="lastName" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="กระเป๋าสำรอง" :class="{'active': fidHas(oldData.balance,newData.balance)}" :text="`${oldData.balance}`"  placeholder="balance" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="กระเป๋าหลัก" :class="{'active': fidHas(oldData.credit,newData.credit)}" :text="`${oldData.credit}`"  placeholder="credit" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="เลขบันชีธนคาร" :class="{'active': fidHas(oldData.numberBank,newData.numberBank)}" :text="oldData.numberBank"  placeholder="numberBank" />
            </div>
            <div class="col-6 mt-2">
              <img :class="{'active': fidHas(oldData.typeBank,newData.typeBank)}" :src="iconBank(oldData.typeBank)" :alt="oldData.typeBank" style="width: 75px;">
            </div>
            <div class="col-6 mt-2">
              <base-input label="รหัสผ่าน" :class="{'active': fidHas(oldData.passwod,newData.passwod)}" :text="oldData.passwod"  placeholder="passwod" />
            </div>
            <div class="col-6 mt-2">
              <base-input label="เบอร์โทร" :class="{'active': fidHas(oldData.tel,newData.tel)}" :text="oldData.tel"  placeholder="tel" />
            </div>

          </div>
        </div>
        <div class="col-12 mt-4">
          <h3>คำอธิบายในการแก้ไข</h3>
          <text-area v-model="commit" disabled />
        </div>
        <div class="col-12 mt-4 mb-5">
           <div class="row">
             <div class="col-6 text-center">
               <base-button type="success" style="width: 100%;" class="mt-2"  @click="summit('active')">อนุมัติ</base-button>
             </div>
             <div class="col-6 text-center">
               <base-button type="danger" style="width: 100%;" class="mt-2"  @click="summit('reject')">ไม่อนุมัติ</base-button>
             </div>
           </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
import { TextArea } from "@/components";
export default {
  name: 'costomer',
  components:{
    TextArea
  },
  props: {
    // loading:Boolean,
    data: {
      type: Object,
      default: {},
      description: " "
    }
  },
  data() {
    return {
      newData: {},
      oldData: this.data.post.data,
      commit: this.data.post.commit
      // fullPage: true,
    };
  },
  computed: {

  },
  methods: {
    isLoading(evt){
      this.$emit('loading', evt);
    },
    fidHas(oldDB,newDB){
      return (oldDB != newDB);
    },
    LoadData(){
      this.isLoading(true);
      axios(config.api('get', `/confirm/find/${this.data.isPage}/${this.data.post._id}`)).then((response)=>{
        this.isLoading(false);
        this.newData = response.data.ResultData;
      },(error)=>{
        this.isLoading(false);
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    SendData(Objdata,callback){
      this.isLoading(true);
      axios(config.api('post', `/confirm/SendData`,Objdata)).then((response)=>{
        this.isLoading(false);
        callback(null,response.data.ResultData);
      },(error)=>{
        this.isLoading(false);
        callback(error.response.data.Message,null);
        // this.$alert(error.response.data.Message,'Error','error');
      });
    },
    SendMethod(Objdata,callback){
      this.isLoading(true);
      axios(config.api('post', Objdata.method ,{secret:Objdata.secret,post:Objdata.post})).then((response)=>{
        this.isLoading(false);
        callback(null,response.data.ResultData);
      },(error)=>{
        this.isLoading(false);
        callback(error.response.data.Message,null);
        // this.$alert(error.response.data.Message,'Error','error');
      });
    },
    summit(eve){
      if(eve == 'reject'){
        this.$prompt("ขอเหตุผลที่ไม่อนุมัติ").then((commit) => {
          this.SendData({
            _id: this.data._id,
            ac:'reject',
            commit: commit,
          },(err,resp)=>{
            if(err == null){
              this.$alert('บันทึกข้อมูลเรียบร้อย','Success','success').then(()=>{
                this.$emit('backPage', {action:{title:'table'}});
              });
            }else{
              this.$alert(err,'Error','error');
              this.$emit('backPage', {action:{title:'table'}});
            }
          });
        });
      }else{
        this.$confirm("แน่ใจแล้วไช่มั้ย?","แน่ใจ","question").then(()=>{
          this.SendData({
            _id: this.data._id,
            ac:'active',
            commit: 'อนุมัติ',
          },(err,resp)=>{
            if(err == null){
              this.SendMethod({
                method : this.data.method,
                secret: resp,
                post: this.data.post
              },(err,respev)=>{
                if(err == null){
                  this.$alert('อนุมัติเรียบร้อย','Success','success').then(()=>{
                    this.$emit('backPage', {action:{title:'table'}});
                  });
                }else{
                  this.$alert(err,'Error','error');
                  this.$emit('backPage', {action:{title:'table'}});
                }
              });
            }else{
              this.$alert(err,'Error','error');
            }
          });
        })

      }
    },
    iconBank(typeBank){
      return config.iconBank[typeBank]
    }
  },
  mounted(){
    this.LoadData();
    // console.log(utils);
    console.log('Costomer data',this.data);
  }
};
</script>
<style>
  div.active .form-control{
    border: solid 1px #F00 !important;
  }
  img.active{
    border: 2px #F00 solid;
  }
</style>
