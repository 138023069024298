<template>
  <div class="row">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <div class="col-lg-12" v-if="isLoading == false">
      <card v-if="!editer.open">
        <div class="row">
          <div class="col-8">
            <h5 slot="header" class="title">SYSTEM LOBBY</h5>
          </div> 
        </div>
        <div class="row"> 
          <div class="col-12">
            <label for="balance">เครดิต</label>
            <base-input id="balance" addon-left-icon="tim-icons icon-coins"  :text="utils.forMatNymber(dataForm.balance)"  placeholder="balance" />
          </div>
          <div class="col-6">
            <label for="domain">Domain</label>
            <base-input id="domain" addon-left-icon="tim-icons icon-world"  :text="dataForm.domain"  placeholder="balance" />
          </div>
          <div class="col-6">
            <label for="agentId">Operator Code</label>
            <base-input id="agentId" addon-left-icon="tim-icons icon-key-25"  :text="dataForm.agentId"  placeholder="balance" />
          </div>
          <div class="col-6">
            <label for="withdrawMax">ถอนสูงสุด</label>
            <base-input id="withdrawMax" :help="`*ข้อกำหนดสำหรับ Admin ถ้ายอดถอน ${dataForm.lobbyConfig.withdrawMax} เกินกว่าจะต้องให้ตำแหน่งใหญ่กว่ายืนยัน`" addon-left-icon="tim-icons icon-key-25" type="number"  v-model="dataForm.lobbyConfig.withdrawMax"  placeholder="Max" />
          </div>
          <div class="col-6">
            <label for="withdrawMinimum">ถอนขั้นต่ำ</label>
            <base-input id="withdrawMinimum" addon-left-icon="tim-icons icon-key-25" type="number"  v-model="dataForm.lobbyConfig.withdrawMinimum"  placeholder="Minimum" />
          </div>
          <div class="col-12">
            <label for="">Line Notifications รายการ ฝาก / ถอน</label>
            <base-input  addon-left-icon="tim-icons icon-chat-33" v-model="dataForm.lineNoti['ref1']"  placeholder="payoff" />
            <label for="">Line Notifications รายการ คอนเฟิร์ม</label>
            <base-input :help="'Notifications การกระทำต่างๆของระบบ'" addon-left-icon="tim-icons icon-chat-33" v-model="dataForm.lineNoti['ref2']"  placeholder="payoff" />
          </div>
        </div>
      </card>
       
      <card v-if="!editer.open">
        <div class="row">
          <div class="col-10">
            <h5 slot="header" class="title">Carousel</h5>
          </div>
          <div class="col-2 text-right">
            <base-button type="secondary" @click="addBanner()">เพิ่มแบนเนอร์</base-button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-4" v-for="(items,index) in dataPages.carouselSlide" style="position: relative;">
            <img :src="items.image" alt="">
            <p>{{items.detail}}</p>
            <base-button type="danger" title=" ลบ" style="position: absolute; top: 10px; right: 25px;" icon size="sm" @click="ChangeBansStats('delete',{id:index,items:items})">
              <i class="tim-icons icon-trash-simple"></i>
            </base-button> 
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-5 mb-5" v-if="BannerNew">
            <hr>
            <base-button type="warning" style="width: 100%;" class="mb-2"   @click="LoadData()">ยกเลิกข้อมูลใหม่</base-button>
            <base-button type="default" style="width: 100%;" class="mt-2"  @click="saveChanges()">Save changes</base-button>
          </div>
        </div>
      </card>
       
      <card v-if="!editer.open">
        <h5 slot="header" class="title">Config</h5>
        <div class="row">
          <div class="col-12 text-center">
            <label for="balance" class="text-left">Logo</label>
            <p><img :src="`${dataForm.lobbyConfig.logo}?s=300&c=${cache}`" alt=""></p>
          </div>
          <div class="col-12 mt-3">
            <base-file type="primary" value="อัพโหลดรูป" @filedata="onFileChange" :block="true" :param="{'path':`/${dataForm.agentId}/lobby`,'type':'logo','name':'logo'}" />
          </div> 
          <div class="col-6">
            <label for="condomain">Title Page</label>
            <base-input id="condomain" addon-left-icon="tim-icons icon-align-center" v-model="dataPages.title"  placeholder="Url" />
          </div> 
          <div class="col-6">
            <label for="condomain">Description</label>
            <base-input id="condomain" addon-left-icon="tim-icons icon-align-left-2" v-model="dataPages.description"  placeholder="Url" />
          </div>  
          <div class="col-6">
            <label for="condomain">Home Page</label>
            <base-input id="condomain" addon-left-icon="tim-icons icon-world" v-model="dataForm.lobbyConfig.domain"  placeholder="Url" />
          </div> 
          <div class="col-6">
            <label for="balance">ติดต่อเรา (@LINE)</label>
            <base-input id="balance" addon-left-icon="tim-icons icon-chat-33"  v-model="dataForm.lobbyConfig.contect"  placeholder="ติดต่อเรา (@LINE)" />
          </div>

          <div class="col-12 mt-4">
              <label for="Template">เลือก Template</label>
              <ul class="row Template">
                <li class="col-3" v-for="(item,index) in theme" :key="index" :class="{'active':(item.key == dataForm.lobbyConfig.tempage.name)}" @click="selectTheme(item)">
                  <div :style="[{'background':item.colors,'border':'solid 3px'+item.boder}]"> <p>{{item.name}}</p> </div>
                </li>
              </ul>
          </div>
          <div class="col-12 mt-5 mb-5">
            <hr>
            <base-button type="default" style="width: 100%;" @click="saveChanges()">Save changes</base-button>
          </div>
        </div>
      </card>
      <!-- ///// EDIT CSS -->
      <card v-if="editer.open">
        <h5 slot="header" class="title">SYSTEM LOBBY CSS STYLE</h5>
        <div class="row">
          <div class="col-12">
             <div class="row">
               <div class="col-4 text-center teb-css"> <base-button :type="editer.tab.adv" block @click="()=>{editer.tab.adv = 'primary' ; editer.tab.app = 'def'}"> <i class="tim-icons icon-settings"></i> Advance</base-button> </div>
               <div class="col-4 text-center teb-css"> <base-button type="default" simple @click="ToolEditBack()"> <i class="tim-icons icon-double-left"></i> Back</base-button> </div>
             </div>
             <div class="row mt-4"> 
               <div class="col-12" v-if="editer.tab.adv == 'primary'">
                 <div class="col-12 mt-3 mb-4">
                   <h2> Tool editer CSS</h2>
                 </div>
                 <div class="editerCSS">
                   <!-- <contenteditable v-modal="'skfjahskdhfashfkashfkjsd'" style="height: 1200px !important; color: #FFF; max-height: 2000px !important;" /> -->
                   <text-area v-model="editer.css" highlight style="height: 1200px !important;"/>
                 </div>
                 <!-- <div class="col-6"> -->
                 <div class="col-12 mt-5 mb-3">
                   <hr>
                   <base-button type="default" style="width: 100%;" @click="saveChangesSCCS('string')">Save changes</base-button>
                 </div>
               </div>
             </div>
          </div>
        </div>
      </card>
      <!-- //////// Modal --> 
      <modal :show.sync="modals.open"
             body-classes="p-0"
             modal-classes="modal-dialog-centered modal-sm">
          <card type="secondary"
                header-classes="bg-white pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0">
              <template>
                <strong>เพิ่มรายการ</strong>
              </template>
              <!-- ///////// MASTER ////////// -->
              <template class="mt-5 mb-5">
                <div class="row">
                  <div class="col-12 text-center">
                    <img :src="`${modals.data.image}?s=300&c=${cache}`" alt="">
                  </div>
                  <div class="col-12 inputFiles mt-3">
                    <base-file type="primary" value="อัพโหลดรูป" @filedata="onFileChange" :block="true" :param="{'path':`/${dataForm.agentId}/banners`,'type':'banner','name':''}" />
                  </div>
                  <div class="col-12">
                    <base-input label="รายละเอียด" v-model="modals.data.detail"  placeholder="Detail" />
                  </div> 
                </div>
              </template>
              <template slot="footer">
                  <div class="row">
                    <div class="col-4"><base-button type="secondary" class="w-100" @click="modals.open = false">Close</base-button></div>
                    <div class="col-8"><base-button type="primary" style="width:100%;" @click="saveBanner()">Save</base-button></div> 
                  </div>
              </template>
          </card>
      </modal>

    </div>
    <!-- <div class="col-lg-4" v-if="isLoading == false">
      <card>
        <h5 slot="header" class="title">VIEW LOBBY</h5>
        <div class="row">
           <view-lobby :dataForm="dataForm" :data="editer.style" />
        </div>
      </card>
    </div> -->
  </div>
</template>
<script>
import { Modal  , BaseFile , TextArea , ViewLobby,Contenteditable} from "@/components";
import themeData from '@/data/theme.json'
import axios from 'axios';
import config from '@/config.js';
import utils from '@/utils';
import InputTag from 'vue-input-tag'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// import css from 'highlight.js/lib/languages/css.js';
// hljs.registerLanguage('css', css);

  export default {
    components: {
      Loading,
      BaseFile,
      ViewLobby,
      TextArea,
      Contenteditable,
      Modal,
      InputTag
    },
    data() {
      return {
        isLoading: true,
        fullPage: true,
        cache: Math.floor(Date.now() / 1000),
        DataUpImage:{},
        dataForm: {},
        dataPages:{},
        BannerNew: false,
        pageTag:[
          {name:'หน้าแรก',key:'lobby'},
          {name:'ฝาก',key:'deposit'},
          {name:'เกม',key:'games'},
          {name:'ถอน',key:'withdraw'},
          {name:'โปรไฟล์',key:'profile'},
          {name:'คืนยอดเสีย',key:'loss'},
          {name:'แชร์',key:'share'}
        ],
        master: utils.permission('MASTER'),
        modals: {
          open: false,
          data:{
            detail:'',
            image:'',
            link:'',
            action: false,
            page:[],
            enable:false
          }
        },
        theme:[
          {name:'Default',key:'default',colors:'#999999',boder:'#514e4e'},
          {name:'Primary',key:'primary',colors:'#9c27b0',boder:'#61166e'},
          {name:'Info',key:'info',colors:'#00bcd4',boder:'#027786'},
          {name:'Success',key:'success',colors:'#4caf50',boder:'#2b6c2e'},
          {name:'Warning',key:'warning',colors:'#f7931e',boder:'#ea7c22'},
          {name:'Danger',key:'danger',colors:'#f44336',boder:'#8c180f'},
          {name:'Rose',key:'rose',colors:'#e91e63',boder:'#870d36'},
          {name:'แก้ไขด้วยมือ',key:'modify',colors:'#212121',boder:'#141313'},
        ],
        editer:{
          open: false,
          tab:{
            app: 'def',
            adv: 'primary',
          },
          style: { },
          css:''
        },
        slideStyle : '0px',
        utils:utils
      }
    },
    methods: {
      LoadMyStyle(theme){
        let themeIndex = (keys)=>{
          for (var i = 0; i < this.theme.length; i++) {
            if(this.theme[i].key == theme) return i;
          }
          return -1
        }
        axios(config.static('get',`/mystyles/${theme}`)).then((response)=>{
          if(themeIndex(theme) < 0){
            this.theme[this.theme.length - 1].colors = response.data.style.mainColor;
          }
          this.editer.style = response.data.style;
          this.editer.css = response.data.css;
        });
      },
      LoadData(){
        // console.log('config.urlstatic',config.urlstatic); 
        (async () => {
          try {
            let tableReq = await axios(config.api('get', '/looby/info'));
            tableReq.data.ResultData.agentinfo.lobbyConfig.logo = tableReq.data.ResultData.agentinfo.lobbyConfig.logo.replace('undefined/',config.urlstatic);
            this.dataForm.enable = (tableReq.data.ResultData.enable == 'off')?false:true;
            this.dataForm = tableReq.data.ResultData.agentinfo;
            this.dataPages = tableReq.data.ResultData.pages;
            if(this.dataForm.lobbyConfig.tempage.name == 'modify'){
              this.LoadMyStyle(this.dataForm.agentId);
            }else{
              this.LoadMyStyle(this.dataForm.lobbyConfig.tempage.name);
            }
            this.BannerNew = false;
            this.isLoading = false;
          } catch (error) {
            if(error.response.data.Code == 500){
              this.$router.push('/login')
            }else{
              this.$alert(error.response.data.Message,'Error','error');
            }
            this.isLoading = false;
          }
        })();
      },
      selectTheme(item){ 
        if(item.key == 'modify'){ 
          axios(config.static('get',`/mystyles/${this.dataForm.agentId}`)).then((response)=>{ 
            this.editer.open = true;
            this.dataForm.lobbyConfig.tempage.demo = item.colors;
            this.editer.style = response.data.style;
            this.editer.css = response.data.css;
            this.dataForm.lobbyConfig.tempage.style = `${config.urlstatic}/styles/${this.dataForm.agentId}/style.css?cs=${utils.makeId(20)}`;
            this.dataForm.lobbyConfig.tempage.name = item.key;
          });
        }else{ 
          axios(config.static('post',`/adstyles/${this.dataForm.agentId}/object`,themeData[item.key])).then((response)=>{
            this.LoadMyStyle(item.key);
            this.dataForm.lobbyConfig.tempage.demo = item.colors;
            this.dataForm.lobbyConfig.tempage.style = `${config.urlstatic}/styles/${item.key}/style.css?cs=${utils.makeId(20)}`;
            this.dataForm.lobbyConfig.tempage.name = item.key;
          }); 
        }
      },
      ToolEditBack(){
        this.editer.open = false;
        this.theme[this.theme.length - 1].colors = this.editer.style.mainColor;
      },
      addBanner(){
        if(this.dataPages.carouselSlide.length <= 20){
          this.modals.open = true;
          this.modals.data = {
            detail:'',
            image:'', 
            page:[],
            enable:false
          };
        }else{
          this.$alert('การเพิ่มแบนเนอร์เกินที่กำหนด','Permission','warning');
        }
      },
      isActive(name){
        return (this.modals.data.page.indexOf(name) > -1)?true:false
      },
      selectTagsPage(name){
        let indexF = this.modals.data.page.indexOf(name);
        if(indexF > -1){
          this.modals.data.page.splice(indexF, 1);
        }else{
          this.modals.data.page.push(name);
        }
      },
      saveBanner(){
        if (this.modals.data.detail) {
          this.dataPages.carouselSlide.push(this.modals.data);
          this.BannerNew = true;
          this.modals.open = false;
        }else{
          this.$alert('ข้อมูลไม่ครบ','warning','warning');
          this.isLoading = false;
        }
      },

      ChangeBansStats(event,data){
        if(event == 'enable'){
          this.BannerNew = true;
          this.dataForm.ads[data.id].enable= item;
        }else if(event == 'pin'){
          let enable = (data.items.action)?false:true;
          this.dataForm.ads[data.id].action= enable;
          this.BannerNew = true;
        }else{
          this.$confirm("แน่ใจว่าจะลบ",'warning','warning').then(() => {
            this.BannerNew = true;
            this.dataPages.carouselSlide.splice(data.id, 1);
          });
        }
      },
      switchSlide(){
        if(this.slideStyle == '0px'){
          this.slideStyle = '200px';
        }else{
          this.slideStyle = '0px';
        }
      },
      onFileChange(e) {
        if(utils.permission('MASTER')){ 
            axios(config.image({
              path: e.param.path,
              name: e.param.name || Math.floor(Date.now() / 1000),
              image: e.Base64
            })).then((response)=>{
              if(e.param.type == 'logo'){
                this.dataForm.lobbyConfig.logo = `${config.urlstatic}${response.data.path}`;
              }else if(e.param.type == 'playAgame'){
                this.dataForm.lobbyConfig.playAgame = `${config.urlstatic}${response.data.path}`;
              }else if(e.param.type == 'PlayAIngame'){
                this.dataForm.lobbyConfig.PlayAIngame = `${config.urlstatic}${response.data.path}`;
              }else if(e.param.type == 'banner'){
                this.modals.data.image = `${config.urlstatic}${response.data.path}`;
              }else if(e.param.type == 'icons'){
                this.dataForm.lobbyConfig.menus[e.param.index].img = `${config.urlstatic}/${response.data.path}`;
                this.dataForm.lobbyConfig.menus[e.param.index].enable = false;
              }
              this.cache = Math.floor(Date.now() / 1000);
              this.isLoading = false;
            },(error)=>{
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            });
        }else{
          this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
          this.isLoading = false;
        }
      },
      switchLobbyMenu(eve,index){
        this.dataForm.lobbyConfig.menus[index].enable = (eve == 'icons')?true:false;
      },
      saveChanges(){
        this.isLoading = true;
        axios(config.api('post', '/looby/update',{dataForm:this.dataForm,dataPages:this.dataPages})).then((response)=>{
          this.LoadData();
          this.$alert('แก้ไข้อมูลสำเร็จ','Success','success');
          this.isLoading = false;
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
      },
      saveChangesSCCS(meth){
        let methData = {'object': this.editer.style, 'string': {css:this.editer.css}};
        axios(config.static('post',`/adstyles/${this.dataForm.agentId}/${meth}`, methData[meth])).then((response)=>{
          this.dataForm.lobbyConfig.tempage.style = `${config.urlstatic}/styles/${this.dataForm.agentId}/style.css?cs=${utils.makeId(20)}`;
          this.theme[this.theme.length - 1].colors = this.editer.style.mainColor;
          this.saveChanges();
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      },
      editerColor(){
        this.dataForm.lobbyConfig.tempage.demo = this.editer.style.mainColor;
      },
      updateStatusServic(){
        this.saveChanges();
        // console.log('Mo',this.dataForm.enable);
      }
    },
    

    /// LOAD Form
    mounted(){
      this.LoadData();
    },
    beforeCreate() {

    }
  }
</script>
<style >
   @import '/css/icomoon.css';
  .Template{
    margin: 0px;
    padding: 0px;
  }
  .Template > li{
    display: block;
    padding: 10px;
    cursor: pointer;
  }
  .Template > li.active > div{
    border: solid 5px #000 !important;
  }
  .Template > li > div{
    text-align: center;
    background: #FFF;
    border-radius: 10px;
    padding: 15px;
    border: solid 3px #3a9c6c;
    color: #FFF;
  }
  .Template > li > div > p{ color: #FFF; font-weight: bold; }
  iframe{
    width: 100%;
    height: 100%;
    border: none;
  }
  .bodyLobby {
    position: relative;
    background: #1e1e2e;
    color: #FFF;
    width: 420px;
    height: 650px;
    overflow: hidden;
  }
  .bodyLobby>.slide{
    top: 0;
    position: absolute;
    left: 0px;
    width: 200px;
    background: #FFF;
    height: 100%;
  }
  .bodyLobby>.slide > ul{
    margin: 0px;
    padding: 10px;
  }
  .bodyLobby>.slide > ul >li{
     display: block;
     color: #FFF;
     margin: 10px;
  }
  .bodyLobby>.bodys{
    top: 0;
    position: absolute;
    left: 200px;
    width: 420px;
    position: relative;
    height: 650px;
    z-index: 100;
    background: #1e1e2e;
  }
  .bodyLobby>.bodys > .navi{
    height: 50px;
    background: #1a1e34;
    padding: 10px;
    color: #FFF;
  }
  .bodyLobby>.bodys > .boder{
    height: 530px;
    padding: 10px;
    color: #FFF;
  }
  .bodyLobby>.bodys > .footer{
    height: 70px;
    background: #1a1e34;
    padding: 10px;
    color: #FFF;
  }
  .buttonnavi{
    border: none;
    background: none;
    color: #FFF;
    cursor: pointer;
  }
  .buttonnavi:focus{
    outline: none;
  }
  .buttonnavi i{
    display: block;
    margin: -14px;
    font-size: 20px;
  }
  .bodyLobby>.bodys > .footer > ul{
    padding: 0px;
    margin: 0px;
  }
  .bodyLobby>.bodys > .footer > ul > li{
    display: inline-block;
    width: 80px;
    text-align: center;
  }
  .bodyLobby>.bodys > .footer > ul > li > p > i{
    font-size: 27px;
  }
  .bodyLobby>.bodys > .footer > ul > li > p{
    font-size: 11px;
    color: #525f7f;
  }
   div.card-body > div > div > div > div.bodys > div.boder > div.cash > div.box{
    border-radius: 20px;
    border: solid 3px #1e1e2e;
    padding: 10px;
    overflow: hidden;
    background: #f7931e;
    margin-bottom: 0px;
  }
  div.card-body > div > div > div > div.bodys > div.boder > div.cash > div.box > div.userCash{
    background: #1e1e2e;
    padding: 20px;
    height: 105px;
    border-radius: 10px;
  }
  div.card-body > div > div > div > div.bodys > div.boder > div.cash > div.balan{
    border: solid 3px #1e1e2e;
    background: #f7931e;
    padding: 15px;
    height: 100px;
    width: 95%;
    margin: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
 }
 .btnCardit {
     background: #1e1e2e;
     color: #FFF;
     border: none;
     padding: 0px;
     width: 100%;
     border-radius: 10px;
     height: 41px;
     font-size: 11px;
 }
 .iconGames > ul{
   padding: 0px;
   margin: 15px;
 }
 .iconGames > ul > li{
   display: inline-block;
   width: 92px;
   height: 92px;
 }
 .iconGames > ul > li > div{
   /* background: #303030; */
   text-align: center;
   margin: 0px;
   font-size: 11px;
  padding: 10px;
  border-radius: 8px;
 }
 .switchIcon{
   margin: 0px;
   padding: 0px;
 }
 .switchIcon > li{
   display: inline-block;
   background: #383a4a;
   background-size: 210% 210%;
   background-position: 100% 0;
   -webkit-transition: all .15s ease;
   transition: all .15s ease;
   -webkit-box-shadow: none;
   box-shadow: none;
   color: #fff;
   text-align: center;
   margin: 2px 6px;
   float: left;
   border-radius: 5px;
   cursor: pointer;
   padding: 10px
 }
 .switchIcon > li.active{
  background: #e14eca !important;
  color: #FFF !important;
 }
 .inputFilesNone label {
    width: 100%;
    display: block;
    padding: 10px;
    text-align: center;
    background: #262733;
    border-radius: 10px;
    color: #FFF;
}
.my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;
    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
  .teb-css > div{
    float: none !important;
  }
  .tabMenuGame{
    border-radius: 20px;
    padding: 10px;
  }
  .tabMenuGame > ul{
    margin: 0px;
    padding: 0px;
  }
  .tabMenuGame > ul > li{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    width: 18%;
    padding: 5px;
  }
  .tabMenuGame > ul > li i{
    font-size: 50px;
  }
  .sel-bg-close{
    background-color: #2d2d2d;
  }
  .sel-bg-on{
    background-color: #3a9c6c;
  }
  .sel-bg-off{
    background-color: brown;
  }
</style>
