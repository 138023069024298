<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <div class="row">
            <div class="col-9">
              <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากเลขบันชีเลขท้าย ... <Enter>" v-on:keyup.enter="searchWord()">
              </base-input>
            </div>
            <div class="col-3">  
              <base-button type="warning" size="md" class="pull-right " @click="onExport"> <i class="tim-icons icon-cloud-download-93"></i> XLSX </base-button>
            </div> 
          </div>
        </card>
      </div>
      <div class="table-responsive">
        <base-alert v-if="isAlert.open" type="danger" v-on:click="isAlert.open == false">
          <strong> {{isAlert.tip}} </strong> {{isAlert.msg}}
          <button type="button" aria-label="Close" class="close" @click="isAlert.open = false"><span aria-hidden="true"><i class="tim-icons icon-simple-remove"></i></span></button>
        </base-alert>
        <base-table-deposit :data="table.data" :columns="table.columns" :permis="permis" @user-modals="eventProd" :sort="table.sort" @SortTable="SortTable" thead-classes="text-primary"> </base-table-deposit>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>

  <!-- //////// -->

  <modal :show.sync="modals.open"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
          <template>
            <span v-html="modals.title"></span>
          </template>
          <template v-if="modals.data.owner == null">
              <div class="text-center text-muted mb-4">
                  <small>แก้ไขการเติมเงิน</small>
              </div>
              <form role="form">
                  <label for="">ยอดเข้า</label>
                  <base-input alternative
                              class="mb-3"
                              placeholder="0"
                              v-model="modals.data.amount"
                              addon-left-icon="tim-icons icon-money-coins" disabled>
                  </base-input>
                  <label for="">เบอร์โทรสมาชิกที่ต้องการแมท</label>
                  <base-input alternative
                              type="text"
                              placeholder="092xxxxx"
                              v-model="modals.data.telMember"
                              addon-left-icon="tim-icons icon-badge">
                  </base-input>
                  <label for="">แจ้งหมายเหตุว่า</label>
                  <base-input alternative
                              type="text"
                              placeholder="แจ้งหมายเหตุว่า"
                              v-model="modals.data.commit" >
                  </base-input>
              </form>
          </template>
          <template v-if="modals.data.owner != null">
              <div class="mb-1">
                  <strong>UID: </strong> {{modals.data.owner._id}}
              </div>
              <div class="mb-1">
                  <strong>Name: </strong> {{modals.data.owner.name}}
              </div>
              <div class="mb-1">
                  <strong>Tel: </strong> {{modals.data.owner.tel}}
              </div>
              <div class="mb-1">
                  <strong>Commit: </strong> {{modals.data.owner.commit || '-'}}
              </div>
          </template>
          <template slot="footer">
            <base-button type="secondary" @click="modals.open = false">Close</base-button>
            <base-button v-show="modals.data.telMember && modals.data.commit" type="primary" style="width: 225px;" @click="saveChanges()">Save changes</base-button>
          </template>
      </card>
  </modal>


</div>
</template>
<script>
import { BaseTableDeposit, Modal , BaseAlert} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "type", "amount", "balance", "from" , "created_at", "active"];
const tableData = [];

export default {
  components: {
    BaseTableDeposit,
    SlidingPagination,
    Modal,
    BaseAlert,
    Loading
  },
  data() {
    return {
      permis:{
        delete: utils.permission('LISTTRANSFERFAIL_DEL'),
        edit : utils.permission('LISTTRANSFERFAIL_EDIT')
      },
      table: {
        sort:{
          even:'desc',
          col:'created_at'
        },
        title: "รายการฝากไม่สำเร็จ",
        columns: [...tableColumns],
        data: [...tableData]
      },
      modals: {
        open: false,
        title: '',
        data :{},
        telMember: '',
        commit: ''
      },
      isAlert: {open:false,tip:'',msg:''},
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/TransferFail/'+selectedPage));
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    SortTable(column){ 
      let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
      let ObjK = {};
      ObjK[column] = this.table.data;
      this.table.sort.col = column;
      this.table.sort.even = sortType; 
      this.table.data = utils.Sort[sortType](ObjK) 
    },
    eventProd(itesObj) {
      //// edit
      if(utils.permission('LISTTRANSFERFAIL_EDIT') && itesObj.eve == 'edit'){
        this.modals.open = true;
        this.modals.title = `<strong>${ itesObj.data.type.toUpperCase() } :</strong> ${ itesObj.data.from }`; //itesObj.from;
        this.modals.data = itesObj.data;
      }
      ////// delete
      if(utils.permission('LISTTRANSFERFAIL_DEL') && itesObj.eve == 'delete'){
        this.$confirm(`แน่ใจ ว่าจะลบ รายการ ของ "${itesObj.data.from}" ฝาก ${utils.forMatMony(itesObj.data.amount)} จากรายการของ ${itesObj.data.type.toUpperCase()}`).then(() => {
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/TransferFail/rejact',itesObj.data));
              this.table.data.splice(itesObj.index, 1);
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        },(error)=>{});
      }
      //console.log('itesObj',itesObj);
    },
    searchWord(){
      if(utils.permission('LISTTRANSFERFAIL_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/TransferFail/1'));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/TransferFail/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    saveChanges(){
      if(utils.permission('LISTTRANSFERFAIL_EDIT')){
        if(this.modals.data.telMember && this.modals.data.commit){
          this.isLoading = true;
          axios(config.api('post', '/TransferFail/addPoint',{id:this.modals.data._id,tel:this.modals.data.telMember,commit:this.modals.data.commit})).then((addPoint)=>{
            this.modals.open = false;
            this.isLoading = false;
            this.modals.data.status = addPoint.data.ResultData.status
            this.modals.data.owner = addPoint.data.ResultData.owner
          },(error)=>{
            this.isLoading = false;
            this.modals.open = false;
            this.$alert(error.response.data.Message,'Error','error');
          });
        }else{
          this.modals.open = false;
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      }
    },
    onExport() { 
      // const tableColumns = [ "type", "amount", "balance", "from" , "created_at", "active"];
      let DataSheet = [
            {
                sheet:  this.table.title,
                columns:[ 
                    { label: "ธนาคาร", value: "type" }, 
                    { label: "จำนวน", value: "amount" },
                    { label: "คงเหลือในบันชี", value: "balance" },
                    { label: "จาก", value: "from" }, 
                    { label: "เวลา", value: "created_at" , format:'h:mm AM/PM'}, 
                ],
                content:this.table.data
            }
        ];
        axios(config.api('post', `/toxlsx`,DataSheet)).then((response) => { 
          window.open(`${config.apiplayload}/${response.data.ResultData}`,'_blank');
          this.isLoading = false; 
        }, (error) => {
          this.isLoading = false;
          this.$alert(error.response.data.Message, 'Error', 'error');
        });
    }
  },
  mounted(){
    ///// GET List True
    if(utils.permission('LISTTRANSFERFAIL_VIEW')){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/TransferFail/1'));
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = tableReq.data.ResultData.skipdata;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }else{
      this.isLoading = false;
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');

    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
