<template>
     <div>  
         {{seclectTage}}
        <section class="mb-3" ref="costomer" id="costomer">
          <h3>ข้อมูลสมาชิก</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/memners/costomer/table.png" style="width: 100%;" alt="dashboard">
                         <p>รายการตรางสมาชิกและเมนูสำหรับแก้ไขสมาชิก</p>
                    </div>  
                    <div class="col-3">
                         <ul class="iconmenu">
                              <li>
                                   <button class="btn btn-info icon sm" @click="event = 'edit'"><i class="tim-icons icon-paper"></i></button> แก้ไขสมาชิก
                              </li>
                              <li>
                                   <button class="btn btn-default icon sm" @click="event = 'info'"><i class="tim-icons icon-bullet-list-67"></i></button> รายละเอียดสมาชิก
                              </li>
                              <li>
                                   <button class="btn btn-success icon sm" @click="event = 'freecode'"><i class="tim-icons icon-tag"></i></button> ฟรีโค๊ต
                              </li>
                              <li>
                                   <button class="btn btn-warning icon sm"><i class="tim-icons icon-vector"></i></button> แต้มที่ได้จากเพื่อน
                              </li>
                              <li>
                                   <button class="btn btn-danger icon sm"><i class="tim-icons icon-lock-circle"></i></button> บ๊อค
                              </li>
                              <li>
                                   <button class="btn btn-primary icon sm"><i class="tim-icons icon-lock-circle"></i></button> ปลดบ๊อค
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="margin: 5px; width: 95px;" class="tale-status-wait icon sm"> Wait </span></li>
                                        <li>สมารถขอ ฟรีโค๊ต ได้ </li>
                                   </ul>
                                    
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="margin-top: 5px; width: 95px;" class="tale-status-reject icon sm"> Redy </span></li>
                                        <li>ฟรีโค๊ตไม่ได้เพราะมีการเติมเงินไปแล้ว</li>
                                   </ul> 
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="margin: 5px; width: 95px;" class="tale-status-active icon sm"> D248N </span></li>
                                        <li>ฟรีโค๊ตถูกใช่งานแล้ว</li>
                                   </ul> 
                              </li>
                         </ul> 
                    </div> 
                     <div class="col-9">
                         <div v-show="event == 'edit'">
                              <img src="/img/help/memners/costomer/edit.png" style="width: 100%;" alt="dashboard">
                              <p>หน้าต่างแก้ไขข้อมูลสมาชิก</p>
                         </div>
                         <div v-show="event == 'info'">
                              <img src="/img/help/memners/costomer/report.png" style="width: 100%;" alt="dashboard">
                              <p>หน้าต่างข้อมูลการกะทำทั้งหมดของสมาชิกไม่ว่าจะเป็นการเติมเงินหรือถอนเงิน</p>
                         </div>
                         <div v-show="event == 'freecode'">
                              <img src="/img/help/memners/costomer/kditfree.png" style="width: 100%;" alt="dashboard">
                              <p>ใน INPUT จะมีรหัสที่จะให้ สมาชิกใช้งานได้และรหัสจะใช้ได้แต่สมาชิกนั้นๆไม่สามารถให้คนอื่นได้</p>
                         </div>
                    </div> 
               </div>
          </div>
        </section>
        <section class="mt-3" ref="modlist" id="modlist">
          <h3>รายชื่อผู้ดูแลระบบ</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/memners/modlist/table.png" style="width: 100%;" alt="dashboard">
                         <p>รายการตรางผู้ดูแลและเมนูสำหรับแก้ไขผู้ดูแล</p>
                    </div>  
                    <div class="col-3 mt-3">
                         <ul class="iconmenu">
                              <li>
                                   <button class="btn btn-info icon sm" @click="eventmod = 'edit'">
                                        <i class="tim-icons icon-badge"></i>
                                   </button> ข้อมูลสมาชิก
                              </li>
                              <li>
                                   <button class="btn btn-warning icon sm" >
                                        <i class="tim-icons icon-lock-circle"></i>
                                   </button> บ๊อคปิดกันไม่ให้ใช้งาน
                              </li>
                              <li>
                                   <button class="btn btn-primary icon sm" >
                                        <i class="tim-icons icon-lock-circle"></i>
                                   </button> ยกเลิกบ๊อคเปิดให้ใช้งานปกติ
                              </li>
                              <li>
                                   <button class="btn btn-danger icon sm" >
                                        <i class="tim-icons icon-trash-simple"></i>
                                   </button> ลบบันชีทิ้ง
                              </li>
                              <li>
                                   <button class="btn btn-primary icon sm" @click="eventmod = 'newuser'">
                                        เพิ่มผู้ดูแล
                                   </button> เพิ่มผู้ดูแลใหม่
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li>
                                             <div class="form-control" style="width: 136px; overflow: hidden;">
                                                  <i class="tim-icons icon-zoom-split"></i> ค้าหาชื่อหรือเบอร์
                                             </div> 
                                        </li>
                                        <li>ค้าหาชื่อหรือเบอร์</li>
                                   </ul>
                                   
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="width: 95px; margin: 5px;" class="tale-status-active"> ใช้งาน </span></li>
                                        <li>สถานะปกติ</li>
                                   </ul>
                                    
                              </li>
                              <li>
                                   <ul class="iconmenu-sub">
                                        <li><span style="width: 95px; margin: 5px;" class="tale-status-reject"> ระงับ </span></li>
                                        <li>สถานะโดนระงับ</li>
                                   </ul> 
                              </li>
                         </ul>
                    </div>
                    <div class="col-9 mt-3">
                         <div v-show="eventmod == 'edit'">
                              <img src="/img/help/memners/modlist/edit.png" style="width: 100%;" alt="dashboard">
                              <p>แก้ไขข้อมูลผู้ดูแลสามารถปรับแต่งหรือเปลียนแปลงกลุ่ม</p>
                         </div>
                         <div v-show="eventmod == 'newuser'">
                              <h3>สร้างผู้ดูแลใหม่</h3>
                              <img src="/img/help/memners/modlist/newuser.png" style="width: 100%;" alt="dashboard">
                              <p>สิทธิ์แต่ละหัวข้อคือเมนูที่สามารถจัดการได้หรือจะกำหนดสิทธิแบบกลุ้มก็ได้</p>
                              <h3>สร้างสร้างผู้ดูแลใหม่โดยใช้สิทธิ์แบบกลุม</h3>
                              <img src="/img/help/memners/modlist/newuserbygroup.png" style="width: 100%;" alt="dashboard">
                              <p>การให้สืทธิ์แบบกลุ้มเพื่อง่ายต่อการจัดการแต่ละประเภทของผู้ดูแลโดยจำกัดใช้เฉพาะการกระทำนั้น</p>
                              <h3>สร้างสิทธิ์สำหรับกลุม</h3>
                              <img src="/img/help/memners/modlist/group.png" style="width: 100%;" alt="dashboard">
                              <p>การสร้างสิทธิ์แบบกลุ่มกำหนดให้กลุ่มนั้นๆสามรถกระทำอะไรได้บ่าง</p>
                         </div>
                    </div>
               </div>
          </div>
        </section> 
     </div>
</template>
<script>
export default { 
     props: {
          tag:{
               type:String,
               default:'costomer'
          }
     }, 
     data() {
          return {
               event: 'edit',
               eventmod: 'edit'
          }
     },
     computed:{
          seclectTage(){ 
               try {
                    let ele = this.$refs[this.tag]; 
                    if(ele.offsetTop > 0){ 
                         ele.scrollIntoView({behavior: 'smooth'})
                         // window.scrollTo(ele.offsetTop);
                    } 
               } catch (error) {}
          }
     },
     mounted() {
          
     },
}
</script>
<style scoped>
     .body-htlp-tag{
          margin-bottom: 50px;
          border-bottom: dotted 2px rgb(59, 58, 58);
          padding-bottom: 50px !important;
     }
     .btn-action {
          background: #c8c8c8;
     }
     .iconmenu,.iconmenu-sub{
          margin: 0px;
          padding: 0px;
     }
     .iconmenu > li {
          display: block;
     }
     .iconmenu-sub > li{
          display: inline-block;
     }
</style>