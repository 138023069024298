<template>
    <div class="row">
      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading> 
        <div class="col-12">
            <card :title="table.title">
            <div class="table-responsive">
                <base-table-tag-game :columns="columns" :data="data" type="hover"/>
            </div>
            </card>
        </div>
    </div>
    </template>
    <script>
    import {
        BaseTableTabGame 
    } from "@/components";
    // Import component
    import Loading from 'vue-loading-overlay'; 
    import 'vue-loading-overlay/dist/vue-loading.css'; 
    
    /////
    import axios from 'axios';
    import config from '@/config.js';
    import utils from '@/utils'; 
    const tableColumns = [ "name", "agent", "lose" ,"commission"];
    const tableData = [];
    
    export default {
      components: {
        BaseTableTabGame,  
        Loading
      },
      data() {
        return {
          table: {
            title: "ประเภทเกม",
            columns: [...tableColumns],
            data: [...tableData]
          },
          isLoading: false,
          fullPage: true,
        };
      },
      methods: {
        
    
      },
      mounted(){
  
      }
    
    };
    </script>
    <style>
 
    </style>
    