<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากชื่อ/เบอร์/เลขบัญชี ... <Enter>" v-on:keyup.enter="searchWord()">
          </base-input>
        </card>
      </div>
      <!-- <div class="mb-3">
        <card>
          <strong>Help : </strong>
            <base-button type="info" style="margin: 0px 5px;" icon size="sm" disabled>
              <i class="tim-icons icon-paper"></i>
            </base-button>  ดูข้อมูลสมาชิก
            <base-button type="success" style="margin: 0px 5px;" icon size="sm" disabled>
              <i class="tim-icons icon-tag"></i>
            </base-button> ให้แต้มฟรี
            <base-button type="warning" style="margin: 0px 5px;" icon size="sm" disabled>
              <i class="tim-icons icon-vector"></i>
            </base-button> แต้มที่ได้จากเพื่อน
            <base-button type="default" style="margin: 0px 5px;" icon size="sm" disabled>
              <i class="tim-icons icon-controller"></i>
            </base-button> แก้ไขเครดิตในเกม
            <base-button type="danger" style="margin: 0px 5px;" icon size="sm" disabled>
              <i class="tim-icons icon-lock-circle"></i>
            </base-button> บ๊อคหรือห้ามเข้าใช้งาน
            <base-button type="primary" style="margin: 0px 5px;" icon size="sm" disabled>
              <i class="tim-icons icon-lock-circle"></i>
            </base-button> ปลดบ๊อคหรือเปิดให้ใช้งานได้
        </card>
      </div> -->
      <div class="table-responsive">
        <base-table-friend-list :data="table.data" :columns="table.columns" :columnsTh="table.columnsTh" thead-classes="text-primary"></base-table-friend-list>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>


</div>
</template>
<script>
import {
  BaseTable,
  BaseAlert,
  Modal,
  BaseTableFriendList
} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";

/////
import axios from 'axios';
import config from '@/config.js';
import utils from '@/utils';
/////
// const generateUntil = function(length) {
//     var result           = '';
//     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     var charactersLength = characters.length;
//     for ( var i = 0; i < length; i++ ) {
//        result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }
//     return result;
//   }
/////
const tableColumns = [ "memberName", "gameId","reward","_id","created_at"];
const tableColumnsTh = ["ชื่อผู้ใช้", "เกมไอดี", "ยอดที่ได้รับ","อ้างอิง","วัน/เวลา"];
const tableData = [];

export default {
  components: {
    BaseTable,
    SlidingPagination,
    BaseTableFriendList,
    Loading
  },
  data() {
    return {
      table: {
        title: "ชวนเพื่อน",
        columns: [...tableColumns],
        columnsTh: [...tableColumnsTh],
        data: [...tableData]
      },
      friends:{},
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: '',
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      axios(config.api('get', '/costomer/friendspoint/'+selectedPage)).then((tableReq)=>{
        this.table.data = tableReq.data.ResultData.skipdata;
        this.currentPage = selectedPage;
        this.isLoading = false;
      },(error)=>{
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    searchWord(){
      if(utils.permission('FRIENDSPOINT_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/costomer/friendspoint/1'));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/costomer/friendspoint/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    ClipboardVouche(e){
      this.modals.open = false;
    }

  },
  mounted(){
    if(utils.permission('FRIENDSPOINT_VIEW')){
      if(window.location.hash.split("id=").length > 1){
        let findName = decodeURIComponent(window.location.hash.split("id=")[1]);
        this.searchName = findName;
        setTimeout(function () {
          this.searchWord();
        }.bind(this), 500);
      }
    ///// GET List True
      axios(config.api('get', '/costomer/friendspoint/1')).then((response)=>{
        this.totalPages = response.data.ResultData.numpage.page;
        this.table.data = response.data.ResultData.skipdata;
        this.isLoading = false;
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    }else{
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      this.isLoading = false;
    }
  },
  beforeCreate() {

  }

};
</script>
<style>

</style>
