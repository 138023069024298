<template>
    <div class="row">
      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
      <div class="col-12">
        <card :title="promoUsePoint.title">
          <div class="row" v-if="master">
            <div class="col-12">
              <base-button type="primary pull-right" @click="openForm()">เพิ่มรายการ</base-button>
            </div>
          </div>
          <div class="table-responsive">
            <base-table-promotion :data="promoUsePoint.data"
                        :columns="promoUsePoint.columns"
                        @use-modals="useModals"
                        @pin-modals="pinmodify"
                        thead-classes="text-primary">
            </base-table-promotion>
          </div>
        </card>
      </div>

      <div class="col-12">
        <card :title="promonGift.title">
          <div class="table-responsive">
            <base-table-promotion :data="promonGift.data"
                        :columns="promonGift.columns"
                        @use-modals="useModals"
                        thead-classes="text-primary">
            </base-table-promotion>
          </div>
        </card>
      </div>

      <div class="col-12">
        <card :title="promonVoucher.title">
          <div class="table-responsive">
            <base-table-promotion :data="promonVoucher.data"
                        :columns="promonVoucher.columns"
                        @use-modals="useModals"
                        thead-classes="text-primary">
            </base-table-promotion>
          </div>
        </card>
      </div>


      <!-- //////// Modal -->

      <modal :show.sync="modals.open"
             body-classes="p-0"
             modal-classes="modal-dialog-centered modal-sm">
          <card type="secondary"
                header-classes="bg-white pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0">
              <template>
                <strong v-if="modals.eve == 'info'">โปรโมชั่น</strong>
                <strong v-if="modals.eve == 'new'"> เพิ่มโปรโมชั่น</strong>
              </template>
              <!-- ///////// MASTER ////////// -->
              <template v-if="modals.data.owner == null && master">
                  <div class="text-center text-muted mb-4">
                      <small>แก้ไขการเติมเงิน</small>
                  </div>
                  <form role="form" class="row">
                    <div class="col-12">
                      <base-input label="หัวข้อ" type="text" v-model="modals.data.title" placeholder="Enter text"></base-input>
                    </div>
                    <div class="col-12">
                      <!-- <base-input label="รายละเอียด" type="text" v-model="modals.data.detail" placeholder="Enter text"></base-input> --> 
                      <text-area label="รายละเอียด"  v-model="modals.data.detail" />
                    </div>
                    <div class="col-12 text-center">
                      <img :src="modals.data.img" alt="">
                    </div>
                    <div class="col-12 inputFiles mt-3">
                      <base-file type="primary"  style="width: 100%;" value="อัพโหลดรูป" @filedata="onFileChange" :block="true" :param="{'path':`/${agentId}/promotion`,'name':modals.imageName}" />
                      <!-- <base-input label="อัพโหลดรูป" type="file" v-on:change="onFileChange"></base-input> -->
                    </div>
                    <div class="col-6">
                      <p><label for="freeCode">รูปแบบคูปอง (Free credit)</label></p>
                      <label class="switch" id="freeCode">
                        <input v-model="modals.data.voucher" type="checkbox" checked>
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-6">
                      <base-input label="รูปแบบ">
                        <select class="form-control" id="protype" v-model="modals.data.protype">
                          <option value="giftFriends">ชวนเพื่อน</option>
                          <option value="credit">โบนัสเครดิต</option>
                        </select>
                      </base-input>
                    </div>
                    <div class="col-6">
                      <base-input label="จำนวนโบนัส" type="number" v-model="modals.data.bonut" placeholder="Enter text"></base-input>
                    </div>
                    <div class="col-6">
                      <base-input label="รูปแบบการเพิ่ม">
                        <select class="form-control" id="protype" v-model="modals.data.type">
                          <option value="persen">เปอร์เซน</option>
                          <option value="plus">บวก</option>
                        </select>
                      </base-input>
                    </div>
                    <div class="col-4">
                      <!-- modals.data.typeturn -->
                      <!-- <base-input ref="withdraw" label="ต้องทำยอด Turn เคดิต" v-if="modals.data.typeturn == 'truecdiet'" type="number" v-model="modals.data.withdraw" placeholder="Enter text"></base-input>
                      <base-input ref="withdraw" label="ต้องทำยอด Turn โอเวอร์" v-if="modals.data.typeturn == 'trueover'" type="number" v-model="modals.data.withdraw" placeholder="Enter text"></base-input>
                       -->
                       <div>
                          <div class="form-group">
                          <label class="control-label" v-if="modals.data.typeturn == 'truecdiet'"> ต้องทำยอด Turn เคดิต </label>
                          <label class="control-label" v-if="modals.data.typeturn == 'trueover'"> ต้องทำยอด Turn โอเวอร์ </label>
                          <input  v-model="modals.data.withdraw"  ref="withdraw" aria-describedby="addon-right addon-left" type="number" placeholder="Enter text" class="form-control">
                        </div>
                        <div>
                          <p class="helperText form-text text-muted"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <base-input label="รูปแบบยอด">
                        <select class="form-control" id="protype" v-model="modals.data.typeWithdraw">
                          <option value="none">ปกติ</option>
                          <option value="multiply">คูณ</option>
                        </select>
                      </base-input>
                    </div>
                    <div class="col-4">
                      <base-input label="จำกัดการฝาก" type="number" v-model="modals.data.transfer" placeholder="Enter text"></base-input>
                    </div>
                    <div class="col-6">
                      <base-input label="จำกัดยอดรับเครดิต" type="number" v-model="modals.data.getbonutLinit" placeholder="Enter text"></base-input>
                    </div>
                    <div class="col-6">
                      <base-input label="จำกัดยอดถอน" type="number" v-model="modals.data.withdrawLimit" placeholder="Enter text"></base-input>
                    </div>
                    <div class="col-4">
                      <base-input label="จำนวนครั้ง" type="number" v-model="modals.data.useLimit" placeholder="Enter text"></base-input>
                    </div>
                    <div class="col-4">
                      <base-input label="รูปแบบจำนวนครั้ง">
                        <select class="form-control" id="protype" v-model="modals.data.typeLimit"  @change="changeTypeLimit('change')">
                          <option value="none">รับได้ตลอด</option>
                          <option value="day">วันละครั้ง</option>
                          <option value="forever">รับได้ครั้งเดียว</option>
                        </select>
                      </base-input>
                    </div>
                    <div class="col-4">
                      <base-input label="รูปแบบยอด Turn">
                        <select class="form-control" id="protype" v-model="modals.data.typeturn" @change="$refs.withdraw.focus()"> 
                          <option value="truecdiet">Turn เคดิต</option>
                          <option value="trueover">Turn โอเวอร์</option>
                        </select>
                      </base-input>
                    </div>


                    <div class="col-12" v-if="modals.data.typeLimit == 'day'">
                      <div class="row" v-if="!modals.data.opentime">
                        <div class="col-12"><base-button type="warning" style="width: 100%;" block @click="changeTypeLimit('click')"> <i class="tim-icons icon-time-alarm"></i> กำหนดช่วงเวลา</base-button></div>
                      </div>
                      <div class="row" v-if="modals.data.opentime">
                        <div class="col-6">
                          <base-input label="เริ่มเวลา" type="time" v-model="modals.data.startTime" placeholder="09:00"></base-input>
                        </div>
                        <div class="col-6">
                          <base-input label="หมดเวลา" type="time" v-model="modals.data.endTime" placeholder="23:59"></base-input>
                        </div>
                      </div>
                    </div>



                    <div class="col-6">
                      <base-input label="ยอดคงเหลือเท่าไหร่ถึงเปลียนโปรได้" type="number" v-model="modals.data.total" placeholder="Enter text"></base-input>
                    </div>
                    <div class="col-6">
                      <base-input label="กิจกรรม AF Link">
                        <select class="form-control" id="protype" v-model="modals.data.onlyEvent" >
                          <option v-for="item in shortLink" :value="item.social">{{item.social}}</option>
                        </select>
                      </base-input>
                    </div>
                    <div class="col-12">
                      <p><label for="freeCode">โปรโมชั่นนี้ใช้ได้กับเกมอะไร</label></p>
                      <div class="row">
                        <!-- gameAgent -->
                        <div class="col-6 text-center optionGaneAgen" v-for="item in gameAgent">
                          <a v-bind:class="{ active: isActive(item.agent) }" @click="selectAgent(item.agent)">{{item.name}}</a>
                        </div>
                      </div>
                    </div>
                  </form>
              </template>

              <template slot="footer">
                <base-button type="secondary" @click="modals.open = false">Close</base-button>
                <base-button v-if="master" type="primary" style="width: 225px;" @click="saveChanges()">Save</base-button>
              </template>
          </card>
      </modal>

    </div>
</template>
<script>
import { BaseTablePromotion , Modal , BaseFile , TextArea } from "@/components";
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import config from '@/config.js';
import utils from '@/utils';

const tableColumns = ["title","agent","bonut","getbonutLinit","withdrawLimit" , "pin","status"];
const tableData = {
  promoUsePoint:[],
  promonGift:[],
  promonVoucher:[],
  promonLoss:[]
};

export default {
  components: {
    BaseTablePromotion,
    BaseFile,
    TextArea,
    Modal,
    Loading
  },
  data() {
    return {
      cache: '',
      agentId: utils.localDB.jwt('token').agentId,
      promoUsePoint: {
        title: "โปรโมชั่น",
        columns: [...tableColumns],
        data: [...tableData.promoUsePoint]
      },
      master: utils.permission('MASTER'),
      promonGift: {
        title: "ชวนเพื่อนรับพ้อย",
        columns: [...tableColumns],
        data: [...tableData.promonGift]
      },
      promonVoucher: {
        title: "แจกโค๊ดฟรี",
        columns: [...tableColumns],
        data: [...tableData.promonVoucher]
      },
      promonLoss: {
        title: "คืนยอดเสีย",
        columns: [...tableColumns],
        data: [...tableData.promonLoss]
      },
      isLoading: true,
      fullPage: true,
      gameAgent:[],
      shortLink:[],
      modals: {
        open: false,
        imageName: Math.floor(Date.now()),
        data :{
          agent: [],
          pin:0,
          img: '',
          bonut: '',
          created_at: '',
          detail: '',
          getbonutLinit: '',
          protype: '',
          status: '',
          title: '',
          total: '',
          transfer: '',
          type: '',
          typeLimit: '',
          typeWithdraw: 'none',
          updated_at: '',
          useLimit: '',
          voucher: '',
          typeturn:'truecdiet',/// none truecdiet
          withdraw: '',
          withdrawLimit: '',
          opentime:false,
          startTime:'',
          endTime:'',
          onlyEvent:'none'
        },
        index: 0,
        eve: 'new'
      },
    };
  },
  methods: {
    LoadData(){
      if(utils.permission('PROMOTION_VIEW')){
        // Load list promotion
        this.isLoading = true;
        axios(config.api('get', '/control/listPromotion')).then((response)=>{
          let promoUsePoint = [],   promonGift = [], promonVoucher = [] ;
          for (var i = 0; i < response.data.ResultData.promotion.length; i++) {
            if(response.data.ResultData.promotion[i].voucher == false && response.data.ResultData.promotion[i].protype == 'credit'){
              promoUsePoint.push(response.data.ResultData.promotion[i]);
            }
            if(response.data.ResultData.promotion[i].voucher == false && response.data.ResultData.promotion[i].protype == 'giftFriends'){
              promonGift.push(response.data.ResultData.promotion[i]);
            }
            if(response.data.ResultData.promotion[i].voucher == true && response.data.ResultData.promotion[i].protype == 'credit'){
              promonVoucher.push(response.data.ResultData.promotion[i]);
            }
          }
          this.promoUsePoint.data = utils.sortA({'pin':promoUsePoint});
          this.promonGift.data = utils.sortA({'pin':promonGift});
          this.promonVoucher.data = utils.sortA({'pin':promonVoucher});
          this.isLoading = false;
          this.gameAgent = response.data.ResultData.tagGame;
          this.shortLink = response.data.ResultData.short; 
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
          this.gameAgent = [
            {name:'สล็อต',agent:'slot'},
            {name:'บาคาร่า',agent:'baccarat'},
            {name:'ยิงปลา',agent:'fish'},
            {name:'บิงโก',agent:'bingo'},
            {name:'บอล',agent:'ball'},
            {name:'อาร์เคด',agent:'arcade'}
          ];
        });
        this.isLoading = false;
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    tableFid(datas){
      if(datas.voucher == false && datas.protype == 'credit'){
        return 'promoUsePoint';
      }
      if(datas.voucher == false && datas.protype == 'giftFriends'){
        return 'promonGift';
      }
      if(datas.voucher == true && datas.protype == 'credit'){
        return 'promonVoucher';
      }
    },
    onFileChange(e) {
      this.isLoading = true;
      e.param.image = e.Base64;
      axios(config.image(e.param)).then((response)=>{
        this.modals.data.img = `http://images.${utils.getDomain()}${response.data.path}`;
        this.cache = Math.floor(Date.now() / 1000);
        this.isLoading = false;
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
      // let file = e.target.files[0];
      // let toBase64  = new Promise((resolve, reject) => {
      //   const reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   reader.onload = () => resolve(reader.result);
      //   reader.onerror = error => reject(error);
      // });
      // toBase64.then((resolve)=>{
      //   //////
      //   axios(config.image({
      //     path: `/${utils.localDB.jwt('token').agentId}/promotion`,
      //     name: Math.floor(Date.now() / 1000),
      //     image: resolve
      //   })).then((response)=>{
      //     this.modals.data.img = `http://images.${utils.getDomain()}${response.data.path}`;
      //     this.cache = Math.floor(Date.now() / 1000);
      //     this.isLoading = false;
      //   },(error)=>{
      //     this.isLoading = false;
      //     this.$alert(error.response.data.Message,'Error','error');
      //   });
      // })
    },
    updatePromotion(datas){
      axios(config.api('post', '/control/updatePromotion',datas.item)).then((response)=>{
        let updateTableFild = this.tableFid(datas.item);
        ///console.log('datas.item',this[updateTableFild]);
        this.modals.open = false
        this.isLoading = false;
        this.$alert('เปลียนสถานะเรียบร้อย','Success','success').then(()=>{this.LoadData();}); 

      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    useModals(datas){
      console.log('useModals',datas);
      if(datas.eve == 'block'){
        if(utils.permission('PROMOTION_EDIT')){
          this.$prompt("เหตุผลของการกระทำ").then((commit) => {
            this.isLoading = true;
            datas.item.status = (datas.item.status)?false:true;
            datas.item.commit = commit;
            this.updatePromotion(datas);
          });
        }else{
          this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        }
      }else if(datas.eve == 'del'){ 
        if(utils.permission('PROMOTION_EDIT')){
          this.$prompt("เหตุผลของการกระทำ").then((commit) => {
            this.isLoading = true;
            datas.item.status = false;
            datas.item.del = true;
            datas.item.commit = commit;
            this.updatePromotion(datas);
          });
        }else{
          this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        }
      }else{
        this.modals.open = true;
        this.modals.imageName = Math.floor(Date.now());
        this.modals.index = datas.index;
        this.modals.eve = datas.eve;
        this.modals.data = datas.item;
      }
    },
    pinmodify(datas){
      if(datas.eve == 'block'){
        datas.item.pin = 1,
        this.updatePromotion(datas);
      }else{
        datas.item.pin = 0,
        this.updatePromotion(datas);
      }
    },
    changeTypeLimit(evt){
      if(evt == 'change'){
        this.isLoading = true;
         setTimeout( ()=> {
           this.modals.data.opentime = false;
           this.isLoading = false;
         }, 1000);
      }else{
        this.isLoading = true;
        setTimeout( ()=> {
          this.modals.data.opentime = true;
          this.isLoading = false;
        }, 1000);
      }

    },
    openForm(){
      if(utils.permission('PROMOTION_EDIT')){
        this.modals.open = true;
        this.modals.imageName = Math.floor(Date.now());
        this.modals.index = 0;
        this.modals.eve = 'new';
        let agentArray = [];
        for (var i = 0; i < this.gameAgent.length; i++) {
          agentArray.push(this.gameAgent[i].agent);
        }
        this.modals.data = {
          agent: agentArray,
          bonut: '',
          detail: '',
          getbonutLinit: '',
          protype: 'credit',
          title: '',
          total: '',
          transfer: '',
          type: 'persen',
          typeLimit: 'none',
          typeWithdraw: 'none',
          useLimit: '',
          voucher: false,
          withdraw: '',
          withdrawLimit: '',
          typeturn:'truecdiet',/// none truecdiet
          opentime:false,
          startTime:'',
          endTime:'',
          onlyEvent:'none',
          status:false
        };
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      }
    },
    listPromotion(){
      axios(config.api('get', '/control/listPromotion')).then((response)=>{
        let promoUsePoint = [],   promonGift = [], promonVoucher = [] ;
        for (var i = 0; i < response.data.ResultData.length; i++) {
          if(response.data.ResultData[i].voucher == false && response.data.ResultData[i].protype == 'credit'){
            promoUsePoint.push(response.data.ResultData[i]);
          }
          if(response.data.ResultData[i].voucher == false && response.data.ResultData[i].protype == 'giftFriends'){
            promonGift.push(response.data.ResultData[i]);
          }
          if(response.data.ResultData[i].voucher == true && response.data.ResultData[i].protype == 'credit'){
            promonVoucher.push(response.data.ResultData[i]);
          }
        }
        this.promoUsePoint.data = promoUsePoint;
        this.promonGift.data = promonGift;
        this.promonVoucher.data = promonVoucher;
        this.isLoading = false;
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    isActive(name){
      try {
        for (var i = 0; i < this.modals.data.agent.length; i++) {
          if(this.modals.data.agent[i] == name) return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    indexofAgent(name){
      try {
        for (var i = 0; i < this.modals.data.agent.length; i++) {
          if(this.modals.data.agent[i] == name) return i;
        }
        return -1;
      } catch (e) {
        return -1;
      }
    },
    selectAgent(name){
      try {
        if(this.indexofAgent(name) > -1){
          this.modals.data.agent.splice(this.indexofAgent(name), 1);
        }else{
          this.modals.data.agent.push(name);
        }
      } catch (e) {
        this.modals.data.agent.push(name);
      }
    },
    saveChanges(){
      // <strong v-if="modals.eve == 'info'">โปรโมชั่น</strong>
      // <strong v-if="modals.eve == 'new'"> เพิ่มโปรโมชั่น</strong>
      if(this.modals.eve == 'info'){
        let dataform = {
          _id:this.modals.data._id,
          data:{
            agent: this.modals.data.agent,
            img: this.modals.data.img,
            bonut: this.modals.data.bonut,
            detail: this.modals.data.detail,
            getbonutLinit: this.modals.data.getbonutLinit,
            protype: this.modals.data.protype,
            title: this.modals.data.title,
            total: this.modals.data.total,
            transfer: this.modals.data.transfer,
            type: this.modals.data.type,
            typeLimit: this.modals.data.typeLimit,
            typeturn: this.modals.data.typeturn,
            typeWithdraw: this.modals.data.typeWithdraw,
            useLimit: this.modals.data.useLimit,
            voucher: this.modals.data.voucher,
            withdraw: this.modals.data.withdraw,
            withdrawLimit: this.modals.data.withdrawLimit
          }
        }
        /////
        this.isLoading = true;
        axios(config.api('post', '/control/savePromotion',{event:this.modals.eve,data:dataform})).then((response)=>{
          this.listPromotion();
          this.modals.open = false;
          this.$alert(response.data.Message,'Success','success').then(()=>{this.LoadData();});
          this.LoadData();
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.isLoading = true;
        axios(config.api('post', '/control/savePromotion',{event:this.modals.eve,data:this.modals.data})).then((response)=>{
          this.listPromotion();
          this.modals.open = false;
          this.$alert(response.data.Message,'Success','success').then(()=>{this.LoadData();});
          this.LoadData();
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
      }
    }
  },
  mounted(){
    this.LoadData();
  },
  beforeCreate() {

  }

};
</script>

<style>
.optionGaneAgen > a{
  display: inline-block;
  background: #383a4a;
  background-size: 210% 210%;
  background-position: 100% 0;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  text-align: center;
  width: 90%;
  margin: 2px 6px;
  float: left;
  border-radius: 5px;
  cursor: pointer;
}
.optionGaneAgen a.active{
 background: #e14eca;
 color: #FFF;
}
.inputFiles label{
  width: 100%;
  display: block;
  padding: 10px;
  text-align: center;
  background: #e14eca;
  border-radius: 10px;
  color: #FFF;
}
</style>
