<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="row">
        <div class="col-12">
          <base-button type="primary pull-right" @click="openForm()">เพิ่มรายการ</base-button>
        </div>
      </div>
      <div class="table-responsive">
        <base-alert v-if="isAlert.open" :type="isAlert.type" v-on:click="isAlert.open == false">
          <strong> {{isAlert.tip}} </strong> {{isAlert.msg}}
          <button type="button" aria-label="Close" class="close" @click="isAlert.open = false"><span aria-hidden="true"><i class="tim-icons icon-simple-remove"></i></span></button>
        </base-alert>
        <base-table-control-bank :data="table.data" :columns="table.columns" @ChangeStats="ChangeUpdate" @Editupdate="editEvet" @openTime="openTime" thead-classes="text-primary" @click="saveChanges()"> </base-table-control-bank>
      </div>
    </card>
  </div>

  <!-- //// EDIT AND ADD //// -->
  <modal :show.sync="newModals.open" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
          <template class="mb-4">
            <h3 v-if="newModals.eve == 'add'"> เพิ่มรายการ </h3>
            <h3 v-if="newModals.eve == 'edit'">แก้ไขนายการ</h3>
          </template>

          <template>
            <div class="row" v-if="newModals.data == null">
              <div class="col-4 text-center">
                <button class="btnScb" type="button" @click="formType('scb')" name="button"><img style="width: 50px;" src="img/icons_sys/scb.jpg"/></button>
              </div>
              <div class="col-4 text-center">
                <button class="btnTrue" type="button" @click="formType('true')" name="button"><img style="width: 50px;" src="img/icons_sys/true.jpg"/></button>
              </div>
              <div class="col-4 text-center">
                <button class="btnTrue" type="button" @click="formType('kbank')" name="button"><img style="width: 50px;" src="img/icons_sys/kbank.jpg"/></button>
              </div>
            </div>
            <div class="form row" v-if="newModals.data != null">
              <div class="col-6">
                  <base-input label="ชื่อ-นามสกุล" v-model="newModals.data.name" type="text"  placeholder="ชื่อ-นามสกุล"></base-input>
              </div>
              <div class="col-6">
                  <base-input label="เลขบัญชี" v-model="newModals.data.number" type="number"  placeholder="0-9"></base-input>
              </div>
              <div class="col-6">
                  <base-input label="Ref1" v-model="newModals.data.notiid" disabled type="text"  placeholder="ชื่อ-นามสกุล"></base-input> 
              </div>
              <div class="col-6"> 
                  <base-input v-if="newModals.data.type != 'true'" label="Ref1" v-model="newModals.data.type" disabled type="text"  :placeholder="newModals.data.type"></base-input>
                  <base-input v-if="newModals.data.type == 'true'" label="Ref2" value="tmn" disabled type="text" ></base-input>
              </div>
            </div> 
          </template>

          <template slot="footer">
            <div class="row">
              <div class="col-3">
                <base-button type="secondary" class="w-100" @click="CloseNewModals()">Closes</base-button>
              </div>
              <div class="col-9"> 
                <base-button  class="w-100" v-if="newModals.data != null" type="primary" @click="saveNewChange()">Submit</base-button>
              </div>
            </div>
          </template>
      </card>
  </modal>

  <!-- //////// -->
  <modal :show.sync="modals.open"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
          <template>
            <strong>ตั้งเวลาเปิดระบบ</strong>
          </template>
          <template>
            <form role="form">
              <div class="row">
                <div class="col-lg-6">
                  <base-input label="วันที่" v-model="forms.dates" type="date"  placeholder="Enter text"></base-input>
                </div>
                <div class="col-lg-6">
                  <base-input label="เวลา" v-model="forms.times" type="time"  placeholder="Enter text"></base-input>
                </div>
              </div>

            </form>
          </template>
          <template slot="footer">
            <base-button type="secondary" @click="CloseModals()">Close</base-button>
            <base-button v-if="forms.dates && forms.times " type="primary" style="width: 82%;" @click="saveChanges()">Submit</base-button>
          </template>
      </card>
  </modal>

  <!-- //////// -->
  <modal :show.sync="modalsLog.open"
       body-classes="p-0"
       modal-classes="modal-dialog-centered modal-sm">
    <card type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">
        <template>
          <strong>ระบบรัน</strong>
        </template>
        <template>
          <div class="box-board-log" id="box-board-log" style="height: 500px; overflow: scroll; background: #15151b;">
            <h5 class="" v-for="tkey in modalsLog.data" style="color: #026709; margin:3px;"><b>Log-></b> {{tkey}}</h5>
          </div>
        </template>
        <template slot="footer">
          <base-button type="secondary" @click="CloseModalsLog()">Close</base-button>
        </template>
    </card>
  </modal>



</div>
</template>
<script>
import {
  BaseTableControlBank,
  BaseAlert,
  Modal
} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
import io from 'socket.io-client';

/////
import async from 'async';
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
// const generateUntil = function(length) {
//     var result           = '';
//     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     var charactersLength = characters.length;
//     for ( var i = 0; i < length; i++ ) {
//        result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }
//     return result;
//   }
/////
const tableColumns = [ "type","typesys","number","name"];
const tableData = [];

export default {
  components: {
    BaseTableControlBank,
    BaseAlert,
    Modal,
    Loading
  },
  data() {
    return {
      socket: io.connect(config.ws),
      permission: utils.permission('CONFIGBANK_RESET'),
      table: {
        title: "ข้อมูลสมาชิก",
        columns: [...tableColumns],
        data: [...tableData]
      },
      modals: {
        open: false,
        title: '',
        eve: '',
        data :{},
        commit: '',
        vocher: ''
      },
      newModals: {
        open: false,
        title: '',
        eve: '',
        data :{},
        commit: '',
        vocher: ''
      },
      modalsLog:{
        open: false,
        data :['Loading...']
      },
      forms:{
        dates: new Date().toISOString().slice(0, 10),
        times:'01:00'
      },
      isAlert: {open:false,tip:'',msg:'',type:'danger'},
      isLoading: true,
      fullPage: true,
      formNewData:{
        default: [
          {icon:'<img style="width: 50px;" src="img/icons_sys/scb.jpg"/>',keyValue: 'scb'},
          {icon:'<img style="width: 50px;" src="img/icons_sys/true.jpg"/>',keyValue: 'true'},
          {icon:'<img style="width: 50px;" src="img/icons_sys/kbank.jpg"/>',keyValue: 'kbank'}
        ],
        newData: {
          'scb':{ 
            "typesys": "deposit",
            "useSyS": "noti",  
            "number": "",
            "deposit": 0,
            "type": "scb",
            "procc": "listdeposit",
            "nameBank": "ธนาคารไทยพาณิชย์",
            "name": "",
            "tel": "",
            "web": false,
            "sys": false
          },
          'true':{ 
            "typesys": "deposit",
            "useSyS": "noti",  
            "number": "",
            "deposit": 0,
            "type": "true",
            "procc": "listdeposit",
            "nameBank": "True wallet",
            "name": "",
            "tel": "",
            "web": false,
            "sys": false
          },
          'kbank':{ 
            "typesys": "deposit",
            "useSyS": "noti",  
            "number": "",
            "deposit": 0,
            "type": "kbank",
            "procc": "listdeposit",
            "nameBank": "ธนาคารกสิกรไทย",
            "name": "",
            "tel": "",
            "web": false,
            "sys": false
          },
        },
      }
    };
  },
  methods: { 
    LoadData(){
      if(utils.permission('CONFIGBANK_VIEW')){
        this.isLoading = true;
        async.parallel({
          listBank:(callback)=>{
            axios(config.api('get', '/control/listBank')).then((response)=>{
               callback(null,response.data.ResultData);
            },(error)=>{
              callback(error.response.data.Message,null);
            });
          }
        },(err, results)=>{ 
          this.table.data = results.listBank;
          this.isLoading = false;
        });
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    saveNewChange(){
      let CheckuseSYS = ()=>{
        if(this.newModals.data.useSyS == 'app' && (this.newModals.data.app.deviceId && this.newModals.data.app.refreshToken && this.newModals.data.app.pin)) return true;
        if(this.newModals.data.useSyS == 'noti') return true;
        if(this.newModals.data.useSyS == 'easy' && (this.newModals.data.easy.Username && this.newModals.data.easy.Password)) return true;
      }
      if(this.newModals.eve == 'add'){
        if(utils.permission('CONFIGBANK_ADD')){
          if(this.newModals.data.number && this.newModals.data.name){
            axios(config.api('post', '/control/updateAndnew',this.newModals.data)).then((response)=>{
              this.$alert('เพิ่มธนคารเรียบร้อย','Warning','success');
              this.modals.open = false;
            },(err)=>{ 
              this.$alert(err.response.data.Message,'Success','warning');
              this.modals.open = false;
            });
          }else{
            this.$alert('กรอกข้อมูลไม่ครบ','Permission','warning');
          }
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      }

      if(this.newModals.eve == 'edit'){
        if(utils.permission('CONFIGBANK_EDIT')){
          if(this.newModals.data.type && this.newModals.data.typesys  && this.newModals.data.number && this.newModals.data.number && this.newModals.data.name && CheckuseSYS()){
            // this.$alert('OKOKOKOKO','Permission','warning');
            axios(config.api('post', '/control/updateAndnew',this.newModals.data)).then((response)=>{
              this.$alert('แก้ไขธนคารเรียบร้อย','Success','success');
              this.modals.open = false;
            },(err)=>{
              this.$alert(err.response.data.Message,'Warning','warning');
              this.modals.open = false;
            });
          }else{
            this.$alert('กรอกข้อมูลไม่ครบ','Permission','warning');
          }
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      }

    },
    openForm(){
      if(utils.permission('CONFIGBANK_ADD')){
        this.newModals.open = true;
        this.newModals.eve = 'add';
        this.newModals.data = null;
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
      }
    },
    formType(form){
      this.newModals.data = this.formNewData.newData[form]; 
    },
    startService(proce){
      axios(config.api('post', '/sys/bots',proce)).then((response)=>{
        this.isLoading = false;
        this.$alert(response.data.Message,'สำเร็จ','success');
        this.LoadData();
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    editEvet(dataItem){
      if(utils.permission('CONFIGBANK_EDIT')){
        if(dataItem.eve == 'restart'){ 
          this.isLoading = true;
          this.startService({
            "agentId": dataItem.data.agentId,
            "name": `${dataItem.data.typesys}_${dataItem.data.type}_${dataItem.data.number}_${dataItem.data.agentId}`,
            "time":"",
            "eve":"restart"
          })
        }else{
          this.newModals.open = true;
          this.newModals.eve = 'edit';
          this.newModals.data = dataItem.data;
        }
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
      }
    },
    CloseNewModals(){
      this.newModals.open = false;
      this.newModals.data = {};
    },
    CloseModalsLog(){
      this.modalsLog.open = false;
      this.modalsLog.data = ['Loading...'];
      this.socket.emit('join', '---');
    },
    ChangeUpdate(item){
       ///console.log('ChangeUpdate -> ',item);
      if(utils.permission('CONFIGBANK_EDIT')){
        if( item.eve == 'web'){
          this.$prompt("เหตุผลของการกระทำ").then((commit) => {
             this.modals.data = item;
             item.commit = commit; 
             axios(config.api('post', '/control/updateBank',item)).then((response)=>{
               this.$alert('บันทึกเรียบร้อย','Success','success');
             },(error)=>{
               this.isLoading = false;
               this.$alert(error.response.data.Message,'Error','error');
             });
           },(error)=>{
              this.table.data[item.index][item.eve] = (this.table.data[item.index][item.eve])?false:true;
           });
        }else{
          this.$prompt("เหตุผลของการกระทำ").then((commit) => {
             this.modals.data = item;
             item.commit = commit; 
             axios(config.api('post', '/control/updateBank',item)).then((response)=>{
               this.$alert('บันทึกเรียบร้อย','Success','success');
               console.log('item',item);
               
             },(error)=>{
               this.isLoading = false;
               this.$alert(error.response.data.Message,'Error','error');
             });
           },(error)=>{
              this.table.data[item.index][item.eve] = (this.table.data[item.index][item.eve])?false:true;
           });
        }
       }else{
         this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
       }
    },
    openTime(data){
      if(utils.permission('CONFIGBANK_EDIT')){
        if(data.eve == 'sys'){
          this.modals.open = data.status;
          this.modals.data.key = data.key;
        }
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        this.modals.open = false;
        // this.table.data[data.key].sys = (this.table.data[data.key].sys)?false:true;
      }
    },
    CloseModals(){
      this.modals.open = false;
      this.table.data[this.modals.data.key].sys = true;
    },
    saveChanges(){
      if(utils.permission('CONFIGBANK_EDIT')){
        this.$prompt("เหตุผลของการกระทำ").then((commit) => {
          let ForMatDate = (forms)=>{
            var dates = forms.dates.replace(/-/g,'').replace(/(\d{4})(\d{2})(\d{2})/, "$3/$2/$1");
            return `${dates} ${forms.times}`
          }
          let ForMatDateTime = (forms)=>{
            return Math.floor(new Date(`${forms.dates}:${forms.times}`).getTime()/1000)
          }
          ///////////
          let item = {
            _id:this.modals.data._id,
            web:this.modals.data.web,
            sys:this.modals.data.sys,
            nameBank:this.modals.data.nameBank,
            name:this.modals.data.name,
            number: this.modals.data.number,
            commit: commit,
            opentime: ForMatDate(this.forms)
          }
          axios(config.api('post', '/control/updateBank',item)).then((response)=>{
            this.$alert('บันทึกเรียบร้อย','Success','success');
            this.socket.emit('join', { room: 'switch'});
            this.socket.emit("reqmp", {typs:'stop',room:'switch'});
            this.table.data[this.modals.data.key].opentime = ForMatDate(this.forms);
            this.startService({
              "agentId": this.modals.data.agentId,
              "name": this.modals.data.proce,
              "time": ForMatDateTime(this.forms),
              "eve":"stop"
            })
            this.modals.open = false;
          },(error)=>{
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
          });
        },(error)=>{});
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    }
  },
  mounted(){
    ///// GET List True
    this.LoadData();
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
.btnScb{
    width: 100%;
    height: 87px;
    background: #532882;
    border: solid 3px #331750;
    border-radius: 15px;
  }
.btnTrue{
    width: 100%;
    height: 87px;
    background: #FFFFFF;
    border: solid 3px #a6a6a6;
    border-radius: 15px;
  }
</style>
