<template>
  <div class="row">
    <div class="col-md-7">
      <card>
        <h5 slot="header" class="title">จัดการข้อมูลเว็บ และ SEO</h5>
        <div class="row">
          <div class="col-md-12">
            <base-input label="Web Title"  placeholder="webtitle" v-model="model.title"></base-input>
          </div>
          <div class="col-md-8">
            <base-input label="โลโก้" placeholder="โลโก้" v-model="imageLoad.logo" ></base-input>
          </div>
          <div class="col-md-4">
            <label for="imageLogo" class="custom-file-upload btn btn-primary"> <i class="tim-icons icon-cloud-upload-94"></i> อัพโหลด โลโก้</label>
            <input type="file" @change="imageLogo" id="imageLogo" ccept="image/*" class="form-input">
          </div>
          <div class="col-md-8">
            <base-input label="รูปพื้นหลัง" placeholder="รูปพื้นหลัง"  v-model="imageLoad.bg" ></base-input>
          </div>
          <div class="col-md-4">
            <label for="imageBG" class="custom-file-upload btn btn-primary"> <i class="tim-icons icon-cloud-upload-94"></i> อัพโหลด รูปพื้นหลัง</label>
            <input type="file" @change="imageBG"  id="imageBG"  accept="image/*" class="form-input">
          </div>
          <div class="col-md-12 hrder mt-2">
            <label for="connet"><h4>เมนู</h4></label>
            <div class="row">
              <div class="col-md-6" v-for="value,index in model.topmenu"  :key="index">
                <base-input :label="value.title" placeholder="รูปพื้นหลัง" v-model="value.link" ></base-input>
              </div>
            </div>
            <label for="connet">สีเมนู</label>
            <div class="row">
              <div class="col-md-4">
                <base-input type="color" label="Color value" placeholder="Color value" v-model="model.backgroundMenu[0]" ></base-input>
              </div>
              <div class="col-md-4">
                <base-input type="color" label="Color value" placeholder="Color value" v-model="model.backgroundMenu[1]" ></base-input>
              </div>
              <div class="col-md-4">
                <base-input type="color" label="Color value" placeholder="Color value" v-model="model.backgroundMenu[2]" ></base-input>
              </div>
            </div>

          </div>
          <hr/>
          <div class="col-md-12 hrder mt-2">
            <label for="connet"><h4>SEO</h4></label>
          </div>
          <div class="col-md-12">
            <base-input label="คำอธิบาย"  placeholder="คำอธิบาย" v-model="model.description"></base-input>
          </div>
          <div class="col-md-12">
            <input-tag  label="แนะนำ"  v-model="model.keywords"></input-tag>
          </div>
          <div class="col-md-12">
            <base-input label="website"  placeholder="website" v-model="model.author"></base-input>
          </div>
          <div class="col-md-12 hrder mt-2" v-if="master">
            <label for="connet"><h4>เนื้อหา</h4></label>
            <textarea style="height:300px;max-height:300px;" class="form-control" id="connet" v-model="model.bodyContent"></textarea>
          </div>
          <div class="col-md-3">
            <label for="imageGamePussy" class="custom-file-upload btn btn-primary"> <i class="tim-icons icon-cloud-upload-94"></i> อัพโหลด เนื้อหาเกม Pussy</label>
            <input type="file" @change="imageGamePussy"  id="imageGamePussy"  accept="image/*" class="form-input">
          </div>
          <div class="col-md-3">
            <label for="imageGameSlotXO" class="custom-file-upload btn btn-primary"> <i class="tim-icons icon-cloud-upload-94"></i> อัพโหลด เนื้อหาเกม SlotXO</label>
            <input type="file" @change="imageGameSlotXO"  id="imageGameSlotXO"  accept="image/*" class="form-input">
          </div>
          <div class="col-md-3">
            <label for="imageGameJoker" class="custom-file-upload btn btn-primary"> <i class="tim-icons icon-cloud-upload-94"></i> อัพโหลด เนื้อหาเกม Joker</label>
            <input type="file" @change="imageGameJoker"  id="imageGameJoker"  accept="image/*" class="form-input">
          </div>
          <div class="col-md-3">
            <label for="imageGamePG" class="custom-file-upload btn btn-primary"> <i class="tim-icons icon-cloud-upload-94"></i> อัพโหลด เนื้อหาเกม PG</label>
            <input type="file" @change="imageGamePG"  id="imageGamePG"  accept="image/*" class="form-input">
          </div>
          <div class="col-md-12 mt-5" v-if="master">
            <label for="detail"> <h4>รายละเอียด </h4></label>
            <textarea style="height:300px;max-height:300px;" class="form-control" id="detail" v-model="model.bodyDetail"></textarea>
          </div>
          <div class="col-md-12">
            <base-input label="Copyright"  placeholder="copyright" v-model="model.footerContent"></base-input>
          </div>
        </div>
        <base-button slot="footer" type="primary" @click="saveContent()" fill>Save</base-button>
      </card>
    </div>
    <div class="col-md-5">
      <div class="row">
        <div class="col-12 text-center">
          <card>
            <img :src="imageLoad.logo" alt="" >
          </card>
        </div>
        <div class="col-12 text-center">
          <card>
            <img :src="imageLoad.bg" alt="" >
          </card>
        </div>
        <div class="col-12 text-center">
          <card>
             <div class="topmenu" :style="{background : `linear-gradient(to bottom, ${model.backgroundMenu[0]} 0%, ${model.backgroundMenu[1]} 44%, ${model.backgroundMenu[2]} 100%)`}">
               <ul>
                 <li v-for="value,index in model.topmenu"  :key="index">
                   <a :href="value.link" target="_blank">{{value.title}}</a>
                 </li>
               </ul>
             </div>
          </card>
        </div>
        <div class="col-12">
          <card>
            <div v-html="model.bodyContent"></div>
          </card>
        </div>
        <div class="col-12">
          <card>
            <div v-html="model.bodyDetail"></div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import config from '@/config.js';
import utils from '@/utils';
import InputTag from 'vue-input-tag'
  export default {
    components: {
      InputTag
    },
    data() {
      return {
        model: {},
        imageLoad: {
          logo: `${config.domain}/images/img-logo.png`,
          bg: `${config.domain}/images/img-bg-min.jpg`,
        } ,
        master: utils.permission('MASTER'),
      }
    },
    methods: {
      saveContent(){
        //console.log('model',this.model);
        if(utils.permission('MASTER')){
          axios(config.api('post', '/webPage/updatePage',this.model)).then((response)=>{
             this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
          },(error)=>{
            this.$alert(error.response.data.Message,'Error','error');
          });
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      },
      imageLogo(e){
        if(utils.permission('MASTER')){
          let file = e.target.files[0]
          utils.getBase64Img(file).then((data)=>{
            axios(config.api('post', '/webPage/upImmag',{name:'img-logo.png',domain:config.domain,image:data,path:'/'})).then((response)=>{
               this.imageLoad.logo = `${response.data.ResultData.link}?d=${utils.makeId(10)}`;
               this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
            },(error)=>{
              this.$alert(error.response.data.Message,'Error','error');
            });
          });
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      },
      imageBG(e){
        if(utils.permission('MASTER')){
          let file = e.target.files[0]
          utils.getBase64Img(file).then((data)=>{
            axios(config.api('post', '/webPage/upImmag',{name:'img-bg-min.jpg',domain:config.domain,image:data,path:'/'})).then((response)=>{
               this.imageLoad.bg = `${response.data.ResultData.link}?d=${utils.makeId(10)}`;
               this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
            },(error)=>{
              this.$alert(error.response.data.Message,'Error','error');
            });
          });
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      },
      imageGamePussy(e){
        if(utils.permission('MASTER')){
          let file = e.target.files[0]
          utils.getBase64Img(file).then((data)=>{
            axios(config.api('post', '/webPage/upImmag',{name:'pussy.png',domain:config.domain,image:data,path:'games'})).then((response)=>{
              //alert(response.data.ResultData.link);
               this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
            },(error)=>{
              this.$alert(error.response.data.Message,'Error','error');
            });
          });
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      },
      imageGameSlotXO(e){
        if(utils.permission('MASTER')){
          let file = e.target.files[0]
          utils.getBase64Img(file).then((data)=>{
            axios(config.api('post', '/webPage/upImmag',{name:'xo.jpg',domain:config.domain,image:data,path:'games'})).then((response)=>{
               this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
            },(error)=>{
              this.$alert(error.response.data.Message,'Error','error');
            });
          });
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      },
      imageGameJoker(e){
        if(utils.permission('MASTER')){
          let file = e.target.files[0]
          utils.getBase64Img(file).then((data)=>{
            axios(config.api('post', '/webPage/upImmag',{name:'jk.png',domain:config.domain,image:data,path:'games'})).then((response)=>{
               this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
            },(error)=>{
              this.$alert(error.response.data.Message,'Error','error');
            });
          });
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      },
      imageGamePG(e){
        if(utils.permission('MASTER')){
          let file = e.target.files[0]
          utils.getBase64Img(file).then((data)=>{
            axios(config.api('post', '/webPage/upImmag',{name:'pg.png',domain:config.domain,image:data,path:'games'})).then((response)=>{
               this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
            },(error)=>{
              this.$alert(error.response.data.Message,'Error','error');
            });
          });
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        }
      }

    },
    /// LOAD Form
    mounted(){
      if(utils.permission('HOMEPAGE_VIEW')){
        (async () => {
          try {
            let tableReq = await axios(config.api('get', '/webPage/listPage/index'));
            this.model = tableReq.data.ResultData;
            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
          }
        })();
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
       /////
    },
    beforeCreate() {

    }
  }
</script>
<style>
 .hrder{
   border-top: solid 2px #2b3553;
   padding-top: 10px;
 }
 .topmenu{
   padding: 10px;
   background: #DDD;
   color: #FFF;
 }
 .topmenu ul{
   margin: 0px;
   padding: 0px;
 }
 .topmenu ul li{
   display: inline-block;
   margin-left: 10px;
   margin-right: 10px;
 }
 .topmenu ul li a{
   color: #FFF;
 }
 .custom-file-upload {
   display: inline-block;
   padding: 6px 12px;
   margin-top: 27px;
   width: 100%;
   text-align: center;
   font-weight: bold;
}
 input[type="file"] { display: none; }
</style>
