<template>
     <div>  
         {{seclectTage}}
        <section class="mb-3" ref="dashboard" id="dashboard">
          <h3>แผงควบคุม</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/dashboard/1.png" style="width: 100%;" alt="dashboard">
                         <p>รายการรายได้ของปีนี้ทั้งปี</p>
                    </div>
                    <div class="col-12">
                         <img src="/img/help/dashboard/2.png" style="width: 100%;" alt="dashboard">
                         <p>รายการรายได้ของเดือนนี้ตลอดทั้งเดือน</p>
                    </div>
                    <div class="col-12">
                         <img src="/img/help/dashboard/3.png" style="width: 100%;" alt="dashboard">
                         <p>สรุปรายสัปดาห์แบ่ง 3 ส่วน ดังนี้ สมาชิก - รายการถอน - รายการฝาก</p>
                    </div>
                    <div class="col-12">
                         <img src="/img/help/dashboard/4.png" style="width: 100%;" alt="dashboard">
                         <p>ส่วนจะบอกเรื่องเครดิตของ Agent แลัว ค้นหาสมาชิกต่อวันหรือต่อเดือนได้</p>
                    </div>
               </div>
          </div>
        </section>
        <section class="mt-3" ref="record" id="record">
          <h3>ประวัติย้อนหลัง</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/record/1.png" style="width: 100%;" alt="record">
                         <p>รายการค้นหาหรือวิเคราะผลกำไรในปีนั้นๆ</p>
                    </div> 
               </div>
          </div>
        </section>
        <section class="mt-3 mb-3" ref="recordbank" id="recordbank">
          <h3>ประวัติธนคารฝาก</h3>
          <div class="body-htlp-tag">
               <div class="row">
                    <div class="col-12">
                         <img src="/img/help/recordbank/1.png" style="width: 100%;" alt="recordbank">
                         <p>รายการผลแต่ละบันชีของธนาคารว่ามีการเติมเข้าบันชีนั้นๆมากเยอะแต่ใหน</p>
                    </div> 
               </div>
          </div>
        </section>  
     </div>
</template>
<script>
export default { 
     props: {
          tag:{
               type:String,
               default:'dashboard'
          }
     }, 
     computed:{
          seclectTage(){ 
               try {
                    let ele = this.$refs[this.tag]; 
                    if(ele.offsetTop > 0){ 
                         ele.scrollIntoView({behavior: 'smooth'})
                         // window.scrollTo(ele.offsetTop);
                    } 
               } catch (error) {}
          }
     },
     mounted() {
          
     },
}
</script>
<style scoped>
     .body-htlp-tag{
          margin-bottom: 50px;
          border-bottom: dotted 2px rgb(59, 58, 58);
          padding-bottom: 50px !important;
     }
     .btn-action {
          background: #c8c8c8;
     }
</style>