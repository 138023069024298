<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <div class="row">
            <div class="col-9">
              <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากเลขบันชีเลขท้าย ... <Enter>" v-on:keyup.enter="searchWord()">
              </base-input>
            </div>
            <div class="col-3 text-right">  
              <base-button type="warning" size="md" class="pull-right " @click="onExport"> <i class="tim-icons icon-cloud-download-93"></i> XLSX </base-button>
            </div> 
          </div>
        </card>
      </div>
      <div class="table-responsive">
        <base-table :data="table.data" :columns="table.columns" thead-classes="text-primary"> </base-table>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>
</div>
</template>
<script>
import { BaseTable } from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "id","member", "title", "amount" , "bonut", "game","created_at"];
const tableData = [];
////
export default {
  components: {
    BaseTable,
    SlidingPagination,
    Loading
  },
  data() {
    return {
      table: {
        title: "รายการโอนเครดิต",
        columns: [...tableColumns],
        data: [...tableData]
      },
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/TransferCredit/'+selectedPage));
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    searchWord(){
      if(utils.permission('LISTUSERPROMOTION_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/TransferCredit/1'));
              //console.log('tableReq', tableReq.data);
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/TransferCredit/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (e) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    onExport() { 
      // const tableColumns =  [ "id","member", "title", "amount" , "bonut", "game","created_at"];
      let DataSheet = [
            {
                sheet:  this.table.title,
                columns:[ 
                    { label: "ไอดี", value: "id" }, 
                    { label: "สมาชิก", value: "member" },
                    { label: "หัวข้อ", value: "title" },
                    { label: "จำนวน", value: "amount" }, 
                    { label: "โบนัส", value: "bonut" }, 
                    { label: "ประเถทเกม", value: "game" }, 
                    { label: "เวลา", value: "created_at" , format:'h:mm AM/PM'}, 
                ],
                content:this.table.data
            }
        ];
        axios(config.api('post', `/toxlsx`,DataSheet)).then((response) => { 
          window.open(`${config.apiplayload}/${response.data.ResultData}`,'_blank');
          this.isLoading = false; 
        }, (error) => {
          this.isLoading = false;
          this.$alert(error.response.data.Message, 'Error', 'error');
        });
    }
  },
  mounted(){
    ///// GET List True
    if(utils.permission('LISTUSERPROMOTION_VIEW')){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/TransferCredit/1'));
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = tableReq.data.ResultData.skipdata;
          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }else{
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      this.isLoading = false;
    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
