<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="table-responsive">
        <base-table :data="table.data" :columns="table.columns" thead-classes="text-primary"> </base-table>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>
</div>
</template>
<script>
import { BaseTable } from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "title", "text", "typeMaseeage" , "ref1","created_at"];
const tableData = [];

export default {
  components: {
    BaseTable,
    SlidingPagination,
    Loading
  },
  data() {
    return {
      table: {
        title: "SMS",
        columns: [...tableColumns],
        data: [...tableData]
      },
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/sms/'+selectedPage));
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }
  },
  mounted(){
    ///// GET List True
    if(utils.permission('SMSSCB_VIEW')){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/sms/1'));
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = tableReq.data.ResultData.skipdata;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }else{
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      this.isLoading = false;
    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
