<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากเลขบันชีเลขท้าย ... <Enter>" v-on:keyup.enter="searchWord()">
          </base-input>
        </card>
      </div>
      <div class="table-responsive">
        <base-table-loss :data="table.data" :columns="table.columns" thead-classes="text-primary"> </base-table-loss>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>


</div>
</template>
<script>
import { BaseTableLoss } from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = [ "#", "gameId", "week", "loss" , "get", "created_at"];
const tableData = [];

export default {
  components: {
    BaseTableLoss,
    SlidingPagination,
    Loading
  },
  data() {
    return {
      table: {
        title: "รายการผู้ขอคืนยอดเสีย",
        columns: [...tableColumns],
        data: [...tableData]
      },
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/viewloss/'+selectedPage));
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    searchWord(){
      if(utils.permission('LOSS_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/viewloss/1'));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/viewloss/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },

  },
  mounted(){
    ///// GET List True
    if(utils.permission('LOSS_VIEW')){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/viewloss/1'));
          this.totalPages = tableReq.data.ResultData.numpage.page;
          this.table.data = tableReq.data.ResultData.skipdata;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }else{
      this.isLoading = false;
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
