<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-md-8">
    <card :title="table.title">
      <div class="row" v-if="permis">
        <div class="col-md-12 mb-5">
          <base-button class="pull-right" slot="footer" type="primary" @click="newSlidBanner" fill>อัพโหลดไฟล์</base-button>
        </div>
      </div>
      <div class="table-responsive">
        <base-table-imagepro :data="table.data" :columns="table.columns" @event="eventAc" thead-classes="text-primary"> </base-table-imagepro>
      </div>
    </card>
  </div>
  <div class="col-md-4">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="row">
            <div class="col-12" v-for="(item,index) in model.promoImage" style="border-bottom: solid 2px #1e1e2c;">
               <label class="labelNumber" for="">{{index}}</label>
              <img :src="item" alt="">
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
  <!-- //// modals //// -->

  <modal :show.sync="modals.open"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
          <template>
            <strong>เพิ่มรายการ</strong>
          </template>
          <template>
            <form role="form" class="row">
              <div class="col-12">
                <label for="imageUpfile" class="custom-file-upload btn btn-primary"> <i class="tim-icons icon-cloud-upload-94"></i> อัพโหลด </label>
                <input type="file" @change="imageUpfile" id="imageUpfile" accept="image/*" class="form-input custom-file-upload">
              </div>
              <div class="col-12">
                <img :src="modals.image" alt="" style="width:100%;">
              </div>
            </form>
          </template>
          <template slot="footer">
            <base-button type="secondary" @click="modals.open = false">Close</base-button>
            <base-button type="primary" style="width: 225px;" @click="saveChanges()">Save changes</base-button>
          </template>
      </card>
  </modal>

</div>
</template>
<script>
import {
BaseTableImagepro,
Modal
} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";

/////
import axios from 'axios';
import config from '@/config.js';
import utils from '@/utils';

const tableColumns = [ "image"];
const tableData = [];
export default {
  components: {
    BaseTableImagepro,
    Modal,
    Loading
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      permis: utils.permission('PROMOIMAGE_ADD'),
      modals:{
        open :false,
        image: ''
      },
      model:[],
      table: {
          title: "จัดการรูปโปรโมชั่น.",
          columns: [...tableColumns],
          data: [...tableData]
        }
    }
  },
  methods: {
    eventAc(item){
      if(utils.permission('PROMOIMAGE_EDIT')){
        axios(config.api('post', '/webPage/updatePage',this.model)).then((response)=>{
           this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
      }
    },
    saveChanges(){
      if(utils.permission('PROMOIMAGE_ADD')){
        console.log('LOg',this.modals);
        this.model.promoImage.push(this.modals.image); // = tableReq.data.ResultData;
        this.table.data = this.model.promoImage;
        axios(config.api('post', '/webPage/updatePage',this.model)).then((response)=>{
           this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
           this.modals.open = false;
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
      }
    },
    newSlidBanner(){
      this.modals = { open :true, image:'' }
    },
    imageUpfile(e){
      if(utils.permission('IMAGEBIGSLIDE_ADD')){
        let file = e.target.files[0]
        utils.getBase64Img(file).then((data)=>{
          axios(config.api('post', '/webPage/upImmag',{name:'',domain:config.domain,image:data,path:'pro'})).then((response)=>{
              this.modals.image = response.data.ResultData.link;
             ///this.$alert('บันทึกข้อมูลสำเร็จ','Success','success');
          },(error)=>{
            this.$alert(error.response.data.Message,'Error','error');
          });
        });
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
      }
    }
  },
  /// LOAD Form
  mounted(){
    if(utils.permission('PROMOIMAGE_VIEW')){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/webPage/listPage/index'));
          console.log(tableReq.data.ResultData);
          this.model = tableReq.data.ResultData;
          this.table.data = tableReq.data.ResultData.promoImage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    }else{
      this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
      this.isLoading = false;
    }
     /////
  },
  beforeCreate() {

  }
}
</script>
<style>
.labelNumber{
  display: block;
  background: #40b37f;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  font-weight: 700;
}
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  margin-top: 27px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
input[type="file"] { display: none; }
</style>
