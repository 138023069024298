<template> 
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <card>
          <div class="row">
            <div class="col-8">
              <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากชื่อ ... <Enter>" v-on:keyup.enter="searchWord()">
              </base-input>
            </div>
            <div class="col-4 text-right"> 
              <base-button type="warning" size="md" class="pull-right " @click="onExport"> <i class="tim-icons icon-cloud-download-93"></i> XLSX </base-button>
            </div> 
            <!-- <div class="col-3">
              <base-button type="secondary" class="ml-auto" @click="checkBalance()"> เช็คยอดคงเหลือ </base-button>
            </div> -->
          </div>
        </card>
      </div>
      <div class="table-responsive">
        <base-alert v-if="isAlert.open" type="danger" v-on:click="isAlert.open == false">
          <strong> {{isAlert.tip}} </strong> {{isAlert.msg}}
          <button type="button" aria-label="Close" class="close" @click="isAlert.open = false"><span aria-hidden="true"><i class="tim-icons icon-simple-remove"></i></span></button>
        </base-alert>
        <base-table-withdraw  :data="table.data" :columns="table.columns" :columnsTh="table.columnsTh" @withd-modals="prodsEvet" :sort="table.sort" @SortTable="SortTable" thead-classes="text-primary"> </base-table-withdraw>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>

  <!-- //////// -->

  <modal :show.sync="modals.open"  body-classes="p-0"  modal-classes="modal-dialog-centered">

    <!-- //// template info -->
    <card type="secondary" style="background: #ffffff;"  header-classes="bg-white pb-5" body-classes=" px-lg-5 py-lg-5" class="border-0 mb-0" v-if="modals.eve == 'info'">
      <div class="row">
        <div class="col-6"><strong>ชื่อ : </strong> {{modals.data.name}}</div>
        <div class="col-6"><strong>ไอดีเกม : </strong> {{modals.data.gameId}}</div>
        <div class="col-6"><strong>เกม : </strong> {{modals.data.agent}}</div>
        <div class="col-6"><strong>บัญชี : </strong> {{modals.data.bank}}</div>
        <div class="col-6"><strong>จำนวนถอน : </strong> {{modals.data.withdraw}}</div>
        <div class="col-12"><hr/></div>
        <div class="col-12">
          <div class="row" v-if="modals.data.promotion != '-'">
            <div class="col-12"><strong>โปรโมชั่น : </strong> {{modals.data.promotion.title}}</div>
            <div class="col-12"><strong>เงือนไข </strong></div>
            <div class="col-4">ยอดฝาก : {{modals.data.usePromotion.amount}}</div>
            <div class="col-4">โบนัส  : {{modals.data.usePromotion.useProPoint-modals.data.usePromotion.amount}}</div>
            <div class="col-4">ทำยอด  : {{condition.condition}}</div>

          </div>
          <div class="row" v-if="modals.data.promotion == '-'">
            <div class="col-12"><strong>โปรโมชั่น : </strong> ไม่เลือกโปรโมชั่น</div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" class="ml-auto" @click="modals.open = false">Close  </base-button>
      </template>
    </card>
    <!-- //// template withdraw -->
    <card type="secondary"  header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" v-if="modals.eve == 'withdraw'">
      <template>
        <strong>WITH</strong>
      </template>
      <template>
        <form role="form">
          <base-radio inline name="Auto" class="mb-3" v-model="radio" :disabled="true">
            Auto
          </base-radio>
          <base-radio inline name="Manual" class="mb-3" v-model="radio">
            Manual
          </base-radio>
        </form>
        <form role="form" v-if="radio == 'Auto'">
            <label for="">ยังไม่เปิดระบบใชังาน</label> 
        </form> 
        <form role="form" v-if="radio == 'Manual'">
          <div class="row">
              <div class="col-6"><strong>ชื่อ : </strong> {{modals.data.name}}</div>
              <div class="col-6"><strong>ไอดีเกม : </strong> {{modals.data.gameId}}</div>
              <div class="col-6"><strong>เกม : </strong> {{modals.data.agent}}</div>
              <div class="col-6"><strong>บัญชี : </strong> {{modals.data.bank}}</div>
              <div class="col-6"><strong>จำนวนถอน : </strong> {{modals.data.withdraw}}</div>
              <div class="col-12"><hr/></div>
              <div class="col-12">
                <div class="row" v-if="modals.data.promotion != '-'">
                  <div class="col-12"><strong>โปรโมชั่น : </strong> {{modals.data.promotion.title}}</div>
                  <div class="col-12"><strong>เงือนไข </strong></div>
                  <div class="col-4">ยอดฝาก : {{modals.data.usePromotion.amount}}</div>
                  <div class="col-4">โบนัส  : {{modals.data.usePromotion.useProPoint-modals.data.usePromotion.amount}}</div> 
                </div>
                <div class="row" v-if="modals.data.promotion == '-'">
                  <div class="col-12"><strong>โปรโมชั่น : </strong> ไม่เลือกโปรโมชั่น</div>
                </div>
              </div>
            </div>
            <hr>
            <label for="">ระบุสาเหตุ</label>
            <base-input alternative type="text" v-model="modals.data.commit" ref="commit"> </base-input>
            <div class="col-12 text-center text-danger"> เมือทำการโอนให้ลูกค้าแล้วโปรดกด "อนุมัติ" </div>
        </form>  
      </template>
      <template slot="footer">
        <div class="row"> 
          <div class="col-4">
            <base-button class="w-100" inline type="secondary" @click="modals.open = false">ยกเลิก</base-button>
          </div>
          <div class="col-8">
            <base-button class="w-100" inline v-show="modals.data.commit || radio == 'Auto'" type="primary" style="width: 223px;" @click="saveChanges()">อนุมัติ</base-button>
          </div>
        </div>
      </template>
    </card>
    <!-- //// template rejact -->
    <card type="secondary"  header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" v-if="modals.eve == 'rejact'">
      <template>
        <strong>REJACT</strong>
      </template>
      <template>
        <form role="form">
            <label for="">ระบุสาเหตุ</label>
            <base-input alternative type="text"   v-model="modals.data.commit" ref="commit"> </base-input>
        </form>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="modals.open = false">ยกเลิก</base-button>
        <base-button v-show="modals.data.commit" type="primary" style="width: 223px;" @click="saveChanges()">บันทึก</base-button>
      </template>
    </card>
    <!-- //// template LOGS  -->
    <card type="secondary"  header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" v-if="modals.eve == 'Logs'">
      <template>
        <h4>รายงานที่ล้มเหลว</h4>
      </template>
      <template>
          <base-table-withdraw-logs :data="modals.data" :columns="tableLog.columns" :columnsTh="tableLog.columnsTh" ></base-table-withdraw-logs>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="modals.open = false">ยกเลิก</base-button> 
      </template>
    </card>

    <!-- <card type="secondary"  header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">

      <template>
        <strong>TrueMoney :</strong> {{ modals.title }}
      </template>
      <template v-if="modals.eve == 'vocher'">
        <form role="form">
            <label for="">สามารถ Clipboard จาก ปุ่มได้เลย</label>
            <base-input alternative type="text"   v-model="genVocher" ref="genvoucher"> </base-input>
        </form>
      </template>
      <template v-if="modals.eve == 'block'">
        <div class="text-center text-muted mb-4">
          <strong>ต้องการแบนสมาชิก</strong>
        </div>
        <form role="form">
            <label for="">แจ้งหมายเหตุว่า</label>
            <base-input alternative
                        type="text"
                        placeholder="แจ้งหมายเหตุว่า"
                        v-model="modals.commit" >
            </base-input>
        </form>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="modals.open = false">Close</base-button>
        <base-button v-show="modals.commit" type="primary" style="width: 225px;" @click="saveChanges()">Save changes</base-button>
        <base-button v-show="genVocher"
          v-clipboard:copy="genVocher"
          v-clipboard:success="ClipboardVouche"
          type="primary" style="width: 225px;">Clipboard</base-button>
      </template>
    </card> -->
  </modal>

</div>
</template>
<script>
import {
  BaseTableWithdraw,
  BaseTableWithdrawLogs,
  BaseRadio,
  BaseAlert,
  Modal
 } from "@/components";
// Import component
import XLSX from 'xlsx'
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination';
import utils from '@/utils';
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import config from '@/config.js'; 
import { join } from "path";
import { log } from "console";
/////
const tableColumns = [ "iconBank","name", "bank" , "gameId" , "withdraw","credit","withMax","status","created_at","updated_at"];
const tableColumnsTh = [ "ไอคอน","ชื่อ", "ธนาคาร" , "เกมไอดี" , "ถอน","เครดิต","ถอนจำกัด","สถานะ","วัน/เวลา","กระทำ"];
const tableData = [];
//////////////////////
const tableColumnsLog = ['status','tid','amount','qrString','created_at','updated_at']
const tableColumnsLogTh = ['สถานะ','อ้างอิงไอดี','จำนวน','รายงาน','วัน/เวลา','กระทำ']
const tableDataLog = [];

export default {
  components: {
    BaseTableWithdraw,
    BaseTableWithdrawLogs,
    SlidingPagination,
    BaseRadio,
    BaseAlert,
    Modal,
    Loading
  },
  data() {
    return {
      table: {
        title: "List Withdraw",
        sort:{
          even:'desc',
          col:'created_at'
        },
        columns: [...tableColumns],
        columnsTh: [...tableColumnsTh],
        data: [...tableData]
      },
      tableLog: {
        title: "List Withdraw Log",
        columns: [...tableColumnsLog],
        columnsTh: [...tableColumnsLogTh],
        data: [...tableDataLog]
      },
      modals: {
        open: false,
        eve: '',
        data :{},
      },
      adminInfo: utils.localDB.jwt('token'),
      forms:{
        eve:'',
        commit: ''
      },
      condition:{},
      radio: 'Manual' ,
      withdrawMax: 0,
      isAlert: {open:false,tip:'',msg:''},
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    withdrawMaxLoad(){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/looby/info'));
          this.withdrawMax = tableReq.data.ResultData.lobbyConfig.withdrawMax;
        } catch (error) {
          if(error.response.data.Code == 500){
            this.$router.push('/login')
          }else{
            this.$alert(error.response.data.Message,'Error','error');
          }
          this.isLoading = false;
        }
      })();
    },
    LoadData(){
      if(utils.permission('LISTWITHDRAW_VIEW')){
        (async () => {
          try {
            let tableReq = await axios(config.api('get', '/listwithdraw/1'));
            this.totalPages = tableReq.data.ResultData.numpage.page;
            this.table.data = tableReq.data.ResultData.skipdata;
            this.table.sort = 'desc';
            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
          }
        })();
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    SortTable(column){ 
      console.log(column);
      
      let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
      let ObjK = {};
      ObjK[column] = this.table.data;
      this.table.sort.col = column;
      this.table.sort.even = sortType; 
      this.table.data = utils.Sort[sortType](ObjK) 
    },
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        let tableReq = await axios(config.api('get', '/listwithdraw/'+selectedPage));
        this.table.data = tableReq.data.ResultData.skipdata;
        this.currentPage = selectedPage;
        this.isLoading = false;
      })();
    },

    prodsEvet(item){
       if(utils.permission('LISTWITHDRAW_EDIT')){
          if(item.eve == 'activeInfo'){
            console.log('dfsdfsd',item.item);
            this.$fire({
              type: 'info',
              title: 'ทำรายการ',
              text: item.item.commit,
              footer: `โดย : ${item.item.admin}`
            });
          }else if(item.eve == 'Logs'){
            console.log('Log',item.item.logs);
            this.modals.open = true;
            this.modals.eve = item.eve;
            this.modals.data = item.item.logs;
          }else if(item.eve == 'info'){
            this.condition = utils.OPwithdraw(item.item);
            this.modals.open = true;
            this.modals.eve = item.eve;
            this.modals.data = item.item;
          }else if(item.eve == 'withdraw'){
            this.condition = utils.OPwithdraw(item.item);
            this.modals.eve = item.eve;
            this.modals.data = item.item;
            this.modals.data.commit = 'Manual'; 
            this.modals.open = true;
            // if(item.item.withdraw <= this.withdrawMax){
            //   this.modals.open = true;
            // }else{
            //   this.$confirm(`เนืองจากมียอดถอนมากกว่าที่กำหนด ${this.withdrawMax} ต้องส่งเรื่องต่อ?`,"คำเตือน","warning").then(() => {
            //     this.saveChanges();
            //   });
            // }
          }else if(item.eve == 'rejact'){
            this.$prompt("ระบุสาเหตุที่ทำการยกเลิก","","Rejact","question").then((text) => {
              this.condition = utils.OPwithdraw(item.item);
              this.modals.eve = item.eve;
              this.modals.data = item.item;
              this.modals.data.commit = text;
              this.saveChanges();
            });
          }else if(item.eve == 'return'){
            this.$prompt("ระบุสาเหตุทีนำกลับมา","","Return","question").then((text) => {
              this.condition = utils.OPwithdraw(item.item);
              this.modals.eve = item.eve;
              this.modals.data = item.item;
              this.modals.data.commit = text;
              this.modals.data.status = 'wait';
              this.saveChanges();
            });
          }

          // <template>
          //   <form role="form">
          //       <label for="">ระบุสาเหตุ</label>
          //       <base-input alternative type="text"   v-model="modals.data.commit" ref="commit"> </base-input>
          //   </form>
          // </template>
          // <template slot="footer">
          //   <base-button type="secondary" @click="modals.open = false">ยกเลิก</base-button>
          //   <base-button v-show="modals.data.commit" type="primary" style="width: 223px;" @click="saveChanges()">บันทึก</base-button>

       }else{
         this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
       }
    },
    searchWord(){
      if(utils.permission('LISTWITHDRAW_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/listwithdraw/1'));
              //console.log('tableReq', tableReq.data);
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;

            } catch (error) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/withdraw/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
            console.log(table.data);
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    /////
    saveChanges(){
      //console.log('FORM ->',this.modals);
      if(utils.permission('LISTWITHDRAW_EDIT')){
        this.modals.open = false;
        this.isLoading = true;
        ////// Event Withdraw
      if(this.modals.eve == 'withdraw'){
        if(this.modals.data.withdraw <= this.withdrawMax){
          let commitWHD = '';
          switch (this.radio) {
            case 'Manual':
              commitWHD = `Manual: ${this.modals.data.commit}`;
              break;
            default:
              commitWHD = 'System Auto';
          }
          this.modals.data.commit = commitWHD;
          axios(config.api('post', '/withdraw/event',{
            _id: this.modals.data._id,
            eve: this.modals.eve,
            rate: this.radio,
            commit: this.modals.data.commit
          })).then((ResultData)=>{
              this.modals.data.status = 'active';
              this.modals.data.admin = this.adminInfo.name;
              this.isLoading = false;
              this.$alert(ResultData.data.Message,'Success','success');
              this.LoadData();
          }).catch((error)=>{
              this.isLoading = false;
              this.modals.data.commit = '';
              this.$alert(error.response.data.Message,'Error','error'); 
          });
        }else{
          
          
            axios(config.api('post', '/confirm/withdraw',{
              data:{
                admin: this.modals.data.admin,
                bank: this.modals.data.bank,
                name : this.modals.data.name,
                typePromotion : this.modals.data.typePromotion,
                withdraw: this.modals.data.withdraw,
                order: this.modals.data._id
              },
              post:{
                _id: this.modals.data._id,
                eve: this.modals.eve,
                rate: this.radio,
                commit: this.modals.data.commit
              }
            })).then((ResultData)=>{
              this.modals.data.status = 'confirm';
              // this.modals.data.admin = this.adminInfo.name;
              this.isLoading = false;
              this.$alert(ResultData.data.Message,'Success','success');
              this.LoadData();
            },(error)=>{
              this.isLoading = false;
              this.modals.data.commit = '';
              this.$alert(error.response.data.Message,'Error','error');
            }); 
            
        }
      ////// Event Rejact
      }else if((this.modals.eve == 'rejact' || this.modals.eve == 'return')){
          axios(config.api('post', '/withdraw/event',{
            _id: this.modals.data._id,
            eve: this.modals.eve,
            commit: this.modals.data.commit
          })).then((ResultData)=>{
              this.modals.data.status = this.modals.eve;
              this.modals.data.admin = this.adminInfo.name;
              this.isLoading = false;
              this.$alert(ResultData.data.Message,'Success','success');
              this.LoadData();
          }).catch((error)=>{
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
          });
        }

      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.modals.open = false;
      }

    },
    forMatMony(input){
       return input.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    onExport() { 
      let DataSheet = [
            {
                sheet:  this.table.title,
                columns:[ 
                    { label: "ไอคอน", value: "iconBank" },
                    //{ label: "ไอดี", value: "tid" },
                    { label: "ชื่อ", value: "name" },
                    { label: "ธนาคาร", value: "bank" },
                    { label: "เกมไอดี", value: "gameId" },
                    { label: "ถอน", value: "withdraw" },
                    { label: "เครดิต", value: "credit" }, 
                    { label: "ถอนจำกัด", value: "withMax" }, 
                    { label: "สถานะ", value: "status" }, 
                    { label: "เวลา", value: "created_at" , format:'h:mm AM/PM'},
                    { label: "กระทำ", value: "updated_at" , format:'h:mm AM/PM'}
                ],
                content:this.table.data
            }
        ];
        axios(config.api('post', `/toxlsx`,DataSheet)).then((response) => { 
          window.open(`${config.apiplayload}/${response.data.ResultData}`,'_blank');
          this.isLoading = false; 
        }, (error) => {
          this.isLoading = false;
          this.$alert(error.response.data.Message, 'Error', 'error');
        });
    },
    /// check Balance
    checkBalance(){
      this.isLoading = true;
      axios(config.api('get', '/scb/balance')).then((ResultData)=>{
          this.isLoading = false;
          this.$alert(`ยอดคงเหลือในบันชี ${this.forMatMony(ResultData.data.ResultData.totalAvailableBalance)} บาท`,'Success','success');
      }).catch((error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
      });
    }
  },
  mounted(){
    ///// GET List True
    this.withdrawMaxLoad();
    this.LoadData();
    //this.adminInfo = utils.localDB.jwt('token'),

     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
