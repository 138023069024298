<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12" v-if="platform.name == ''">
    <card :title="table.title">
      <div class="row" v-if="permission('GOD')">
        <div class="col-4 offset-8 mb-3">
          <div class="row">
            <div class="col-5">
              <base-button type="secondary" @click="checkUpdate()">ตรวจสอบอัพเดท</base-button>
            </div>
            <div class="col-5">
              <base-button type="success" @click="LoadData()">รีเฟทข้อมูล</base-button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <base-alert v-if="isAlert.open" :type="isAlert.type" v-on:click="isAlert.open == false">
          <strong> {{isAlert.tip}} </strong> {{isAlert.msg}}
          <button type="button" aria-label="Close" class="close" @click="isAlert.open = false"><span aria-hidden="true"><i class="tim-icons icon-simple-remove"></i></span></button>
        </base-alert>
        <base-table-game :data="table.data" :columns="table.columns" :cache="cache" @ChangeStats="ChangeUpdate"  @eventEdit="eventData"  thead-classes="text-primary" @click="saveChanges()"> </base-table-game>
      </div>
    </card>
  </div>
  <div class="col-12" v-if="platform.name != ''">
    <card :title="table.title">
      <div class="row">
        <div class="col-2 offset-10">
          <base-button type="secondary" @click="backList()">ย้อนกลับ</base-button>
        </div>
        <div class="col-12 formInfo">
          <label for=""> <u> <h4>ข้อมูล แพลตฟอร์ม ของ {{platform.name}}</h4> </u> </label>
          <div class="row">
            <div class="col-6">
              <label for=""> Image </label>
              <p> <img :src="`https://images.sharkbet777.com/typegame/${platform.name}.png?s=120&cache=${cache}`" style="width: 80%;"/> </p>
              <p class="inputFiles">
                <base-file type="primary" v-if="permission('GOD')" style="width: 80%;" value="อัพโหลดรูป" @filedata="onFileChange" :block="true" :param="{'path':'/typegame','name':platform.name}" />
              </p>
            </div>
            <div class="col-6">
              <label for=""> Tag </label>
              <div class="row">
                <div class="col-4 text-center optioTag" v-for="(item,index) in tagType">
                  <a :class="{ active: isActive(item.tag) }" v-if="permission('GOD')" @click="selectTags(item.tag)">{{item.name}}</a>
                  <a :class="{ active: isActive(item.tag) }" v-if="!permission('GOD')">{{item.name}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 listDataGame mt-4">
          <hr class=" mb-3 ">
          <div class="row">
            <div class="col-5 offset-7 mb-3">
              <div class="row">
                <div class="col-4"> <base-button type="secondary" @click="backList()">ย้อนกลับ</base-button> </div>
                <div class="col-4"> <base-button type="warning" @click="checkUpdateGames()" v-if="permission('MASTER')">อัพเดทเกม</base-button> </div>
                <div class="col-4"> <base-button type="success" @click="eventData({items:platform})">รีเฟทข้อมูล</base-button> </div>
              </div>



            </div>
            <div class="col-12">
                <base-table-plat-game :data="tableplat.data" :cache="cache" :platform="platform.name"  :columns="tableplat.columns" @upstatus="upstatusGame" @selectTagsGame="selectTagsGame" @updateCeahs="updateCeahs" thead-classes="text-primary"> </base-table-plat-game>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
  <!-- //////// -->
</div>
</template>
<script>
import {
  BaseTableGame,
  BaseTablePlatGame,
  BaseFile,
  BaseAlert
} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";

/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
// const generateUntil = function(length) {
//     var result           = '';
//     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
//     var charactersLength = characters.length;
//     for ( var i = 0; i < length; i++ ) {
//        result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }
//     return result;
//   }
/////
const tableColumns = [ "no","img", "name", "payoff" , "enable"];
const tableData = [];

const tablePlatColumns = ['No',"Img", "Name","type"];
const tablePlatData = [];

export default {
  components: {
    BaseTableGame,
    BaseTablePlatGame,
    BaseFile,
    BaseAlert,
    Loading
  },
  data() {
    return {
      permission: utils.permission,
      table: {
        title: "ข้อมูล แพลตฟอร์ม",
        columns: [...tableColumns],
        data: [...tableData]
      },
      tableplat: {
        title: "ข้อมูล เกม",
        columns: [...tablePlatColumns],
        data: [...tablePlatData]
      },
      isAlert: {open:false,tip:'',msg:'',type:'danger'},
      isLoading: true,
      fullPage: true,
      imageDir:'',
      cache: Math.floor(Date.now() / 1000),
      platform: {name:''},
      tagType:[
        {name:'สล็อต',tag:'slot'},
        {name:'บาคาร่า',tag:'baccarat'},
        {name:'ยิงปลา',tag:'fish'},
        {name:'บิงโก',tag:'bingo'},
        {name:'บอล',tag:'ball'},
        {name:'อาร์เคด',tag:'arcade'}
      ]
    };
  },
  methods: {
    LoadData(){
      this.isLoading = true;
      if(utils.permission('LISTGAMES_VIEW')){
        axios(config.api('get', '/control/listAgent')).then((response)=>{
          this.table.data = utils.sortA({'no':response.data.ResultData});
          this.isLoading = false;
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },

    isActive(name){
      return (this.platform.tag.indexOf(name) > -1)?true:false
    },

    backList(){
      this.platform = {name:''};
      this.table.title = `ข้อมูล แพลตฟอร์ม`
    },

    selectTags(name){
      this.isLoading = true;
      let indexF = this.platform.tag.indexOf(name);
      if(indexF > -1){
        this.platform.tag.splice(indexF, 1);
      }else{
        this.platform.tag.push(name);
      }
      /////// Update Tag Platform
      axios(config.api('post', '/control/updateTagPlatform',{_id:this.platform._id,tag:this.platform.tag})).then((response)=>{
        this.isLoading = false;
        this.LoadData();
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    },

    selectTagsGame(item){
      // console.log('selectTagsGame',this.platform);
      // this.isLoading = true;
      // axios(config.api('post', '/control/updateTageGames',{_id:this.platform._id,tag:item.select,data:item.index})).then((response)=>{
      //   this.isLoading = false;
      //   this.LoadData();
      // },(error)=>{
      //   this.isLoading = false;
      //   this.$alert(error.response.data.Message,'Error','error');
      // });
      this.upstatusGame(item);
    },

    upstatusGame(item){ 
      axios(config.api('post', '/control/updateStatusGames',{_id:this.platform._id,data:this.platform.data,agentId:this.platform.agentId})).then((response)=>{
        this.isLoading = false;
        // this.LoadData(); 
        this.tableplat.data = utils.sortA({'No':this.platform.data}); 
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    },

    eventData(data){
      //enable
      this.platform = data.items;
      this.tableplat.data = utils.sortA({'No':data.items.data});
      this.table.title = `ข้อมูล แพลตฟอร์ม -> [${data.items.name}]`
    },

    updateCeahs(){
      this.cache = Math.floor(Date.now() / 1000);
    },

    onFileChange(e) {
      if(utils.permission('GOD')){
        e.param.image = e.Base64;
        axios(config.image(e.param)).then((response)=>{
          this.isLoading = false;
          this.cache = Math.floor(Date.now() / 1000);
          this.$alert(response.data.Message,'Success','success');
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
      }
    },

    checkUpdateGames(){
      if(utils.permission('MASTER')){
        axios(config.api('get', `/control/loadGamedata/${this.platform.name}`)).then((response)=>{
          this.isLoading = false;
          this.$alert(response.data.Message,'Success','success');
          // this.LoadData();
          axios(config.api('get', '/control/listAgent')).then((response)=>{
            this.table.data = response.data.ResultData;
            let FormmPlatF = utils.findIndexByObj(this.table.data,{name:this.platform.name});
            // console.log('FormmPlatF',FormmPlatF);
            this.eventData({items:FormmPlatF});
          },(error)=>{
            this.$alert(error.response.data.Message,'Error','error');
          });
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },

    ChangeUpdate(item){
       ///console.log('ChangeUpdate -> ',item);
       if(utils.permission('MASTER')){
        this.isLoading = true;
         axios(config.api('post', '/control/updateAgent',item.item)).then((response)=>{
          //  this.$alert('บันทึกเรียบร้อย','Success','success');
          this.LoadData();
         },(error)=>{
           this.isLoading = false;
           this.$alert(error.response.data.Message,'Error','error');
         });
       }else{
         this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
         this.isLoading = false;
       }
    },

    updatePlatform(ResultData){
      this.isLoading = true;
      if(utils.permission('GOD')){
        axios(config.api('post', '/control/updatePlatform',{platform:ResultData})).then((response)=>{
          this.isLoading = false;
          this.$alert('อัพเดท Platform','Success','success').then(()=>{
            this.LoadData();
          });
        },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },

    checkUpdate(){
      axios(config.api('get', '/control/loadPlatform')).then((response)=>{
        if(response.data.Code == 200){
          this.$confirm(`Platform ใหม่ ${response.data.Message.join(' และ ')}`,"ต้องการเพิ่ม Platform หรือไม่ ?",'question').then((resp) => {
            if(resp){
             this.updatePlatform(response.data.ResultData);
            }
          });
        }else{
          this.$alert(response.data.Message,'Success','success');
        }

      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    },

  },
  mounted(){
    this.LoadData();
  },
  beforeCreate() {

  }

};
</script>
<style>
.optioTag > a{
  display: inline-block;
  background: #383a4a;
  background-size: 210% 210%;
  background-position: 100% 0;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  text-align: center;
  width: 90%;
  margin: 2px 6px;
  float: left;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}
.optioTag a.active{
 background: #e14eca;
 color: #FFF !important;
}
.inputFiles label{
  width: 100%;
  display: block;
  padding: 10px;
  text-align: center;
  background: #e14eca;
  border-radius: 10px;
  color: #FFF;
}
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
