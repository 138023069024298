<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12 mt-5 mb-5 text-center">
        <!-- <a :href="`${oldData.receipt}?s=1000`" target="_blank">
          <img :src="`${oldData.receipt}?s=300`" alt="" @click="zoomIn()">
        </a> -->
        <img v-if="!zoomIn" :src="`${oldData.receipt}?s=300`" style="border: 11px solid #3faf7c;" @click="zoomIn = true">
        <img v-if="zoomIn" :src="`${oldData.receipt}?s=1200`" style="border: 11px solid #3faf7c;" @click="zoomIn = false">
      </div>
      <div class="col-6 mt-5">
        <base-input label="ผู้ทำร้องขอ"  :text="oldData.admin.name"  placeholder="admin" />
      </div>
      <div class="col-6 mt-5">
        <base-input label="ผู้ได้รับ"  :text="oldData.owner.name"  placeholder="firstName" />
      </div>
      <div class="col-6 mt-2">
        <base-input label="จำนวนเงิน"  :text="oldData.amount"  placeholder="จำนวนเงิน" />
      </div>
      <div class="col-6 mt-2">
        <base-input label="บันชีที่โอนเข้า"  :text="`${oldData.banks.nameBank} - ${oldData.banks.number}`"  placeholder="บันชีที่โอนเข้า" />
      </div>
      <div class="col-6 mt-2">
        <base-input label="วันเวลาที่โอน"  :text="`${oldData.dates}  ${oldData.times}`"  placeholder="วันเวลาที่โอน" />
      </div>
      <div class="col-6 mt-2">
        <base-input label="REF"  :text="oldData.ref"  placeholder="firstName" />
      </div>
      <div class="col-12 mt-4">
        <h3>คำอธิบายในการแก้ไข</h3>
        <text-area v-model="commit" disabled />
      </div>
      <div class="col-12 mt-4 mb-5">
         <div class="row">
           <div class="col-6 text-center">
             <base-button type="success" style="width: 100%;" class="mt-2"  @click="summit('active')">อนุมัติ</base-button>
           </div>
           <div class="col-6 text-center">
             <base-button type="danger" style="width: 100%;" class="mt-2"  @click="summit('reject')">ไม่อนุมัติ</base-button>
           </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
import { TextArea } from "@/components";
export default {
  name: 'getmoney',
  components:{
    TextArea
  },
  props: {
    data: {
      type: Object,
      default: {},
      description: " "
    }
  },
  data() {
    return {
      oldData:this.data.post,
      commit:this.data.commit,
      zoomIn:false,
    };
  },
  computed: {

  },
  methods: {
    isLoading(evt){
      this.$emit('loading', evt);
    },
    SendData(Objdata,callback){
      this.isLoading(true);
      axios(config.api('post', `/confirm/SendData`,Objdata)).then((response)=>{
        this.isLoading(false);
        callback(null,response.data.ResultData);
      },(error)=>{
        this.isLoading(false);
        callback(error.response.data.Message,null);
        // this.$alert(error.response.data.Message,'Error','error');
      });
    },
    SendMethod(Objdata,callback){
      this.isLoading(true);
      axios(config.api('post', Objdata.method ,{secret:Objdata.secret,post:Objdata.post})).then((response)=>{
        this.isLoading(false);
        callback(null,response.data.ResultData);
      },(error)=>{
        this.isLoading(false);
        callback(error.response.data.Message,null);
        // this.$alert(error.response.data.Message,'Error','error');
      });
    },
    summit(eve){
      if(eve == 'reject'){
        this.$prompt("ขอเหตุผลที่ไม่อนุมัติ").then((commit) => {
          this.SendData({
            _id: this.data._id,
            ac:'reject',
            commit: commit,
          },(err,resp)=>{
            if(err == null){
              this.$alert('บันทึกข้อมูลเรียบร้อย','Success','success').then(()=>{
                this.$emit('backPage', {action:{title:'table'}});
              });
            }else{
              this.$alert(err,'Error','error');
              this.$emit('backPage', {action:{title:'table'}});
            }
          });
        });
      }else{
        this.$confirm("แน่ใจแล้วไช่มั้ย?","แน่ใจ","question").then(()=>{
          this.SendData({
            _id: this.data._id,
            ac:'active',
            commit: 'อนุมัติ',
          },(err,resp)=>{
            if(err == null){
              this.SendMethod({
                method : this.data.method,
                secret: resp,
                post: this.data.post
              },(err,respev)=>{
                if(err == null){
                  this.$alert('อนุมัติเรียบร้อย','Success','success').then(()=>{
                    this.$emit('backPage', {action:{title:'table'}});
                  });
                }else{
                  this.$alert(err,'Error','error');
                  this.$emit('backPage', {action:{title:'table'}});
                }
              });
            }else{
              this.$alert(err,'Error','error');
            }
          });
        })
      }
    },

  },
  mounted(){
    console.log('getmoney data',this.data);
  }
};
</script>
<style>
</style>
