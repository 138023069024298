<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="row">
        <div class="col-3 offset-9 mb-4">
          <base-button @click="modals.open = true"> เพิ่มรายการเบอร์ </base-button>
        </div>
      </div>
      <div class="table-responsive">
        <base-table-member-sms :data="table.data" :columns="table.columns" :sum="table.sum" thead-classes="text-primary" />
      </div>
    </card>
  </div>
  <!-- /// modals /// -->
  <modal :show.sync="modals.open" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
          <template>
            <strong>เพิ่มรายการหมายเลข</strong>
          </template>
          <template>
            <text-area label="*กรอกหมายเลขหลายๆหมายเลขให้ใช้ , ขั้นไว้ เช่น 092816288,09382772"  v-model="modals.phones" />
          </template>
          <template slot="footer">
            <base-button type="primary" style="width: 100%;" class="mt-2"  @click="saveChanges()">บันทึก</base-button>
            <base-button type="default" style="width: 100%;" class="mt-2"  @click="modals.open = false">ยกเลิก</base-button>
          </template>
      </card>
  </modal>


</div>
</template>
<script>
import { BaseTableMemberSms , Modal , TextArea} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
/////
const tableColumns = ["icon", "number", "active" , "created_at"];
const tableData = [];

export default {
  components: {
    BaseTableMemberSms,
    SlidingPagination,
    TextArea,
    Modal,
    Loading
  },
  data() {
    return {
      table: {
        title: "MSMS",
        sum:{
          all:0,
          act:0,
          nosact:0,
          type:{
            okao:0,
            opad:0,
            ohox:0
          }
        },
        columns: [...tableColumns],
        data: [...tableData]
      },
      modals: {
        open: false,
        phones :'',
      },
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: ''
    };
  },
  methods: {
    LoadData(){
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/smsmember/list'));
          tableReq.data.ResultData.forEach((item, i) => {
            if(item.active == true){ this.table.sum.act++; }else{ this.table.sum.nosact++; }
            if(item.number.substring(0,2) == '09'){ this.table.sum.type.okao++; }
            if(item.number.substring(0,2) == '08'){ this.table.sum.type.opad++; }
            if(item.number.substring(0,2) == '06'){ this.table.sum.type.ohox++; }
          });
          this.table.data = tableReq.data.ResultData;
          this.table.sum.all = tableReq.data.ResultData.length;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    saveChanges(){
      this.isLoading = true;
      if(utils.permission('SENDSMS_EDIT')){
        axios(config.api('post', '/smsmember/add',{phones:this.modals.phones})).then((response)=>{
          this.LoadData();
          this.$alert(`สำเร็จ ${response.data.ResultData.succ} ไม่สำเร็จ ${response.data.ResultData.fail} ทั้งหมด ${response.data.ResultData.allp} หมายเลขมีปัณหา ${response.data.ResultData.notwork}`,'Success','success');
          this.isLoading = false;
          this.modals.open = false;
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    }
  },
  mounted(){
    ///// GET List True
    if(utils.permission('SENDSMS_VIEW')){
      this.LoadData();
    }else{
      this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      this.isLoading = false;
    }
     /////
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
